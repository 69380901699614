<template>
  <div class="order-quantity d-flex align-items-center justify-content-center">
    <div
      class="order-quantity__counter-button mr-2 bg-color-bg-gray border-radius-xs"
      @click="decreaseValue()"
    >
      -
    </div>
    <div class="mr-2 color-text-gray d-inline-block">
      <input
        class="order-quantity__input"
        :value="value"
        min="0"
        type="number"
        @input="$emit('input', parseInt($event.target.value))"
      />
    </div>
    <div
      class="order-quantity__counter-button bg-color-bg-gray border-radius-xs"
      @click="increaseValue"
    >
      +
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderQuantity',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      qty: 1
    };
  },
  methods: {
    increaseValue() {
      this.$emit('input', this.value + 1);
    },
    decreaseValue() {
      if (this.value > 1) {
        this.$emit('input', this.value - 1);
      }
    }
  }
};
</script>

<style lang="scss">
.order-quantity {
  &__counter-button {
    width: rem(20);
    height: rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    cursor: pointer;
    &:hover {
      background: $border-gray;
    }
  }
  &__input {
    padding: 6px 8px;
    min-width: 40px;
    max-width: 55px;
    font-size: 12px;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
