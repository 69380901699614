<template>
  <button
    class="grid-slider-arrow-button border-radius-s d-flex align-items-center justify-content-center cursor-pointer"
    :class="[
      { 'grid-slider-arrow-button--right ml-2': $props.isRightDirection },
      { 'grid-slider-arrow-button--gray': isGray }
    ]"
  >
    <i
      class="grid-slider-arrow-button__icon icon-triangle_arrow font-size-6"
    ></i>
  </button>
</template>

<script>
export default {
  name: 'GridSliderArrowButton',
  props: {
    isRightDirection: {
      type: Boolean,
      default: false
    },
    isGray: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.grid-slider-arrow-button {
  width: rem(27);
  height: rem(27);
  background: $white;
  transition: background-color 0.2s ease;

  &--gray {
    background: $bg-gray;
    &:hover {
      background: $white !important;
    }
  }

  &__icon {
    transform: rotate(90deg);
  }
  &:focus {
    outline: none !important;
  }

  &--right {
    .grid-slider-arrow-button__icon {
      transform: rotate(270deg);
    }
  }

  &:hover {
    background: $bg-gray;
  }
}
</style>
