<template>
  <div class="container-fluid px-0 product bg-color-bg-gray">
      <Loading v-if="isLoading" />
    <div class="container" v-if="productInfo">
      <Breadcrumbs :navigationList="breadCrumbTree" v-if="breadCrumbTree" />

      <div
        class="mt-1 mb-4 px-3 px-mb-4 py-5 bg-color-white border-radius-s d-inline-block w-100"
      >
        <div class="row">
          <div class="col-md-4 mt-2" v-if="images">
            <div class="product__img-slider-container border-radius-s ">
              <VueTinySlider
                ref="slider" 
                items="1"
                :nav="false"
                :autoplay="false"
                :loop="false"
                :autoplayButtonOutput="false"
                :controls-container="`#grid-slider-id-${this._uid}`"
              >
                <div
                  v-for="item in images"
                  :key="item"
                  class="product__img-container"
                >
                  <zoom-on-hover
                    :scale="1.3"
                    :img-normal="item"
                  ></zoom-on-hover>
                </div>
              </VueTinySlider>
            </div>
            <div
              class="product__group-slider position-relative mt-3"
              v-if="images && images.length > 1"
            >
              <VueTinySlider
                ref="groupSlider"
                items="3"
                slide-by="3"
                :gutter="12"
                :edgePadding="12"
                :nav="false"
                :autoplay="true"
                :autoplayTimeout="10000"
                :loop="false"
                :autoplayButtonOutput="false"
                :speed="600"
                :controls-container="`#grid-slider-id-${this._uid}`"
              >
                <div v-for="(item, index) in images" :key="item">
                  <div
                    class="product__group-img border-radius-s p-2 cursor-pointer"
                    @click="updateSlider(index)"
                  >
                    <div
                      class="w-100 h-100 bg-contain"
                      :style="`background-image: url(${item})`"
                    ></div>
                  </div>
                </div>
              </VueTinySlider>
              <div :id="`grid-slider-id-${this._uid}`">
                <ProductItemSliderArrowButton />
                <ProductItemSliderArrowButton is-right-direction />
              </div>
            </div>
          </div>
          <div class="offset-md-1"></div>
          <div class="col-md-7 mt-4 mt-md-0 pl-md-0">
            <h2
              class="bpg-phone-sans font-size-24 color-dark-blue line-height-1-2 font-weight-normal"
            >
              {{ productInfo.item_price_name }}
            </h2>
            <div class="mb-1">
              <div
                class="product__code d-inline-block px-3 py-1 bpg-phone-sans font-size-14 color-dark-gray border-radius-s mt-3"
              >
                კოდი: {{ productInfo.item }}
              </div>
            </div>
            <div v-if="productInfo.item != productInfo.item_model" class="mb-4">
              <div
                class="product__code d-inline-block px-3 py-1 bpg-phone-sans font-size-14 color-dark-gray border-radius-s mt-3"
              >
                მოდელი: {{ productInfo.item_model }}
              </div>
            </div>
            <div
              class="product__price-container d-flex align-items-center justify-content-between pb-3"
            >
              <div
                class="bpg-phone-sans font-size-14 color-text-gray d-flex align-items-center"
              >
                ფასი:
                <span class="price-amount color-orange ml-2"
                  >{{ productInfo.price_unit }} {{ productInfo.currency }}</span
                >
              </div>
              <div
                class="product__in-stock bpg-phone-sans font-size-14 mr-0"
                :class="[
                  productInfo.qty_text == '-' ? 'color-red' : 'color-dark-purple'
                ]"
              >
                მარაგშია ({{ productInfo.qty_text }})
                </div>
                
             <div  v-if="productInfo.qty_text != '-'">
                <!--<img v-if="productInfo.allow_sale == 0"
                  class="icon-info-svg-product"
                  alt="არ არის ხელმისაწვდომი მთავარ საწყობში"
                  src="@/assets/svg/icon-info.svg"
                  title="არ არის ხელმისაწვდომი მთავარ საწყობში"
                />-->
              </div>

              <div v-if="productInfo.qty_text != '-'"
                class="product__code d-inline-block px-3 py-1 bpg-phone-sans font-size-14 color-dark-gray border-radius-s"
                role="button" @click="getstores({item:productInfo.item})"
              >
                ნაშთი საწყობებში
              </div>
              <modal v-if="showmodal" @close="showmodal = false">
                 <tr v-for="store in storeslist">
                <td>{{ store.store_name }}</td>
                <td>{{ store.store_address }}</td>
                <td>{{ store.qty_text }}</td>
                  </tr>
            </modal>
            </div>
 
              

<div class="aqciebiInfo_class float-left text-left mt-2" v-if="aqciebiInfo">
    
<div v-for="action in aqciebiInfo.actions">
    
<div v-if="action.promo_type == 'PART'" class="d-inline-block bpg-phone-sans font-size-14 color-dark-gray border-radius-s mt-1 aqciis_box"> 
<span class="aqciis_piroba">{{ action.promo_type_desc }}</span>
<ul>
<li v-for="item in action.items"><a target="_blank" :href="'/product/' + Number(item.fg_item)">
{{ item.fg_item_name }} <br/><span class="saaqcio_fasi"> {{ item.discount }}</span>
</a></li>   
</ul>
</div>
    
</div> 
    
</div>
                       
              
              
            <div
              class="mt-4 d-flex float-right text-right justify-content-between d-md-block"
            >
              <div class="d-inline-block mb-0 mb-md-4">
                <OrderQuantity v-model="qty" />
              </div>
              <BaseButton @click.native="handleAddClick">
                კალათაში დამატება
                <i class="icon-add_to_cart font-size-14 color-white mx-2"></i>
              </BaseButton>
            </div>
          </div>
        </div>
          

<div class="aqciebiInfo_class_qveda float-left text-left" v-if="aqciebiInfo">
    
<div v-for="action in aqciebiInfo.actions">
<div v-if="action.promo_type !== 'PART'" class="row mt-5">
    
<h4 class="col-12 font-size-20">{{ action.promo_type_desc }}</h4>
    
<table class="w-100 mx-3 font-size-16 product__specifications-table">

    
<tr v-if="action.promo_type !== 'QTY'" v-for="item in action.items" v-bind:key="item.promo_type">
<td>
<a v-if="action.promo_type !== 'QTY'" target="_blank" :href="'/product/' + Number(item.fg_item)">{{ item.fg_item_name }} </a>
</td>
<td class="w-25 text-right">
<div v-if="action.promo_type == 'FG' || action.promo_type == 'PART'"> <span class="saaqcio_fasi">{{ item.fg_qty }} </span> <span>ცალი</span> </div>
</td>
<td class="w-25 text-right">
<span v-if="action.promo_type == 'PART' || action.promo_type == 'PROMO'" class="saaqcio_fasi"> {{ item.discount }} </span>    
</td>
</tr>
 
<tr v-if="action.promo_type == 'QTY'" v-for="item in action.items">
<td>{{ item.parent_qty }} ცალის შეძენისას</td>
<td class="w-25 text-right"><span class="saaqcio_fasi">{{ item.discount }}</span></td>
</tr>
    
</table>
    

</div>
</div> 
    
</div>
          
                   
          
        <div class="row mt-5" v-if="featuresInfo">
          <h4 class="col-12 mb-3 font-size-20 ">მახასიათებლები</h4>
          <div
            v-for="(featureGroup, key) in featuresInfo"
            :key="key"
            class="d-flex w-100 mb-3 flex-wrap"
          >
            <h5 class="col-12 font-size-15">{{ key }}</h5>
            <table
              class="w-100 mx-3 font-size-13 product__specifications-table"
            >
              <tr v-for="feature in featureGroup" :key="feature.field_name">
                <td class="w-25">{{ feature.field_name }}</td>
                <td class="">{{ feature.field_value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../components/common/Breadcrumbs';
import CatalogService from '../services/CatalogService';
import VueTinySlider from 'vue-tiny-slider';
import ProductItemSliderArrowButton from '../components/common/ProductItemSliderArrowButton';
import OrderQuantity from '../components/common/OrderQuantity';
import Vue from 'vue';
import ZoomOnHover from 'vue-zoom-on-hover';
import { mapActions, mapGetters } from 'vuex';
import { setPageTitle } from '../helpers/helpers';
import imagesMixin from '@/mixins/images-mixin';
import Loading from '@/components/common/Loading';

import modal from '../components/modal/modal_stores.vue';

Vue.use(ZoomOnHover);

export default {
  name: 'Product',
  components: {
    OrderQuantity,
    ProductItemSliderArrowButton,
    Breadcrumbs,
    VueTinySlider,
    modal,
    Loading  
  },
  mixins: [imagesMixin],
  data() {
    return {
      productInfo: null,
      featuresInfo: null,
      images: null,
      qty: 1,
      showmodal: false,
      storeslist: null,
      aqciebiInfo: null,
      isLoading: false
    };
  },
  created() {
    this.getProductInfo();
    this.getProductFeatures();
    this.getAqciebi();   
  },
    
    
  beforeRouteUpdate(to, from, next) {
    this.getProductInfo();
    this.getProductFeatures();
    this.getAqciebi();
    next();
    
  },
  computed: {
    ...mapGetters('navigation', ['getBreadNavigationItems']),
    breadCrumbTree() {
      const categoryTree = this.getBreadNavigationItems(
        this.productInfo?.tree_id
      )?.map((item) => {
        return {
          route: {
            name: 'Catalog',
            query: { id: item.ID }
          },
          meta: {
            title: item.Name
          }
        };
      });

      categoryTree?.push({
        route: {
          name: 'Product',
          params: { id: this.productInfo?.item }
        },
        meta: {
          title: this.productInfo?.item_price_name
        }
      });

      return categoryTree;
    }
  },
  methods: {
    ...mapActions('basket', ['updateBasket']),
    getstores(item_code) {
    this.showmodal = true,
      CatalogService.GetItemRest(item_code)
        .then(({ data }) => {
        this.storeslist = data.stores;
        })
        .catch((error) => {
          console.log(error);
        });         
    },
    handleAddClick() {
      const item = {
        item_full_name: this.productInfo.item_price_name,
        item: this.productInfo.item,
        qty: this.qty
      };
      this.updateBasket([item]);
      this.qty = 1;
    },
    getProductInfo() {
    this.isLoading = true;
      const data = {
        vat_zone: 'GEVAT',
        currency: 'GEL',
        item: parseInt(this.$route.params.id)
      };

      CatalogService.getProductItem(data)
        .then(({ data }) => {
          this.isLoading = false;
          this.productInfo = data;
          setPageTitle(data.item_price_name);
          this.getProductImages();
        
        if (!this.productInfo.item) { this.$router.push('/404'); }  
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
                   
    getProductImages() {
      this.images = this.getImages(
        this.productInfo.item,
        this.productInfo.img_count,
        700
      );
    },
      
    getAqciebi () {
    const data = parseInt(this.$route.params.id);
    CatalogService.GetAqciebi(data)
        .then(({ data }) => {
          this.aqciebiInfo = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
      
      
    getProductFeatures() {
      const data = {
        item: parseInt(this.$route.params.id)
      };

      CatalogService.getProductFeatures(data)
        .then(({ data }) => {
          this.featuresInfo = data.features
            ? this.groupFeatures(data.features)
            : [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    groupFeatures(features) {
      const groupedFeatures = {};
      features.forEach((item) => {
        (groupedFeatures[item['group_name']] =
          groupedFeatures[item['group_name']] || []).push(item);
      });
      return groupedFeatures;
    },
    updateSlider(index) {
      this.$refs.slider.slider.goTo(index);
      // this.$refs.groupSlider.slider.goTo(index - 1);
    }
  }
};
</script>

<style lang="scss">
.aqciebiInfo_class {width: 60%}
.aqciebiInfo_class_qveda {width: 100%; margin: 20px 0px !important;}
.aqciis_box {padding: 10px 5px;width: 100%;margin-bottom: 10px;border: 1px solid #B8B5B5; padding: 10px;}
.aqciis_box_qveda {padding: 10px 5px;width: 100%;margin-bottom: 10px; padding: 10px;}
.aqciis_box:hover{border: 1px solid #482080;}
.aqciis_box ul {margin-left: 0px;padding-left: 0px;margin-top: 5px;}
.aqciis_box ul li {padding-bottom: 5px; margin-bottom: 10px;}
.aqciis_piroba {font-weight: bold; color: #482080;}
.saaqcio_fasi {font-weight: bold; color: #f77e0b;}
    
.icon-info-svg-product {
  width: 30px;
  margin-right: -30px;
  cursor: help;
}
.product {
  padding-top: $goods-menu-height-mobile;

  @include media-breakpoint-up(md) {
    padding-top: $goods-menu-height;
  }

  &__img-slider-container {
    width: 100%;
    height: rem(280);
    border: 1px solid $border-gray;
  }

  &__img-container {
    img {
      &:first-child {
        margin-top: rem(20);
        margin-bottom: rem(20);
        height: rem(240);
        object-fit: contain;
        width: 100%;
      }
    }
  }

  &__group-slider {
    width: calc(100% - #{rem(64)});
    margin-left: auto;
    margin-right: auto;
  }

  &__group-img {
    height: rem(80);
    border: 1px solid $border-gray;
  }

  &__code {
    border: 1px solid rgba($dark-purple, 0.2);
  }

  &__in-stock {
    padding-left: 27px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: $light-purple;
      width: 10px;
      height: 10px;
    }
  }

  &__specifications-table {
    td {
      padding: 10px;

      $table-border-radius: 5px;

      &:first-child {
        border-top-left-radius: $table-border-radius;
        border-bottom-left-radius: $table-border-radius;
      }

      &:first-child {
        border-top-right-radius: $table-border-radius;
        border-bottom-right-radius: $table-border-radius;
      }
    }

    tr:nth-child(odd) {
      background: #f7f8fa;
    }
  }

  &__price-container {
    border-bottom: 1px solid $border-gray;

    .price-amount {
      font-size: rem(20);

      @include media-breakpoint-up(md) {
        font-size: rem(28);
      }
    }
  }

  .product-item-slider-arrow-button {
    top: 50%;
    left: rem(-32);
    transform: translateY(-50%);

    &--right {
      left: auto;
      right: rem(-32);
    }
  }
}
</style>
