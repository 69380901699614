<template>
  <div id="app" :class="{ loggedIn }">
    <TheHeader v-if="loggedIn" />
    <router-view />
    <TheFooter v-if="loggedIn" />
    <notifications group="all" :duration="5000" position="bottom right" />
  </div>
</template>
<script>
import TheHeader from './components/partials/TheHeader';
import TheFooter from './components/partials/TheFooter';
import { mapGetters } from 'vuex';
// import GoodsMenu from './components/goods-menu/GoodsMenu';
export default {
  components: { TheFooter, TheHeader },
  computed: {
    ...mapGetters('auth', ['loggedIn'])
  }
};
</script>

<style lang="scss">
.vue-notification {
  margin: 5px !important;

  &.warn {
    background: $purple !important;
    border-left-color: darken($purple, 5) !important;
  }
  &.error {
    background: $red !important;
    border-left-color: darken($red, 5) !important;
  }
  &.success {
    background: #5d965c !important;
    border-left-color: darken(#5d965c, 5) !important;
  }

  border-left-width: 4px;

  .notification-title {
    font-family: BPG Nino Mtavruli, sans-serif;
  }

  .notification-content {
    font-family: BPG Phone Sans, sans-serif;
  }
}
</style>
