import apiClient from './api-client';

export default {
  getNews(perPage, page) {
    return apiClient.get(`customer-news?page=${page}&per_page=${perPage}`);
  },
  getNewsById(id) {
    return apiClient.get('customer-news-nested/', {
      params: {
        news_id: id
      }
    });
  }
};
