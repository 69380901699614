<template>
  <div
    class="breadcrumbs d-flex flex-wrap bpg-phone-sans font-size-12 color-text-gray align-items-center my-md-4"
  >
    <div
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      class="breadcrumbs__item d-flex mb-2 mb-md-0 align-items-center"
    >
      <div
        v-if="breadcrumb.meta.disableBreadcrumbsLink"
        class="breadcrumbs__item-link"
      >
        {{ breadcrumb.meta.title }}
      </div>
      <router-link v-else :to="breadcrumb.route" class="breadcrumbs__item-link">
        {{ breadcrumb.meta.title }}
      </router-link>
      <div>
        <i class="breadcrumbs__item-icon icon-arrow-down font-size-5"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    navigationList: {
      type: [Array, null]
    },
    isLoading: {
      type: [Number, Boolean, null]
    }
  },
  computed: {
    breadcrumbs() {
      const breadcrumbsArr = [
        {
          route: { name: 'Home' },
          meta: { title: 'მთავარი' }
        }
      ];

      if (this.navigationList)
        return breadcrumbsArr.concat(this.navigationList);

      const mappedBreadcrumbs = this.$route.matched.reduce((filtered, item) => {
        if (!item.meta.hideFromBreadcrumbs) {
          const name = item.name === 'NewsNested' ? 'News' : item.name;
          filtered.push({ route: { to: name }, meta: item.meta });
        }
        return filtered;
      }, []);

      return breadcrumbsArr.concat(mappedBreadcrumbs);
    }
  }
};
</script>

<style lang="scss">
.breadcrumbs {
  &__item {
    &:last-child {
      .breadcrumbs__item-link {
        text-decoration: none;
      }
      .breadcrumbs__item-icon {
        display: none;
      }
    }
  }

  &__item-icon {
    writing-mode: vertical-rl;
    margin: 0 rem(13);
    transform: rotate(270deg);
    padding-bottom: rem(3);
  }
}
</style>
