<template>
  <div class="bg-color-bg-gray d-flex pt-3">
    <div class="container mt-5">
      <Breadcrumbs />
      <SectionTitle>
        სიახლეები
      </SectionTitle>
      <div class="my-4">
        <PageTransition>
          <router-view />
        </PageTransition>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/common/SectionTitle';
import Breadcrumbs from '@/components/common/Breadcrumbs';
import PageTransition from '../../components/common/PageTransition';
export default {
  name: 'NewsLayout',
  components: { PageTransition, Breadcrumbs, SectionTitle }
};
</script>
