<template>
  <form
    @submit.prevent="submitSearch()"
    class="search-input position-relative align-items-center"
  >
    <input
      v-model="keyword"
      type="text"
      placeholder="მოძებნე პროდუქტი (ჩაწერეთ მინ. 5 სიმბოლო)"
      class="search-input__input font-size-14 w-100"
    />
    
    <i
      class="search-input__icon icon-search position-absolute color-icon-gray font-size-18 cursor-pointer p-2"
      @click="submitSearch()"
    ></i>
  </form>
</template>

<script>


export default {
  name: 'SearchInput',
  components: {
    
  },
  props: {
    goods: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      keyword: '',
      selectedCategory: ''
    };
  },
  watch: {
    topLevelCategories() {
      this.selectedCategory = this.topLevelCategories[0].id;
    }
  },
  computed: {
    topLevelCategories() {
      return this.goods.map((category) => {
        return {
          id: category.ID,
          label: category.Name
        };
      });
    }
  },
  methods: {
    async submitSearch() {
      this.$router.push({
        path: '/catalog',
        query: { id: "", keyword: this.keyword, all_items: "Y", t: new Date().getTime() }
      });
    }
  }
};
</script>

<style lang="scss">
$category-selector-width: 235;
.search-input {
  display: flex;
  height: 100%;

  &__input {
    padding: rem(15) rem(45) rem(15) rem(10);
    height: 100%;
  }

  &__icon {
    right: rem(5);
    top: 50%;
    transform: translateY(-50%);
  }

  &__category-selector {
    position: absolute !important;
    min-width: auto !important;
    width: rem($category-selector-width);
    right: 40px;
  }

  .vs--single.vs--open .vs__selected {
    position: relative;
  }

  .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vs--unsearchable .vs__search {
    display: none;
  }

  .style-chooser .vs__selected-options {
    transition: padding 0.3s ease;
  }
}
</style>
