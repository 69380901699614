import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import News from '../views/news/News';
import NewsNested from '../views/news/NewsNested';
import LoginUser from '../views/auth/LoginUser';
import RegisterUser from '../views/auth/RegisterUser';
import Error from '../views/Error';
import Ledger from '../views/dashboard/Ledger';
import Card from '../views/dashboard/Card';
import store from '../store';
import MyProfile from '../views/dashboard/MyProfile';
import Auth from '../views/auth/Auth';
import NewsWrapper from '../views/news/NewsWrapper';
import Dashboard from '../views/dashboard/Dashboard';
import ChangePassword from '../views/dashboard/ChangePassword';
import PenaltyOrders from '../views/dashboard/PenaltyOrders';
import Orders from '../views/dashboard/Orders';
import OpenOrders from '../views/dashboard/OpenOrders';
import Basket from '../views/Basket';
import Catalog from '../views/Catalog';
import Product from '../views/Product';
import OrderNested from '../views/dashboard/OrderNested';
import OpenOrderNested from '../views/dashboard/OpenOrderNested';
import NewProducts from '../views/NewProducts';
import Promos from '../views/Promos';
import GetCatalog from '../views/GetCatalog';
import GetPos from '../views/GetPos';
import { setPageTitle } from '../helpers/helpers';

Vue.use(VueRouter); 

const routes = [
  {
    path: '*',
    name: 'errorRedirect',
    redirect: '/404'
  },  
 {
    path: '/404',
    name: 'error',
    component: Error,
    meta: {
    title: '404',
    requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'მთავარი',
      requiresAuth: true
    }
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product,
    meta: {
      title: 'პროდუქტი',
      requiresAuth: true,
      hideFromBreadcrumbs: true
    }
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket,
    meta: {
      title: 'კალათა',
      requiresAuth: true
    }
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog,
    meta: {
      title: 'კატალოგი',
      requiresAuth: true
    }
  },
  {
    path: '/news-wrapper',
    name: 'NewsWrapper',
    component: NewsWrapper,
    redirect: {
      name: 'News'
    },
    meta: {
      title: 'სიახლეები',
      requiresAuth: true,
      hideFromBreadcrumbs: true
    },
    children: [
      {
        path: '/news',
        name: 'News',
        component: News,
        meta: {
          title: 'სიახლეები',
          requiresAuth: true
        }
      },
      {
        path: '/news/:id',
        name: 'NewsNested',
        component: NewsNested,
        meta: {
          title: 'სიახლეები',
          requiresAuth: true
        }
      }
    ]
  },
    {
        path: '/new-products',
        name: 'NewProducts',
        component: NewProducts,
        meta: {
          title: 'ახალი პროდუქტები',
          requiresAuth: true
        }
    },
    {
        path: '/promos',
        name: 'Promos',
        component: Promos,
        meta: {
          title: 'მიმდინარე აქციები',
          requiresAuth: true
        }
    },
    {
        path: '/get-catalog',
        name: 'GetCatalog',
        component: GetCatalog,
        meta: {
          title: 'სასაქონლო კატალოგი',
          requiresAuth: true
        }
    },
    {
        path: '/get-pos',
        name: 'GetPos',
        component: GetPos,
        meta: {
          title: 'თვითმომსახურების პროგრამა პერსონალური კომპიუტერისათვის ',
          requiresAuth: true
        }
    },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    redirect: {
      name: 'login'
    },
    meta: {
      title: 'აუტენთიფიკაცია'
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: LoginUser,
        meta: {
          title: 'ავტორიზაცია',
          hideForAuth: true
        }
      },
      {
        path: '/register',
        name: 'register',
        component: RegisterUser,
        meta: {
          title: 'რეგისტრაცია',
          hideForAuth: true
        }
      }
    ]
  },
  {
    path: '/open-orders/:id/:type',
    name: 'OpenOrderNested',
    component: OpenOrderNested,
    meta: {
      title: 'ღია შეკვეთა',
      requiresAuth: true
    }
  },
  {
    path: '/orders/:id/:type',
    name: 'OrderNested',
    component: OrderNested,
    meta: {
      title: 'შეკვეთა',
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    redirect: {
      name: 'MyProfile'
    },
    meta: {
      title: 'ინფორმაცია მომხმარებელზე',
      disableBreadcrumbsLink: true
    },
    children: [
      {
        path: '/my-profile',
        name: 'MyProfile',
        component: MyProfile,
        meta: {
          title: 'ჩემი მონაცემები',
          requiresAuth: true
        }
      },
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
          title: 'პაროლის შეცვლა',
          requiresAuth: true
        }
      },
      {
        path: '/card',
        name: 'Card',
        component: Card,
        meta: {
          title: 'ბარათი',
          requiresAuth: true
        }
      },
      {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        meta: {
          title: 'შეკვეთები',
          requiresAuth: true
        }
      },
      {
        path: '/open-orders',
        name: 'OpenOrders',
        component: OpenOrders,
        meta: {
          title: 'ღია შეკვეთები',
          requiresAuth: true
        }
      },
      {
        path: '/ledger',
        name: 'Ledger',
        component: Ledger,
        meta: {
          title: 'ამონაწერი',
          requiresAuth: true
        }
      },
    
      {
        path: '/penalty-orders',
        name: 'PenaltyOrders',
        component: PenaltyOrders,
        meta: {
          title: 'ვადაგადაცილებული ინვოისები',
          requiresAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: 'smooth' };
    }
  }
});

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title);

  const loggedIn =
    store.getters['auth/loggedIn'] || localStorage.getItem('user');
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !loggedIn) {
    next('/login');
  } else if (!requiresAuth && loggedIn) {
    next('/');
  }
  next();
});

export default router;
