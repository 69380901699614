import AuthService from '../../services/AuthService';
import apiClient from '../../services/api-client';

export const namespaced = true;

export const state = {
  isLoading: false,
  user: null,
  saveUserLoggedIn: false
};

export const actions = {
  registerUser({ commit }, credentials) {
    commit('SET_LOADING_STATUS', true);

    AuthService.registerUser(credentials)
      .then((response) => {
        commit('SET_USER_DATA', response.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        commit('SET_LOADING_STATUS', false);
      });
  },
  // async loginUser({ commit }, credentials) {
  //   commit('SET_LOADING_STATUS', true);
  //   return await AuthService.loginUser(credentials)
  //     .then((response) => {
  //       if (response.data.token) {
  //         commit('SET_USER_DATA', response.data, credentials.saveUserLoggedIn);
  //       }
  //       return response;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       return error;
  //     })
  //     .finally(() => {
  //       commit('SET_LOADING_STATUS', false);
  //     });
  // },
  getUser({ commit }) {
    commit('SET_LOADING_STATUS', true);
    return AuthService.getUser()
      .then(({ data, status }) => {
        if (status === 200 && data.error_desc === 'login successed!') {
          commit('SET_USER_DATA', data);
        }
        return data;
      })
      .catch((error) => {
        commit('REMOVE_USER_DATA');
        console.log(error);
        return error;
      })
      .finally(() => {
        commit('SET_LOADING_STATUS', false);
      });
  },
  logout({ commit }) {
    commit('REMOVE_USER_DATA');
  }
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    apiClient.defaults.headers['Authorization'] = `Bearer ${userData.token}`;
    // if (state.saveUserLoggedIn) {
    localStorage.setItem('user', JSON.stringify(userData));
    // }
  },
  REMOVE_USER_DATA() {
    localStorage.removeItem('user');
    location.reload();
  },
  SET_LOADING_STATUS(state, value) {
    state.isLoading = value;
  },
  SET_USER_LOGGED_IN(state, value) {
    state.saveUserLoggedIn = value;
    localStorage.setItem('saveUserLoggedIn', JSON.stringify(value));
  }
};

export const getters = {
  loggedIn({ user }) {
    return !!user;
  },
  getUser({ user }) {
    return user;
  },
  getCurrency({ user }) {
    return user?.currency;
  },
  isLoading({ isLoading }) {
    return isLoading;
  }
};
