<template>
  <div class="dashboard d-flex bg-color-bg-gray">
    <div class="container-fluid px-0">
      <div class="container">
        <Breadcrumbs />
      </div>
      <div class="container d-flex">
        <div class="dashboard__sidebar d-none d-md-block pr-3">
          <nav class="dashboard__nav border-radius-s bg-color-white h-100">
            <ul class="dashboard__nav-list p-0">
              <li
                v-for="route in getDashboardRoutes"
                :key="route.name"
                class="dashboard__nav-list-item"
              >
                <router-link
                  :to="{ name: route.name }"
                  class="dashboard__nav-link exact bpg-phone-sans font-size-12 nav-link text-decoration-none"
                >
                  {{ route.meta.title }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="dashboard__content p-0 pl-md-3">
          <div
            :class="{
              'dashboard__content-child border-radius-s bg-color-white h-100':
                $route.name !== 'MyProfile'
            }"
          >
            <PageTransition>
              <router-view />
            </PageTransition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/common/Breadcrumbs';
import PageTransition from '@/components/common/PageTransition';
import dashboardNavigationMixin from '@/mixins/dashbaord-navigation-mixin';
export default {
  name: 'Dashboard',
  components: { PageTransition, Breadcrumbs },
  mixins: [dashboardNavigationMixin]
};
</script>

<style lang="scss">
.dashboard {
  padding-top: $goods-menu-height-mobile;
  padding-bottom: rem(80);

  @include media-breakpoint-up(md) {
    padding-top: $goods-menu-height;
  }

  &__sidebar {
    width: 30%;
  }

  &__nav {
    padding: rem(28) rem(38);
  }

  &__nav-list {
    position: sticky;
    top: calc(#{$goods-menu-height} + #{rem(80)});
  }

  &__nav-list-item {
    border-bottom: 1px solid $border-gray;
    margin-bottom: rem(22);
    padding-bottom: rem(24);
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &__content {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 70%;
    }
  }

  &__content-child {
    padding: rem(25) rem(20);

    @include media-breakpoint-up(md) {
      padding: rem(27) rem(35);
    }
  }
}
</style>
