<template>
  <div class="profile-navigation px-4">
    <div class="font-size-12 color-dark-gray bpg-phone-sans">
      <router-link
        v-for="(route, index) in getDashboardRoutes"
        :key="index"
        :to="{ name: route.name }"
        @click.native.prevent="openDashboardRoute(route)"
        class="profile-navigation__link cursor-pointer d-flex text-decoration-none nav-link"
      >
        {{ route.meta.title }}
      </router-link>
    </div>
    <div
      @click="logout"
      class="profile-navigation__logout d-flex align-items-center justify-content-center font-size-12 cursor-pointer"
    >
      <div class="bpg-nino-mtarvruli">
        <i
          class="profile-navigation__logout-icon d-inline-block icon-logout mr-2"
        /><span>გამოსვლა</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import dashboardNavigationMixin from '@/mixins/dashbaord-navigation-mixin';

export default {
  name: 'HeaderProfileNavigation',
  props: ['closeDropdown'],
  methods: {
    ...mapActions('auth', ['logout']),
    openDashboardRoute() {
      this.closeDropdown();
    }
  },
  mixins: [dashboardNavigationMixin]
};
</script>

<style lang="scss">
.profile-navigation {
  min-width: rem(213);
  &__link {
    padding: rem(21) 0;
    border-bottom: 1px solid $border-gray;
    white-space: nowrap;
  }

  &__logout {
    padding: rem(21) 0;
    color: #707070;
  }

  &__logout-icon {
    transform: rotate(180deg);
  }
}
</style>
