<template>
  <div class="modal position-absolute position-full">
    <Loading v-if="fetching" />
    <div
      @click="closePopup"
      class="modal__background-overlay position-fixed position-full"
    ></div>
    <div
      class="modal__content position-absolute border-radius-s bg-color-white"
      :class="{ mounted: mounted }"
    >
      <div
        class="status-popup__header d-flex align-items-center justify-content-between pb-3"
      >
        <div
          class="bpg-phone-sans font-size-14 colo-dark-gray d-flex align-items-center"
        >
          ნაშთი საწყობებში
        </div>
        <div
          class="status-popup__close-container position-relative cursor-pointer d-flex text-decoration-none tooltip-parent"
        >
          <i
            @click="closePopup"
            class="icon-close1 font-size-13 color-orange"
          ></i>
        </div>
      </div>
      <table class="table w-100"> 
        <thead>
          <tr >
            <th><strong>საწყობი</strong></th>
            <th><strong>მისამართი</strong></th>
            <th style="min-width:70px;"><strong>ნაშთი</strong></th>
          </tr>
        </thead>
        <tbody>
          <slot></slot>  
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/common/Loading';

export default {
  name: 'modal',
  components: {
    Loading
  },
  props: { 
      
         },
  data() {
    return {
    };
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDownListener);
  },
  methods: {
    init() {
    },

    closePopup() {
      this.$emit('close');
    },
    keyDownListener(e) {
      if (e.key === 'Escape') {
        this.closePopup();
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss">
.modal {
  z-index: 9;

  &__background-overlay {
    background: rgba($dark-gray, 0.5);
  }

  &__content {
    padding: rem(22) rem(25) rem(40);
    top: 3%;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: all 0.3s ease;
    opacity: 0;
    width: calc(100% - #{rem(30)});

    @include media-breakpoint-up(md) {
      max-width: rem(780);
      padding: rem(22) rem(40) rem(40);
    }

    &.mounted {
      transform: translate(-90%, 0);
      opacity: 1;
    }
  }

  &__header {
    border-bottom: 3px solid $border-gray;
  }

  &__tab-switcher {
    background-color: rgba(#f7f8fa, 0.66);

    .base-button {
      background-color: rgba(#f7f8fa, 0.66);
      color: #3a3e4c;
      box-shadow: none !important;
      flex: 1;
      justify-content: center;

      &.active {
        color: #f77e0b;
        background-color: #eff0f2;
      }
    }

    .divider {
      padding: 0 1em;
    }
  }

  .vs--disabled {
    .vs__dropdown-toggle {
      background: #f7f8fa;
    }
  }
}
</style>
