<template>
  <div
    class="basket-count-text d-flex bpg-nino-mtarvruli font-size-22 color-text-gray align-items-center"
  >
    {{ text }}:
    <span class="color-dark-purple font-size-22 font-weight-bold ml-2">
      {{ count }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BasketCountText',
  props: {
    text: {
      type: String,
      required: true
    },
    count: {
      type: [Number, String],
      required: true
    }
  }
};
</script>
