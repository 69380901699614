<template>
  <div>
    <div
      class="aside-dropdown cursor-pointer bpg-phone-sans font-size-16 font-weight-bold d-flex align-items-center justify-content-between"
      :class="{ active: show }"
      @click="show = !show"
    >
      <div>{{ title }}</div>
      <i
        class="aside-dropdown__icon icon-arrow-down font-size-6 color-dark-gray"
      ></i>
    </div>
    <div class="aside-dropdown__content mb-3" :class="{ active: show }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AsideDropdown',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: true
    };
  }
};
</script>

<style lang="scss">
.aside-dropdown {
  padding: rem(20) 0;
  border-top: 1px solid $border-gray;
  &:last-child {
    border-bottom: 1px solid $border-gray;
  }

  &__icon {
    transform: rotate(-90deg);
    transition: transform 0.2s ease;
  }

  &.active {
    .aside-dropdown__icon {
      transform: rotate(0);
    }
  }

  &__content {
    display: none;
    &.active {
      display: flex;
    }
  }
}
</style>
