<template>
  <div
    class="loading position-full position-fixed d-flex align-items-center justify-content-center"
  >
    <i class="loading__icon icon-refresh font-size-48 color-white"></i>
  </div>
</template>

<script>
export default {
  name: 'Loading'
};
</script>

<style lang="scss">
.loading {
  background: rgba($dark-gray, 0.6);
  z-index: 99999;

  &__icon {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>
