<template>
  <div
    class="header-cart-item d-flex align-items-center justify-content-between"
  >
    <div
      class="header-cart-item__left d-flex align-items-center justify-content-between"
    >
      <div class="header-cart-item__close-container">
        <div
          class="header-cart-item__close tooltip-parent position-relative bg-color-text-gray border-radius-50 d-flex align-items-center justify-content-center"
          @click="removeBasketItems([data.item])"
        >
          <div
            class="header-cart-item__close-tooltip position-absolute py-2 px-3 border-radius-s bg-color-white color-text-gray bpg-phone-sans font-size-12"
          >
            წაშლა
          </div>
          <i
            class="header-cart-item__icon-close icon-close1 font-size-5 color-white"
          ></i>
        </div>
      </div>
      <router-link :to="{ name: 'Product', params: { id: data.item } }">
        <div
          class="header-cart-item__img-container d-flex align-items-center justify-content-center"
        >
          <img class="header-cart-item__img" :src="image" />
        </div>
      </router-link>
    </div>
    <div class="flex-1 ml-3 color-dark-gray bpg-phone-sans">
      <router-link :to="{ name: 'Product', params: { id: data.item } }">
        <div
          class="header-cart-item__title font-size-10 mb-2"
          :title="data.item_full_name"
        >
          {{ data.item_full_name }}
        </div>
      </router-link>
      <div
        class="d-flex align-items-center justify-content-between font-size-12 "
      >
        <div class="d-flex align-items-center">
          <div>
            <OrderQuantity v-model="data.qty" @input="onQuantityInput(data)" />
          </div>
        </div>
        <div>{{ data.price_total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OrderQuantity from '@/components/common/OrderQuantity';
import imagesMixin from '@/mixins/images-mixin';

export default {
  name: 'HeaderBasketItem',
  components: { OrderQuantity },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  mixins: [imagesMixin],
  data() {
    return {
      image: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('basket', ['removeBasketItems', 'updateBasket']),
    onQuantityInput(item) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (parseInt(item.qty) > 0) {
          this.updateBasket([item]);
        }
      }, 1000);
    },
    init() {
      if (!this.data.img_count) {
        this.image = require(`@/assets/images/no-image.png`);
        return;
      }

      this.image = this.getImageLink(this.data.item);
    }
  }
};
</script>

<style lang="scss">
.header-cart-item {
  border-bottom: 1px solid $bg-gray;
  padding-bottom: rem(12);
  padding-top: rem(25);

  &:last-child {
    border-bottom: none;
  }

  &__left {
    width: rem(60);
  }

  &__title {
    overflow: hidden;
    width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &:first-child {
    padding-top: 0;
  }

  &__close-container {
    padding: rem(5);

    &:hover {
      .header-cart-item__close-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__close {
    width: 11px;
    height: 11px;

    &:hover {
      background: $light-purple;
    }
  }

  &__close-tooltip {
    left: 16px;
    transform: translateY(-50%);
    top: 50%;
    border: 1px solid $border-gray;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease;
  }

  &__img-container {
    width: rem(40);
    height: rem(40);
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
  }

  &__counter-button {
    width: rem(20);
    height: rem(20);

    &:hover {
      background: $border-gray;
    }
  }
}
</style>
