<template>
  <div class="card">
    <div v-if="data">
      <h2 class="dashboard-page-title mb-4">ბარათი</h2>
      <div class="d-flex flex-wrap">
        <ReportItem
          title="მიმდინარე ბალანსი"
          v-if="data.current_balance"
          :value="`${data.current_balance} ${data.credit_currency}`"
          class="mr-5"
          font-color="dark-gray"
        />
        <ReportItem
          title="საკრედიტო ლიმიტი"
          v-if="data.credit_limit"
          :value="`${data.credit_limit} ${data.credit_currency}`"
          class=""
          font-color="dark-gray"
        />
      </div>
      <div class="d-flex mt-md-3 flex-wrap">
        <ReportItem
          title="ბარათის გახსნის თარიღი"
          :value="data.account_opened"
          class="mr-5"
          font-color="dark-gray"
        />
        <ReportItem
          title="ბოლო გადახდის თარიღი"
          :value="data.date_last_paid"
          class="mb-4"
          font-color="dark-gray"
        />
      </div>
      <div v-if="data.penalty_items">
        <h2 class="dashboard-page-title mt-4">ვადაგადაცილებები</h2>
        <div class="card-table mt-3">
          <div
            class="card-table__tr font-size-12 color-text-gray bpg-phone-sans"
          >
            <div class="card-table__th">
              პერიოდი
            </div>
            <div class="card-table__th text-right">
              საჯარიმო თანხა
            </div>
            <div class="card-table__th">
              ვალუტა
            </div>
          </div>
          <div
            v-for="(item, index) in data.penalty_items.items"
            :key="index"
            class="card-table__tr font-size-14 bpg-phone-sans color-dark-gray"
          >
            <div class="card-table__td">
              {{ item.penalty_period }}
            </div>
            <div class="card-table__td color-red text-right">
              {{ item.penalty_amount }}
            </div>
            <div class="card-table__td">
              {{ item.currency }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import ReportItem from '../../components/common/ReportItem';
import CustomerService from '../../services/CustomerService';
import Loading from '../../components/common/Loading';
export default {
  name: 'Card',
  components: { Loading, ReportItem },
  data() {
    return {
      data: null,
      isLoading: true
    };
  },
  created() {
    this.isLoading = true;
    CustomerService.getCustomerCard()
      .then(({ data }) => {
        this.data = data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>

<style lang="scss">
.card-table {
  display: table;
  width: 100%;
  &__tr {
    border-radius: 5px;
    display: table-row;

    // width: auto;
    &:first-child {
      background: $bg-gray;
    }
  }
  &__th {
    padding: rem(15) rem(20);

    @include media-breakpoint-up(md) {
      padding: rem(23) rem(43);
    }
  }
  &__th,
  &__td {
    display: table-cell;
    &:last-child {
      // padding-right: 25%;
    }
  }
  &__td {
    padding: rem(10) rem(20) 0;

    @include media-breakpoint-up(md) {
      padding: rem(16) rem(43) 0;
    }
  }
}
</style>
