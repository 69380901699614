<template>
  <div class="d-flex align-items-center justify-content-center">
    <ValidationObserver v-slot="{ handleSubmit }" class="w-100">
      <form @submit.prevent="handleSubmit(onSubmit)" class="w-100">
        <div class="d-flex flex-column">
          <AuthInputContainer
            v-model="user"
            icon-name="person" 
            font-size="13"
            title="მომხმარებლის სახელი ან ელ-ფოსტა"
            placeholder="სახელი"
            name="user"
            ref-string="user"
            rules="required"
            class="mb-3"
          />
          <AuthInputContainer
            v-model="password"
            icon-name="lock"
            font-size="15"
            title="პაროლი"
            placeholder="პაროლი"
            name="password"
            ref-string="password"
            rules="required"
            input-type="password"
          />
          <BaseCheckbox
            class="mt-5 mb-4"
            v-model="saveUserLoggedIn"
			name="save_session"
			true-value="1"
			false-value="0"
            :custom-value="1"
          >
            დამიმახსოვრე
          </BaseCheckbox>
          <!-- <ValidationProvider
            rules="checkbox"
            v-slot="{ errors }"
            name="recaptcha"
            ref="recaptcha"
            class="mb-3"
          >
            <ccaptcha
              v-model="reCaptchaChecked"
              @verify="recaptchaVerified"
              :loadRecaptchaScript="true"
              sitekey="6LcfDEcdAAAAAImX9LS3EvPHk5idWntG32q5XTrO"
              class="g-recaptcha"
            ></ccaptcha>
            <div
              v-if="errors[0]"
              class="mt-2 color-red font-size-10 bpg-phone-sans"
            >
              {{ errors[0] }}
            </div>
          </ValidationProvider> -->
          <div class="d-flex mb-4 justify-content-end">
            <vue-recaptcha
              :sitekey="siteKey"
              ref="recaptcha"
              @verify="verifyMethod"
              @expired="onCaptchaExpired"
            ></vue-recaptcha>
          </div>
          <div
            class="d-flex align-items-center justify-content-between color-text-gray"
          >
            <router-link :to="{ name: 'register' }" class="font-size-12 bpg-phone-sans">რეგისტრაცია</router-link>
            <a href=""></a>
            <BaseButton :is-loading="isLoading" :disabled="!token" type="submit"
              >შესვლა</BaseButton
            >
          </div>
        </div>
      </form>
    </ValidationObserver>
    <transition name="fade">
      <StatusPopup
        v-if="statusPopupIsOpen"
        :status-is-success="statusIsSuccess"
        :visible="statusPopupIsOpen"
        @close="statusPopupIsOpen = false"
      >
        <template v-slot:header>
          {{ status.title }}
        </template>
        <template v-slot:body>
          {{ status.title }}
        </template>
        <template v-slot:buttons="slotProps">
          <BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
        </template>
      </StatusPopup>
    </transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import authMixin from '../../mixins/auth-mixin';
import AuthService from '../../services/AuthService';
// import recaptchaMixin from '../../mixins/recaptcha-mixin';
import AuthInputContainer from '../../components/form-components/AuthInputContainer';
import BaseCheckbox from '../../components/form-components/BaseCheckbox';
import StatusPopup from '../../components/common/StatusPopup';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'LoginUser',
  mixins: [authMixin],
  components: { StatusPopup, BaseCheckbox, AuthInputContainer, VueRecaptcha },
  data() {
    return {
      siteKey: '',
      isLoading: false,
      token: '',
      user: '',
      password: '',
      saveUserLoggedIn: false,
      statusPopupIsOpen: false,
      status: {
        title: 'ავტორიზება ვერ განხორციელდა',
        body: `ავტორიზება ვერ განხორციელდა, გთხოვთ შეამოწმოთ მონაცემები`
      },
      statusIsSuccess: false
    };
  },
  created() {
    this.siteKey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  },
  mounted() {
    // await this.$recaptchaLoaded();
    // this.$recaptchaInstance.showBadge();
  },
  destroyed() {
    // this.$recaptchaInstance.hideBadge();
  },
  methods: {
    ...mapMutations('auth', ['SET_USER_LOGGED_IN', 'SET_USER_DATA']),
    verifyMethod(response) {
      this.token = response; 
    },
    onCaptchaExpired: function() {
      this.$refs.recaptcha.reset();
    },
    async onSubmit() {
      this.isLoading = true;
      // const token = await this.$recaptcha('auth');

      const credentials = {
        user: this.user,
        password: this.password,
		save_session: this.saveUserLoggedIn ? 1 : 0,
        token: this.token
      };

      this.SET_USER_LOGGED_IN(this.saveUserLoggedIn);
      AuthService.loginUser(credentials)
        .then((response) => {
          if (response.data.error_desc === 'login successed!') {
            this.statusPopupIsOpen = true;
            this.statusIsSuccess = true;
            this.SET_USER_DATA(response.data, credentials.saveUserLoggedIn);
          } else {
            this.$refs.recaptcha.reset();
            this.statusPopupIsOpen = true;
            this.statusIsSuccess = false;
          }
        })
        .catch((error) => {
          this.statusPopupIsOpen = true;
          this.statusIsSuccess = false;
          this.$refs.recaptcha.reset();
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
/*.g-recaptcha {*/
/*  transform: scale(0.85) !important;*/
/*  transform-origin: 0 0 !important;*/
/*}*/
</style>
