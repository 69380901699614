<template>
  <div
    class="basket-product-list-item d-flex align-items-center w-100 justify-content-around"
  >
    <i
      class="icon-delete font-size-18 color-text-gray cursor-pointer"
      @click="$emit('remove')"
    ></i>
    <router-link
      :to="{ name: 'Product', params: { id: data.item } }"
      class="pr-3 pl-3"
    >
      <div
        class="basket-product-list-item__img-container d-flex align-items-center justify-content-center"
      >
        <img
          v-if="image"
          class="basket-product-list-item__img"
          :src="image"
          :alt="data.item_full_name"
        />
      </div>
    </router-link>
    <div class="basket-product-list-item__text d-flex align-items-center">
      <router-link :to="{ name: 'Product', params: { id: data.item } }">
        <div
          class="font-size-14 color-dark-gray bpg-phone-sans line-height-1-3 text-left"
        >
          {{ data.item_full_name }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import imagesMixin from '@/mixins/images-mixin';

export default {
  name: 'BasketProductListItem',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  mixins: [imagesMixin],
  data() {
    return {
      image: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.data.img_count) {
        this.image = require(`@/assets/images/no-image.png`);
        return;
      }

      this.image = this.getImageLink(this.data.item);
    }
  }
};
</script>

<style lang="scss">
.basket-product-list-item {
  &__img-container {
    height: rem(90);
    width: rem(90);
    /*margin-left: rem(20);*/
    /*margin-right: rem(30);*/
  }
  &__img {
    max-height: 100%;
    max-width: 100%;
  }

  &__text {
    max-width: rem(220);
  }
}
</style>
