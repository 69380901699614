<template> 
  <article
    class="product-list-item  d-flex bg-color-white border-radius-s position-relative  w-100"
  >
    <div class="product-list-item__content d-flex align-items-center flex-1">
      <div class="product-list-item__slider-container mr-2">
          
<svg v-if = "data.is_promo == 'Y'" class="aqcia_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <line x1="30" y1="50" x2="70" y2="50" stroke="#90298E" stroke-width="4" />
  <line x1="50" y1="30" x2="50" y2="70" stroke="#90298E" stroke-width="4" />
  <line x1="35" y1="35" x2="65" y2="65" stroke="#90298E" stroke-width="4" />
  <line x1="35" y1="65" x2="65" y2="35" stroke="#90298E" stroke-width="4" />
</svg>
          
        <div
          :id="`product-item-slider-id-${this._uid}`"
          class="product-list-item__image-slider-buttons"
        >
          <ProductItemSliderArrowButton />
          <ProductItemSliderArrowButton is-right-direction />
        </div>
        <router-link :to="{ name: 'Product', params: { id: data.item } }">
            
          <ProductImageSlider
            :productId="data.item"
            :imageCount="data.img_count"
            :imagesMaxNumber="3"
            :uid="this._uid"
            imageClassName="product-list-item__image"
          />
        </router-link> 
      </div>
      <div
        class="product-list-item__information-container flex-1 d-flex flex-column ml-3 ml-lg-4"
      >
        <router-link
          :to="{ name: 'Product', params: { id: data.item } }"
          class="mb-3 bpg-phone-sans font-size-14 color-dark-gray line-height-1-3"
        >
          {{ data.item_price_name }}
        </router-link>
        
          
        <div class="d-flex flex-wrap align-items-center ">
          <div
            class="product-list-item__in-stock bpg-phone-sans font-size-12 mr-4 mb-2"
            :class="[isStockQuantity == '-' ? 'color-red' : 'color-dark-purple']"
          >
            მარაგშია ({{ isStockQuantity }})
          </div>
          <div
            class="product-list-item__code mb-2 px-3 py-1 bpg-phone-sans font-size-14 color-dark-gray border-radius-s"
          >
            კოდი: {{ data.item }} <br/><span  v-if="data.item != data.item_model">მოდელი: {{ data.item_model }} </span>
          </div>
        </div>
         
          
   <div v-if="isStockQuantity != '-'" class="d-flex flex-wrap align-items-center ">
    <!--<img  v-if="data.allow_sale == 0" class="icon-info-svg" alt="არ არის ხელმისაწვდომი მთავარ საწყობში" src="@/assets/svg/icon-info.svg" title="არ არის ხელმისაწვდომი მთავარ საწყობში" />-->
    <div  class="product-list-item__code mb-2 px-3 py-1 bpg-phone-sans font-size-14 color-dark-gray border-radius-s mt-2" role="button" @click="getstores({item:data.item})">
    ნაშთი საწყობებში
    </div>
    <modal v-if="showmodal" @close="showmodal = false">
      <tr v-for="store in storeslist">
        <td>{{ store.store_name }}</td>
        <td>{{ store.store_address }}</td>
        <td>{{ store.qty_text }}</td>
       </tr>  
    </modal>
    </div>  
          
          
      </div>
      <div
        class="product-list-item__price-container ml-2 ml-lg-5 d-flex justify-content-between"
      >
        <div class="product-list-item__price color-dark-purple bpg-phone-sans">
          {{ data.price_unit }} {{ data.currency }}
        </div>
        <div class="d-inline-flex py-3">
          <OrderQuantity v-model="qty" />
        </div>
        <div
          class="product-list-item__add-to-cart cursor-pointer px-4 d-flex align-items-center bpg-phone-sans font-size-14 color-white"
          @click="handleAddClick"
        >
          <i class="icon-add_to_cart font-size-14 color-white mr-2"></i>
          <div>
            დამატება
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import ProductItemSliderArrowButton from './ProductItemSliderArrowButton';
import ProductImageSlider from './ProductImageSlider.vue';
import OrderQuantity from './OrderQuantity';
import { mapActions } from 'vuex';
import CatalogService from '@/services/CatalogService';
    
import modal from '../modal/modal_stores.vue';

export default {
  name: 'ProductListItem',
  components: {
    OrderQuantity,
    ProductItemSliderArrowButton,
    ProductImageSlider,
    modal
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      qty: 1,
      showmodal: false,
      storeslist: []
    };
  },
  computed: {
    isStockQuantity() {
      return this.data.qty_text;
      // const quantity = parseInt(this.data.qty_text.replace(/\D/g, ''));
      // return quantity ? quantity : 0;
    }
  },
  methods: {
    getstores(item_code) {
    this.showmodal = true,    
      CatalogService.GetItemRest(item_code)
        .then(({ data }) => {
        this.storeslist = data.stores;
        })
        .catch((error) => {
          console.log(error);
        });         
    },
    ...mapActions('basket', ['updateBasket']),
    handleAddClick() {
      const item = {
        item_full_name: this.data.item_price_name,
        item: this.data.item,
        is_promo:this.data.is_promo,
        qty: this.qty
      };
      this.updateBasket([item]);
      this.qty = 1;
    }
  }
};
</script>

<style lang="scss">
.aqcia_icon { float: right; position: absolute; width: 40px; height: 40px; margin: -60px 0px 0px 78%;}    
.icon-info-svg {width: 30px; margin-right: 10px; float: left; cursor: help;}
.product-list-item {
  box-shadow: 0px 4px 10px $box-shadow-gray;
  border: 2px solid $bg-gray;

  &__content {
    flex-wrap: wrap;

    &:hover {
      .product-list-item__title {
        text-decoration: underline;
      }

      .product-list-item__price {
        color: $purple;
      }
    }
  }

  &__slider-container {
    position: relative;
    width: rem(120);

    @include media-breakpoint-up(md) {
      width: rem(160);
    }
  }

  &__information-container {
    padding: rem(28) rem(20) rem(14) 0;

    @include media-breakpoint-up(md) {
      padding: rem(26) rem(20) rem(24) 0;
    }

    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }

  &__price-container {
    width: 100%;
    align-items: center;
    padding: 0 rem(10);

    @include media-breakpoint-up(md) {
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      width: auto;
    }
  }

  &__image {
    width: 100%;
    height: rem(100);
  }

  &__title-container {
    height: rem(48);
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &__old-price {
    text-decoration: line-through;
  }

  &__add-to-cart {
    height: rem(44);
    border-radius: 5px;
    background: $light-purple;
    transition: all 0.2s ease;

    @include media-breakpoint-up(md) {
      border-radius: 5px 0 5px 5px;
    }

    i {
      transition: color 0.2s ease;
    }

    &:hover {
      color: $light-purple;
      background: $bg-gray;

      i {
        color: $light-purple;
      }
    }
  }

  .product-item-slider-arrow-button {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &__in-stock {
    padding-left: 24px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: $light-purple;
      width: 10px;
      height: 10px;
    }
  }

  &__code {
    border: 1px solid rgba($dark-purple, 0.2);
  }

  &__price {
    font-size: rem(16);

    @include media-breakpoint-up(md) {
      font-size: rem(18);
      margin-top: rem(28);
    }
  }
}
</style>
