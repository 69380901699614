export default {
  data() {
    return {
      paginationOptions: {
        theme: 'bootstrap4',
        edgeNavigation: true,
        texts: {
          first: 'პირველი',
          last: 'ბოლო'
        }
      }
    };
  },
  methods: {
    setUrlQuery(page) {
      this.$router
        .replace({
          query: {
            page: page
          }
        })
        .catch(() => {});
    },
    changeActiveItem(index) {
      this.activeItem = this.activeItem === index ? -1 : index;
    }
  }
};
