<template>
  <div class="catalog bg-color-bg-gray pb-5">
    <Loading v-if="isLoading" />
    <div class="container">
      <div class="d-inline-block w-100">
        <Breadcrumbs class="mb-3 mb-md-5" :navigationList="breadCrumbTree" />
        <div class="catalog__cols">
          <div class="catalog__filter-column" :class="{ open: isFilterOpen }">
            <div
              class="catalog__column-header px-3 py-2 p-md-0 d-flex justify-content-between align-items-center align-items-md-start"
            >
              <h2 class="dashboard-page-title mb-0 ml-2 mt-2 m-md-0">
                ფილტრი
              </h2>
              <button
                @click="isFilterOpen = false"
                class="d-md-none bg-color-white p-2"
              >
                <i class="font-size-18 icon-close1 color-dark-gray"></i>
              </button>
            </div>
            <aside
              class="catalog__aside bg-color-white box-shadow border-radius-s"
            >
              <div
                class="d-flex mb-4 justify-content-between bpg-phone-sans font-size-14 color-dark-gray"
              >
                <span
                  class="color-text-gray cursor-pointer"
                  @click="clearFilter()"
                  >ფილტრის მოხსნა</span
                >
              </div>
              <AsideDropdown :title="'ძებნა'">
                <div class="d-flex align-items-center w-100">
                  <BaseInput
                    v-model="keyword"
                    class="mr-3 w-100"
                    placeholder="ძებნა"
                    input-type="text"
                  />
                  <BaseButton
                    :disabled="!keyword"
                    class="px-3"
                    @click.native="filterPriceList()"
                  >
                    <i class="icon-search font-size-18"></i>
                  </BaseButton>
                </div>
              </AsideDropdown>
              <AsideDropdown :title="'ფასი'">
                <div class="d-flex align-items-center">
                  <BaseInput
                    v-model="priceFrom"
                    class="mr-3"
                    placeholder="დან"
                    input-type="number"
                  />
                  <BaseInput
                    v-model="priceTo"
                    class="mr-3"
                    placeholder="მდე"
                    input-type="number"
                  />
                  <BaseButton
                    :disabled="!priceFrom || !priceTo"
                    class="px-3"
                    @click.native="filterPriceList()"
                    >OK
                  </BaseButton>
                </div>
              </AsideDropdown>
              <AsideDropdown :title="'ბრენდი'">
                <div class="d-flex flex-column">
                  <BaseCheckbox
                    v-for="brandItem in brands"
                    :key="brandItem.brand_id"
                    v-model="brand"
                    :custom-value="brandItem.brand_id"
                    name="brand"
                    class="mb-3"
                    @input="filterPriceList()"
                  >
                    {{ brandItem.brand }} ({{ brandItem.item_count }})
                  </BaseCheckbox>
                </div>
              </AsideDropdown>
              <AsideDropdown :title="'კატეგორია'">
                <div class="d-flex flex-column">
                  <BaseCheckbox
                    v-for="categoryItem in categories"
                    :key="categoryItem.id"
                    v-model="category"
                    :custom-value="categoryItem.id"
                    name="category"
                    class="mb-3"
                    @input="filterPriceList()"
                  >
                    {{ categoryItem.name }} ({{ categoryItem.item_count }})
                  </BaseCheckbox>
                </div>
              </AsideDropdown>
              <div class="filter-button-container d-md-none">
                <button
                  @click="isFilterOpen = false"
                  class="base-button w-100 justify-content-center"
                >
                  გაფილტრვა
                </button>
              </div>
            </aside>
          </div>
          <div>
            <div
              class="catalog__column-header d-flex flex-wrap align-items-center align-items-md-start justify-content-between"
            >
              <h2 class="dashboard-page-title mb-0 pt-1">კატალოგი</h2>

              <div class="d-flex align-items-center">
                <v-select
                  :value="'მაჩვენე ' + lines_per_page + ' ნივთი'"
                  :getOptionLabel="(option) => option"
                  class="style-chooser mr-3"
                  :options="selectData"
                  @input="changePerPageInput"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class="mr-"
                      ><i
                        class="icon-arrow-down font-size-6 color-dark-gray "
                      ></i
                    ></span>
                  </template>
                </v-select>
                <div
                  class="filter-type-icon filter-type-icon&#45;&#45;grid mr-3"
                  :class="{ active: isList }"
                  @click="isList = true"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div
                  class="filter-type-icon filter-type-icon&#45;&#45;list"
                  :class="{ active: !isList }"
                  @click="isList = false"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>

              <button
                @click="isFilterOpen = !isFilterOpen"
                class="d-md-none py-2 my-4 w-100 justify-content-center base-button bg-color-gray"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5M14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5M14 5H20M10 5L4 5M16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12ZM16 12H4M8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21C7.10457 21 8 20.1046 8 19ZM8 19H20"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <span class="mt-2 font-size-18 ml-2">ფილტრი</span>
              </button>
            </div>
            <section class="row">
              <div
                v-for="(item, index) in priceList"
                :key="index"
                :class="
                  parseInt(item.RecHeader) !== 0 && !isList
                    ? 'col-6 col-md-4'
                    : 'col-12'
                "
              >
                <div
                  v-if="parseInt(item.RecHeader) === 0"
                  class="bpg-phone-sans font-size-20 color-dark-purple mb-2"
                >
                  {{ item.item_price_name }}
                </div>
                <ProductListItem
                  v-else-if="item.RecHeader !== 0 && isList"
                  class="mb-3"
                  :data="item"
                />
                <ProductItem
                  v-else-if="item.RecHeader !== 0 && !isList"
                  :data="item"
                />
              </div>
              <Pagination
                v-if="!isLoading"
                v-model="page_num"
                :records="total_lines"
                :per-page="lines_per_page"
                class="mt-5 ml-auto w-100"
                :options="paginationOptions"
                @paginate="onPaginate"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import tableMixin from '@/mixins/table-mixin';
import Breadcrumbs from '../components/common/Breadcrumbs';
import ProductListItem from '../components/common/ProductListItem';
import ProductItem from '../components/common/ProductItem.vue';
import CatalogService from '../services/CatalogService';
import AsideDropdown from '../components/catalog/AsideDropdown';
import Loading from '../components/common/Loading';
import BaseCheckbox from '../components/form-components/BaseCheckbox';
import { mapGetters } from 'vuex';

export default {
  name: 'Catalog',
  components: {
    BaseCheckbox,
    Loading,
    AsideDropdown,
    ProductListItem,
    ProductItem,
    Breadcrumbs,
    vSelect
  },
  mixins: [tableMixin],
  data() {
    const keyword = this.$route.query.keyword || '';
    return {
      tree_id: '',
      selectData: [10, 25, 50, 100],
      isList: true,
      isFilterOpen: false,
      page_num: 1,
      total_lines: 0,
      total_pages: 0,
      lines_per_page: 25,
      keyword: keyword,
      priceFrom: '',
      all_items: '',
      priceTo: '',
      priceList: [],
      brand: '',
      brands: [],
      category: '',
      categories: [],
      isLoading: false,
      paginationOptions: {
        chunk: 10,
        chunksNavigation: 'scroll',
        edgeNavigation: false
      }
    };
  },
  created() {
    this.keyword = this.$route.query.keyword || '';
    this.tree_id = this.$route.query.id;
    this.all_items = this.$route.query.all_items;
    this.page_num = Number(this.$route.query.page) || 1;
    this.lines_per_page = Number(this.$route.query.lines_per_page) || 25;
    this.filterPriceList();
  },
  beforeRouteUpdate(to, from, next) {
    this.tree_id = to.query.id;
    this.all_items = to.query.all_items;
    this.page_num = Number(to.query.page) || 1;
    this.lines_per_page = Number(to.query.lines_per_page) || 25;
    this.keyword = to.query.keyword || '';

    this.filterPriceList();
    next();
  },
  computed: {
    ...mapGetters('navigation', ['getBreadNavigationItems']),
    breadCrumbTree() {
      return this.getBreadNavigationItems(this.tree_id)?.map((item) => {
        return {
          route: {
            name: 'Catalog',
            query: { id: item.ID, lines_per_page: this.lines_per_page }
          },
          meta: {
            title: item.Name
          }
        };
      });
    }
  },
  methods: {
    changePerPageInput(event) {
      this.lines_per_page = event;
      this.$router.replace({
        name: 'Catalog',
        query: {
          ...this.$route.query,
          page: 1,
          lines_per_page: this.lines_per_page
        }
      });
    },
    clearFilter() {
      this.brand = '';
      this.category = '';
      this.priceFrom = '';
      this.priceTo = '';
      this.keyword = '';
      this.all_items = '';
      this.filterPriceList();
    },
    filterPriceList(page = this.page_num) {
      this.isLoading = true;

      const data = {
        tree_id: this.tree_id,
        brand: this.brand,
        category: this.category,
        tax_area: 'GEVAT',
        currency: 'GEL',
        item: 0,
        keyword: this.keyword,
        price_from: Number(this.priceFrom) || 0,
        price_to: Number(this.priceTo) || 9999999,
        all_items: this.all_items || 'N',
        show_promo_items: 'N',
        show_fg_items: 'N',
        show_refurbished_items: 'N',
        rec_date: '',
        page_num: this.page_num,
        total_lines: 0,
        total_pages: 0,
        lines_per_page: this.lines_per_page
      };

      CatalogService.getPriceList(data)
        .then(({ data }) => {
          this.page_num = page;
          this.priceList = data['Price List'] ? data['Price List'].items : [];
          this.brands = data['Brands'] ? data['Brands'].items : [];
          this.categories = data['Categories'] ? data['Categories'].items : [];
          this.total_pages = data.total_pages;
          this.total_lines = data.total_lines;
          this.all_items =  data.all_items;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onPaginate(page) {
      if (this.page_num === this.$route.query.page) return;

      this.$router.push({
        name: 'Catalog',
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style lang="scss">
.catalog {
  padding-top: rem(30);

  @include media-breakpoint-up(md) {
    padding-top: $goods-menu-height;
  }

  &__filter-column {
    position: fixed;
    top: 100%;
    left: 0;
    background: #fff;
    z-index: 50;
    overflow-y: auto;
    height: 100%;
    transition: transform 0.3s ease;
    width: 100%;

    .filter-button-container {
      position: sticky;
      overflow: hidden;
      bottom: 0;
      padding-bottom: 1em;
      width: 100%;
      background: linear-gradient(#ffffff00 0, #ffffff 30%);
    }

    @include media-breakpoint-up(md) {
      position: relative;
      top: 0;
      height: inherit;
      z-index: 1;
      top: 0;
      background: transparent;
      overflow: hidden;
    }

    &.open {
      transform: translateY(-100%);
    }
  }

  &__cols {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 3fr 7fr;
      column-gap: 25px;
    }

    @include media-breakpoint-up(lg) {
      column-gap: 75px;
    }
  }

  &__aside {
    padding: rem(30) 22px;
  }

  &__column-header {
    @include media-breakpoint-up(md) {
      height: rem(80);
    }
  }
}
</style>
