function setPageTitle(name) {
  document.title = `${name} - Alta B2B`;
}

function isDesktop() {
  const resolutionDetector = window.matchMedia('(min-width: 768px)');
  return resolutionDetector.matches;
}

export { setPageTitle, isDesktop };
