<template>
  <div class="header-cart-box">
    <div v-if="basketList.length">
      <div class="bpg-phone-sans font-size-14 color-dark-gray mb-4">
        კალათა
      </div>
      <div class="header-cart-box__cart-items overflow-auto custom-scroll">
        <HeaderBasketItem
          v-for="(item, index) in basketList"
          :key="index"
          :data="item"
        />
      </div>
      <div
        class="header-cart-box__amount font-size-12 color-text-gray bpg-phone-sans d-flex justify-content-end"
      >
        ჯამი
        <span
          v-if="basket"
          class="color-dark-gray ml-1 d-flex align-items-center"
        >
          {{ basket.total_price }} ({{ getCurrency }})
        </span>
      </div>
      <RouterLink
        :to="{ name: 'Basket' }"
        class="text-decoration-none"
        @click.native="closeDropdown"
      >
        <BaseButton class="w-100 d-inline-block">
          კალათაში გადასვლა
        </BaseButton>
      </RouterLink>
    </div>
    <div v-else>
      <p class="bpg-phone-sans font-size-14 text-truncate m-3">
        კალათა ცარიელია
      </p>
    </div>
  </div>
</template>

<script>
import BaseButton from '../common/BaseButton';
import HeaderBasketItem from './HeaderBaksetItem';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'HeaderBasket',
  components: { HeaderBasketItem, BaseButton },
  props: ['closeDropdown'],
  computed: {
    ...mapState('basket', ['basket']),
    ...mapGetters('auth', ['getCurrency']),
    basketList() {
      return this.basket?.items || [];
    }
  },
  created() {
    this.getBasket();
  },
  methods: { ...mapActions('basket', ['getBasket']) }
};
</script>

<style lang="scss">
.header-cart-box {
  max-width: rem(360);
  padding: rem(16) rem(20) rem(23) rem(15);

  &__cart-items {
    max-height: rem(300);
  }

  &__amount {
    padding: rem(20) 0;
  }
}
</style>
