import apiClient from './api-client';

export default {
  getBasket() {
    return apiClient.get(`/get-local-basket`);
  },
  updateBasket(data) {
    return apiClient.post('/update-local-basket', { basket_items: data });
  },
  checkoutBasket() {
    return apiClient.post('/checkout-basket');
  },
  clearBasket() {
    return apiClient.post('/clear-local-basket');
  }
};
