import axios from 'axios';
//import store from '../store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
  }
});

// const userString = localStorage.getItem('user');
// if (userString) {
//   const userData = JSON.parse(userString);
//   store.commit('auth/SET_USER_DATA', userData);
// }

// apiClient.interceptors.response.use(
//   (response) => {
//     //console.log(response);
//     if (response.error_id === 1005) {
//       store.dispatch('auth/logout');
//     }
//     return response;
//   },
//   (error) => {
//     store.dispatch('auth/logout');
//     if (error.response && error.response.data) {
//       //add your code
//       return Promise.reject(error.response.data);
//     }
//     return Promise.reject(error.message);
//   }
// );

export default apiClient;
