import apiClient from './api-client';

export default {
  loginUser(credentials) {
    return apiClient.post('/get-token-and-login', credentials);
  },
  getUser() {
    return apiClient.get('/login');
  },
  registerUser(credentials) {
    return apiClient.post('/registration-request', credentials);
  },
  changePassword(data) {
    return apiClient.post(`/change-password`, data);
  }
};
