<template>
<div class="bg-color-bg-gray d-flex pt-3">
<Loading v-if="PromosIsLoading" />
<div class="container mt-5">
<Breadcrumbs />
<SectionTitle>მიმდინარე აქციები</SectionTitle>
<div class="my-4">
         
<div class="container-fluid">    

<div v-for="(action, index) in Promos" :key="index"> 

<h2>{{action.promo_type_desc}}</h2>

<section class="row">
<div v-for="item in action.items" :key="item.parent_item" class="col-6 col-md-3">    
<ProductItem :data="item"/>
</div>
</section>   
    
</div>           


</div>
</div>
</div>

</div>
</template>

<script>
import ProductItem from '../components/common/ProductItem';
import CatalogService from '../services/CatalogService';
import SectionTitle from '@/components/common/SectionTitle';
import Breadcrumbs from '@/components/common/Breadcrumbs';
import Loading from '../components/common/Loading';
    
export default {
  name: 'Promos',
  components: {
    ProductItem, Breadcrumbs, SectionTitle, Loading
  },
  data() {
    return {
      Promos: [],
      PromosIsLoading: false
    };
  },
  methods: {
    init() {
      this.getPromos();
    },
    getPromos() {
      this.PromosIsLoading = true;
      CatalogService.getPromos()
        .then(({ data }) => {
          this.PromosIsLoading = false;
          this.Promos = data.actions;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.init();
  }
};
</script>