<template>
  <div>
    <h1 class="dashboard-page-title mb-4">
      პაროლის შეცვლა
    </h1>
    <ValidationObserver v-slot="{ handleSubmit }" ref="passwordChangeForm">
      <form @submit.prevent="handleSubmit(onSubmit)" class="w-100">
        <ValidationProvider
          name="password"
          v-slot="{ errors }"
          rules="required|min:8|max:10|password:@password"
          ref="password"
        >
          <BaseInput
            class="change-password__input-wrapper mb-0"
            title="ახალი პაროლი"
            placeholder="ახალი პაროლი"
            input-type="password"
            :is-success="password.length >= 8 && password.length <= 10"
            :error="errors[0]"
            v-model="password"
          />
        </ValidationProvider>
        <div
          class="change-password__input-wrapper mt-1 change-password__password-strength bg-color-border-gray border-radius-s position-relative"
        >
          <div
            :style="{
              width: (passwordStrength + 1) * 20 + '%'
            }"
            :class="strengthColors[passwordStrength]"
            class="change-password__password-strength-indicator position-absolute h-100 border-radius-s"
          ></div>
        </div>
        <ValidationProvider
          name="confirmPassword"
          v-slot="{ errors }"
          rules="required|password:@password"
          ref="confirmPassword"
        >
          <BaseInput
            class="change-password__input-wrapper mb-5"
            title="გაიმეორეთ ახალი პაროლი"
            placeholder="გაიმეორეთ ახალი პაროლი"
            :error="errors[0]"
            input-type="password"
            :is-success="passwordIsValid"
            v-model="passwordConfirmation"
          />
        </ValidationProvider>
        <div class="change-password__input-wrapper">
          <BaseButton
            class="ml-auto"
            :is-loading="isLoading"
            :disabled="!passwordIsValid && !isLoading"
            type="submit"
            >შეცვლა</BaseButton
          >
        </div>
      </form>
    </ValidationObserver>
    <transition name="fade">
      <StatusPopup
        v-if="statusPopupIsOpen"
        :status-is-success="statusType == 'success'"
        :visible="statusPopupIsOpen"
        @close="statusPopupIsOpen = false"
      >
        <template v-slot:header>
          {{ statusMessages[statusType].title }}
        </template>
        <template v-slot:body>
          {{ statusMessages[statusType].body }}
        </template>
        <template v-slot:buttons="slotProps">
          <BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
        </template>
      </StatusPopup>
    </transition>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';
import AuthService from '../../services/AuthService';
import StatusPopup from '../../components/common/StatusPopup';

export default {
  name: 'ChangePassword',
  components: { StatusPopup },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      passwordStrength: null,
      isLoading: false,
      statusPopupIsOpen: false,
      statusType: 'error',
      statusMessages: {
        success: {
          title: 'პაროლის ცვლილება',
          body: `პაროლის წარმატებით შეიცვალა`
        },
        error: {
          title: 'პაროლის ცვლილება',
          body: `პაროლის ცვლილება ვერ განხორციელდა, გთხოვთ სცადოთ მოგვიანებით`
        }
      },
      strengthColors: {
        0: 'bg-color-red',
        1: 'bg-color-orange',
        2: 'bg-color-yellow',
        3: 'bg-color-light-green',
        4: 'bg-color-green'
      }
    };
  },
  methods: {
    onSubmit() {
      const data = { new_password: this.password };
      this.isLoading = true;
      this.statusType = 'error';

      AuthService.changePassword(data)
        .then(({ data }) => {
          if (data.error_id == 0) {
            this.statusType = 'success';

            this.password = this.passwordConfirmation = '';
            this.$nextTick(() => {
              this.$refs.passwordChangeForm.reset();
            });
          }
          this.statusPopupIsOpen = true;
        })
        .catch((error) => {
          console.error(error.response);
          this.statusPopupIsOpen = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
      debugger;
    }
  },
  computed: {
    passwordIsValid() {
      return (
        this.password.length >= 8 &&
        this.password.length <= 10 &&
        this.password === this.passwordConfirmation
      );
    }
  },
  watch: {
    password(val) {
      this.passwordStrength = zxcvbn(val).score;
    }
  }
};
</script>

<style lang="scss">
.change-password {
  &__input-wrapper {
    width: $input-width;
    margin-bottom: rem(30);
  }

  &__password-strength {
    width: $input-width;
    height: rem(5);
  }
  &__password-strength-indicator {
    top: 0;
    left: 0;
    transition: all 0.3s ease;
  }
}
</style>
