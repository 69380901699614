import { extend } from 'vee-validate';
import { required, email, length, numeric } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'ველის შევსება სავალდებულოა'
});
extend('length', {
  ...length,
  message: 'ველის შევსება სავალდებულოა'
});
extend('email', {
  ...email,
  message: 'შეიყვანეთ ვალიდური ელ.ფოსტის მისამართი'
});
extend('min', {
  params: ['length'],
  message: 'შეიყვანეთ მინიმუმ {length} სიმბოლო',
  validate(value, { length }) {
    return value.length >= length;
  }
});
/*d.kalandadze start 21.03.2021*/
extend('max', {
  params: ['length'],
  message: 'შეიყვანეთ მაქსიმუმ {length} სიმბოლო',
  validate(value, { length }) {
    return value.length <= length;
  }
});
extend('numeric', {
  ...numeric,
  message: 'შეიყვანეთ მხოლოდ ციფრები'
});
/*d.kalandadze END 21.03.2021*/
extend('password', {
  params: ['target'],
  message: 'პაროლები არ ემთხვევა',
  validate(value, { target }) {
    return value === target;
  }
});

extend('georgian', {
  message: 'გთხოვთ შეიყვანოთ ქართული ფონტით',
  validate(value) {
    const letters = /^[ა-ჰ ]+$/;
    return value.match(letters);
  }
});

extend('checkbox', {
  message: 'ვალიდაცია სავალდებულოა',
  validate(value) {
    return value;
  }
});
