<template>
  <div v-if="basket" class="basket">
    <div class="container">
      <Breadcrumbs />
      <h2 class="dashboard-page-title mb-2 mb-md-4">კალათა</h2>
      <div class="w-100">
        <table class="basket__table w-100">
          <thead class="thead bpg-phone-sans font-size-12 color-text-gray">
            <tr>
              <th>
                პროდუქტის დასახელება
              </th>
              <th class="px-5">
                რაოდენობა
              </th>
              <th class="px-3">ფასი ({{ getCurrency }})</th>
              <th class="pr-5 pl-3">ჯამი ({{ getCurrency }})</th>
            </tr>
          </thead>
          <tbody class="tbody text-center">
            <tr v-for="item in basket.items" :key="item.item">
              <td>
                <div class="d-flex justify-content-center">
                  <BasketProductListItem
                    :data="item"
                    @remove="removeBasketItems([item.item])"
                  />
                </div>
              </td>
              <td class="px-md-5">
                <OrderQuantity
                  v-model="item.qty"
                  @input="onQuantityInput(item)"
                />
              </td>
              <td class="px-3">
                <span
                  class="bpg-phone-sans line-height-2  color-dark-purple font-size-18"
                  >{{ item.price_unit }}</span
                >
              </td>
              <td class="px-3 pr-md-5 pl-md-3">
                <span
                  class="bpg-phone-sans line-height-2 color-dark-purple font-size-18 "
                  >{{ item.price_total }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4 mb-3 mb-md-5 justify-content-end d-flex flex-wrap">
        <BasketCountText
          class="mr-md-3"
          text="სულ რაოდენობა"
          :count="basket.total_qty"
        />
        <BasketCountText
          class="ml-5"
          text="სულ თანხა"
          :count="`${basket.total_price} ${getCurrency}`"
        />
      </div>
      <div
        class="d-flex action-buttons flex-wrap justify-content-between align-items-center"
      >
        <BaseButton
          class="secondary justify-content-center mb-3 mb-md-0 flex-1 flex-md-grow-0"
          @click.native="handleClearBasketClick"
          >კალათის გასუფთავება</BaseButton
        >
        <BaseButton
          class="justify-content-center flex-1 flex-md-grow-0"
          @click.native="handleCheckout"
          :isLoading="isLoading"
          >შეკვეთის გაფორმება</BaseButton
        >
      </div>
    </div>
    <transition name="fade">
      <StatusPopup
        v-if="statusPopupIsOpen"
        :status-is-success="statusType == 'success'"
        :visible="statusPopupIsOpen"
        @close="closePopup"
      >
        <template v-slot:header>
          {{ statusMessages.title }}
        </template>
        <template v-slot:body>
          {{ statusMessages.body }}
        </template>
        <template v-slot:buttons="slotProps">
          <BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
        </template>
      </StatusPopup>
    </transition>
  </div>
</template>

<script>
import Breadcrumbs from '../components/common/Breadcrumbs';
import BasketProductListItem from '../components/basket/BasketProductListItem';
import BasketCountText from '../components/basket/BasketCountText';
import OrderQuantity from '../components/common/OrderQuantity';
import { mapActions, mapState, mapGetters } from 'vuex';
import StatusPopup from '../components/common/StatusPopup';

export default {
  name: 'Basket',
  components: {
    OrderQuantity,
    BasketCountText,
    BasketProductListItem,
    Breadcrumbs,
    StatusPopup
  },
  data() {
    return {
      date: '',
      page_num: 1,
      total_items: 500,
      lines_per_page: 25,
      activeItem: -1,
      localBasket: [],
      selectedAll: false,
      timeout: null,
      statusPopupIsOpen: false,
      statusType: 'error',
      statusMessages: {
        title: 'შეკვეთა',
        body: ''
      }
    };
  },
  computed: {
    ...mapState('basket', ['basket', 'isLoading', 'order']),
    ...mapGetters('auth', ['getCurrency'])
  },
  methods: {
    ...mapActions('basket', [
      'updateBasket',
      'removeBasketItems',
      'clearBasket',
      'checkoutBasket',
      'getBasket'
    ]),
    handleClearBasketClick() {
      this.clearBasket();
    },
    handleCheckout() {
      this.checkoutBasket()
        .then((order) => {
          this.statusMessages.body = order.error_desc;
          this.statusType = 'error';

          if (order.error_id == 0) {
            this.statusType = 'success';
            return;
          }
        })
        .catch((error) => {
          this.statusMessages.body = error.error_desc;
          this.statusType = 'error';
          console.error(error.response);
        })
        .finally(() => {
          this.statusPopupIsOpen = true;
        });
    },
    onQuantityInput(item) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        console.log(parseInt(item.qty));
        if (parseInt(item.qty) > 0) {
          this.updateBasket([item]);
        }
      }, 700);
    },
    closePopup() {
      this.statusPopupIsOpen = false;

      if (this.statusType !== 'success') {
        window.location.reload();
      }

      this.redirectToOpenOrder();
    },
    redirectToOpenOrder() {
      this.getBasket();

      if (this.order.order_count == 1) {
        this.$router.push({
          name: 'OpenOrderNested',
          params: { id: this.order.order_num, type: this.order.order_type }
        });
        return;
      }

      this.$router.push({ name: 'OpenOrders' });
    }
  }
};
</script>

<style lang="scss">
.basket {
  padding-bottom: rem(120);
  padding-top: rem(30);

  @include media-breakpoint-up(md) {
    padding-top: $goods-menu-height;
  }

  &__table {
    table-layout: fixed;
    width: 100%;

    @include media-breakpoint-up(md) {
      table-layout: auto;
    }
  }

  .thead {
    background: $bg-gray;
    border-radius: 5px;
    text-align: center;
    display: none;

    @include media-breakpoint-up(md) {
      display: table-header-group;
    }

    th {
      height: rem(60);
      font-weight: normal;

      &:first-child {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .tbody {
    tr {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @include media-breakpoint-up(md) {
        display: table-row;
      }
    }
    td {
      padding: rem(20) 0;
      border-bottom: 1px solid $border-gray;
      border-width: 3px;

      &:first-child {
        grid-column: 1 / span 3;
        // border-right: 1px solid $border-gray;
        border-width: 1px;
      }

      @include media-breakpoint-up(md) {
        padding: rem(30) 0;
        border-width: 1px;

        &:first-child {
          border-right: 1px solid $border-gray;
        }
      }
    }
  }

  .action-buttons {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .base-button {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: initial;
      }
    }
  }

  &__checkbox-container {
    width: rem(16);
    height: rem(16);
  }
}
</style>
