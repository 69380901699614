var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"dashboard-page-title mb-4"},[_vm._v(" პაროლის შეცვლა ")]),_c('ValidationObserver',{ref:"passwordChangeForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{ref:"password",attrs:{"name":"password","rules":"required|min:8|max:10|password:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"change-password__input-wrapper mb-0",attrs:{"title":"ახალი პაროლი","placeholder":"ახალი პაროლი","input-type":"password","is-success":_vm.password.length >= 8 && _vm.password.length <= 10,"error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('div',{staticClass:"change-password__input-wrapper mt-1 change-password__password-strength bg-color-border-gray border-radius-s position-relative"},[_c('div',{staticClass:"change-password__password-strength-indicator position-absolute h-100 border-radius-s",class:_vm.strengthColors[_vm.passwordStrength],style:({
            width: (_vm.passwordStrength + 1) * 20 + '%'
          })})]),_c('ValidationProvider',{ref:"confirmPassword",attrs:{"name":"confirmPassword","rules":"required|password:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('BaseInput',{staticClass:"change-password__input-wrapper mb-5",attrs:{"title":"გაიმეორეთ ახალი პაროლი","placeholder":"გაიმეორეთ ახალი პაროლი","error":errors[0],"input-type":"password","is-success":_vm.passwordIsValid},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}],null,true)}),_c('div',{staticClass:"change-password__input-wrapper"},[_c('BaseButton',{staticClass:"ml-auto",attrs:{"is-loading":_vm.isLoading,"disabled":!_vm.passwordIsValid && !_vm.isLoading,"type":"submit"}},[_vm._v("შეცვლა")])],1)],1)]}}])}),_c('transition',{attrs:{"name":"fade"}},[(_vm.statusPopupIsOpen)?_c('StatusPopup',{attrs:{"status-is-success":_vm.statusType == 'success',"visible":_vm.statusPopupIsOpen},on:{"close":function($event){_vm.statusPopupIsOpen = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.statusMessages[_vm.statusType].title)+" ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" "+_vm._s(_vm.statusMessages[_vm.statusType].body)+" ")]},proxy:true},{key:"buttons",fn:function(slotProps){return [_c('BaseButton',{nativeOn:{"click":function($event){return slotProps.closeProp.apply(null, arguments)}}},[_vm._v("გასაგებია")])]}}],null,false,2617528095)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }