<template>
  <div class="go-back d-flex align-items-center color-dark-gray">
    <i class="go-back__icon icon-arrow-down font-size-7 mr-2"></i>
    <div class="font-size-12 bpg-phone-sans">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoBack'
};
</script>

<style lang="scss">
.go-back {
  &__icon {
    transform: rotate(90deg);
  }
}
</style>
