<template>
  <div class="register-user d-flex align-items-center justify-content-center">
    <ValidationObserver v-slot="{ handleSubmit }" class="w-100">
      <form @submit.prevent="handleSubmit(onSubmit)" class="w-100">
<div class="d-flex flex-column">
<AuthInputContainer
v-model="firstName"
icon-name="person"
font-size="13"
title="სახელი *"
name="name"
ref-string="name"
rules="required"
class="mb-3"
/>
<AuthInputContainer
v-model="secondName"
icon-name="person"
font-size="13"
title="გვარი *"
name="secondName"
ref-string="secondName"
rules="required"
class="mb-3"
/>
<AuthInputContainer
v-model="email"
icon-name="email"
font-size="13"
title="ელ.ფოსტა *"
name="email"
ref-string="email"
rules="required|email"
class="mb-3"
/>

<AuthInputContainer
v-model="phone"
icon-name="phone1"
font-size="13"
title="ტელეფონი *"
name="phone"
ref-string="phone"
rules="required|min:9|numeric"
class="mb-3"
/>

<AuthInputContainer
v-model="company_name"
icon-name="business"
font-size="16"
title="კომპანიის სახელი *"
name="company_name"
ref-string="company_name"
rules="required"
class="mb-3"
/>

<AuthInputContainer
v-model="position"
icon-name="assignment"
font-size="16"
title="თანამდებობა *"
name="position"
ref-string="position"
rules="required"
class="mb-4"
/>

<AuthInputContainer
v-model="cadastralCode"
icon-name="library"
font-size="16"
title="საგადასახადო კოდი *"
name="cadastralCode"
ref-string="cadastralCode"
rules="required|min:9|max:11|numeric"
class="mb-3"
/>


<div class="d-flex mb-4 justify-content-end">
<vue-recaptcha
:sitekey="siteKey"
ref="recaptcha"
@verify="verifyMethod"
@expired="onCaptchaExpired"
></vue-recaptcha>
</div>
    
<div class="d-flex align-items-center justify-content-between color-text-gray">
<router-link :to="{ name: 'login' }" class="font-size-12 bpg-phone-sans">
ავტორიზაცია
</router-link>
<BaseButton :is-loading="isLoading" :disabled="!token" type="submit">რეგისტრაცია</BaseButton>
</div>
</div>
</form>
</ValidationObserver>
      
<transition name="fade">
<StatusPopup
v-if="statusPopupIsOpen"
:status-is-success="statusIsSuccess"
:visible="statusPopupIsOpen"
@close="statusPopupIsOpen = false">
<template v-slot:header>
{{ status.title }}
</template>
<template v-slot:body>
{{ status.body }}
<br/>
{{ status.body_loading }}
</template>
<template v-slot:buttons="slotProps">
<BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
</template>
</StatusPopup>
</transition>

</div>
</template>

<script>
import BaseButton from '../../components/common/BaseButton';
import authMixin from '../../mixins/auth-mixin';
import AuthInputContainer from '../../components/form-components/AuthInputContainer';
import AuthService from '../../services/AuthService';
    
    
import BaseCheckbox from '../../components/form-components/BaseCheckbox';
import StatusPopup from '../../components/common/StatusPopup';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
name: 'RegisterUser',
mixins: [authMixin],
components: { StatusPopup, BaseCheckbox, BaseButton, AuthInputContainer, VueRecaptcha },
data() {
return {
siteKey: '',
isLoading: false,
token: '',
firstName: '',
secondName: '',
email: '',
phone: '',
company_name: '',
position: '',
cadastralCode: '',
statusPopupIsOpen: false,
status: {
title: '',
body: '',
body_loading: ''
},
statusIsSuccess: false
};
},
    
created() {
this.siteKey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
},
methods: {    
verifyMethod(response) {
this.token = response;
},
onCaptchaExpired: function() {
this.$refs.recaptcha.reset();
},
    
onSubmit() {
const credentials = { 
firstName: this.firstName,
secondName: this.secondName,
email: this.email,
phone: this.phone,
company_name: this.company_name,
position: this.position,
cadastralCode: this.cadastralCode
};
    
AuthService.registerUser(credentials)
.then((response) => {
if (response.data === 'success') {
this.status.title = "რეგისტრაციის მოთხოვნა მიღებულია";
this.status.body = "მომხმარებლის პარამეტრებს მიიღებთ ელ. ფოსტით.";
this.status.body_loading = "მიმდინარეობს გადამისამართება...";
this.statusPopupIsOpen = true;
this.statusIsSuccess = true;
setTimeout( () => this.$router.push({ path: '/login'}), 5000);
} 
else {
this.$refs.recaptcha.reset();
this.status.title = "რეგისტრაცია ვერ განხორციელდა";
this.status.body = "რეგისტრაცია ვერ განხორციელდა, გთხოვთ შეამოწმოთ მონაცემები";
this.status.body_loading = " ";
this.statusPopupIsOpen = true;
this.statusIsSuccess = false;
}
})

.finally(() => {
this.isLoading = false;
});


                                     
}
}
};
</script>

<style lang="scss">
.register-user {
  &__hr {
    border-top: 1px solid $border-gray;
    width: calc(100% - #{rem(86)});
    margin-right: rem(15);
  }
}
</style>





