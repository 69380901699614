import apiClient from './api-client';

export default {
  getCustomerLedger(data) {
    return apiClient.get(`/customer-ledger`, { params: data });
  },
 getCustomerLedger_excel(data_excel) {
    return apiClient.get(`/customer-ledger`, { params: data_excel });
  },
  getCustomerCard() {
    return apiClient.get(`/customer-card`);
  },
  getCustomerPenalty_excel(data_excel) {
    return apiClient.get(`/customer-penalty`, { params: data_excel });
  },
  getCustomerPenalty(data) {
    return apiClient.get(`/customer-penalty`, { params: data });
  },
  getCustomerSalesOrders(data) {
    return apiClient.get(`/customer-sales-orders`, { params: data });
  },
  getCustomerSalesOrders_excel(data_excel) {
    return apiClient.get(`/customer-sales-orders`, { params: data_excel });
  },
  getCustomerSalesOrder(data) {
    return apiClient.get(`/customer-sales-order`, { params: data });
  },
  getCustomerOpenOrders(data) {
    return apiClient.get(`/customer-open-orders`, { params: data });
  },
  getCustomerOpenOrders_excel(data_excel) {
    return apiClient.get(`/customer-open-orders`, { params: data_excel });
  },
  getCustomerOpenOrder(data) {
    return apiClient.get(`/customer-open-order`, { params: data });
  },
  getTransportationCarriers() {
    return apiClient.get(`/transportation-carriers`);
  },
  getCustomerBranches() {
    return apiClient.get(`/customer-branches`);
  },
  getCustomerVehicles() {
    return apiClient.get(`/customer-vehicles`);
  },
  setOrderDeliveryInfo(data) {
    return apiClient.post(`/set-order-delivery-info`, data);
  },
  cancelOrder(data) {
    return apiClient.post(`/cancel-order`, data);
  },
  sendInvoiceByMail(data) {
    return apiClient.post(`/send-invoice-by-mail`, data);
  },
  getCustomerUsers() {
    return apiClient.get(`/customer-users`);
  },
  createCustomerUser(data) {
    return apiClient.post(`/registration-request`, data);
  },
  changeUserStatus(data) {
    return apiClient.post(`/change-user-status`, data);
  },
  createShipment(data) {
    return apiClient.post('/create-shipment', data);
  }
};
