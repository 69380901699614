<template>
<div class="bg-color-bg-gray d-flex pt-3">
<div class="container mt-5">
    <div class="error d-flex flex-column">
      <h1 class="font-size-24 mb-4">გვერდი ვერ მოიძებნა</h1>
      <RouterLink :to="{ name: 'Home' }" class="text-decoration-none align-self-center" >
        <BaseButton>
          მთავარი გვერდი
        </BaseButton>
      </RouterLink>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Error'
};
</script>

<style lang="scss">
.error {
text-align: center;
margin: 20px 0px; 
}
</style>
