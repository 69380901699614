import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['loggedIn'])
  },
  watch: {
    loggedIn(to) {
      if (to) {
        this.$router.push('/');
      }
    }
  }
};
