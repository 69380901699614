<template>
  <transition name="page-fade" mode="out-in">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'PageTransition'
};
</script>

<style>
.page-fade-enter-active {
  transition: opacity 0.3s ease;
}

.page-fade-enter,
.page-fade-leave-to {
  opacity: 0;
}
</style>
