<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ errors }"
    :name="name"
    :ref="refName"
    class="mb-3"
  >
    <div class="input-with-info-tooltip d-flex">
      <BaseInput
        class="my-profile__input-wrapper"
        :title="title"
        :placeholder="placeholder"
        v-model="innerValue"
        :error="errors[0]"
        :is-success="defaultValue !== innerValue && !errors[0]"
      />
      <div
        class="input-with-info-tooltip__icon-container position-relative tooltip-parent text-nowrap"
      >
        <Tooltip>ჩაასწორეთ და მოითხოვეთ შეცვლა</Tooltip>
        <i class="icon-error_outline font-size-17 ml-4 color-text-gray"></i>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import inputMixin from '@/mixins/input-mixin';
import Tooltip from '../common/Tooltip';

export default {
  name: 'InputWithInfoTooltip',
  components: { Tooltip },
  mixins: [inputMixin],
  props: {
    tooltipText: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: 'required|georgian'
    },
    name: {
      type: String,
      required: true
    },
    defaultValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    refName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    innerValue(val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style lang="scss">
.input-with-info-tooltip {
  &__icon-container {
    margin-top: rem(42);

    &:hover {
      i {
        color: $light-purple;
      }
    }
  }
}
</style>
