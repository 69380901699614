import CommonService from '../../services/CommonService';

export const namespaced = true;

export const state = {
  goodsMenu: [],
  active: null,
  isLoading: false
};

export const actions = {
  fetchGoodsMenu({ commit }) {
    commit('SET_LOADING', true);
    CommonService.getGoods()
      .then((response) => {
        commit('SET_GOODS_MENU', response.data.items);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  }
};

export const mutations = {
  SET_GOODS_MENU(state, value) {
    state.goodsMenu = value;
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  }
};

const buildBreadcrumbForCategory = function(menu, activeCagegory) {
  if (menu.ID === activeCagegory) {
    return [];
  } else if (menu.items) {
    for (var i = 0; i < menu.items.length; i++) {
      var path = buildBreadcrumbForCategory(menu.items[i], activeCagegory);
      if (path !== null) {
        path.unshift(menu.items[i]);
        return path;
      }
    }
  }
  return null;
};

export const getters = {
  getBreadNavigationItems: (state) => (id) => {
    const menu = {
      items: state.goodsMenu
    };
    return buildBreadcrumbForCategory(menu, id);
  }
};
