<template>
  <div class="bg-color-bg-gray w-100">
    <div class="auth__content m-auto">
      <a href="/" class="d-flex justify-content-center">
        <img
          alt="Alta logo"
          src="@/assets/images/logo.png"
          class="logo mt-4 mb-4"
        />
      </a>
      <div class="auth__form-container border-radius-s m-auto bg-color-white m-auto">
        <PageTransition>
          <router-view />
        </PageTransition>
      </div>
    </div>
  </div>
</template>

<script>
import PageTransition from '../../components/common/PageTransition';

export default {
  name: 'Auth',
  components: { PageTransition },
  mounted() {
    document.body.classList.add('auth');
  },
  destroyed() {
    document.body.classList.remove('auth');
  }
};
</script>

<style lang="scss">
.logo {
  height: rem(90);
}

.auth {
  padding: rem(36) 0;

  &__content {
    width: 100%;
    max-width: 600px;
  }

  &__form-container {
    padding: rem(56) rem(40);

    @include media-breakpoint-up(md) {
      padding: rem(56) rem(120);
    }
  }

  &__tabs {
    height: rem(64);
    margin-bottom: rem(33);
  }
}
</style>
