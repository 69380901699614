<template>
  <footer v-if="data && Object.keys(data).length > 0" class="footer">
    <section class="footer__top-section bg-color-bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-md-6 pr-md-4">
            <div class="footer__section-title mb-4">
              {{ data.help.title }}
            </div>
            <div class="bpg-phone-sans font-size-12 color-text-gray mb-3">
              {{ data.help.text }}
            </div>
            <ul
              class="dotted-list font-size-12 color-dark-gray bpg-phone-sans mb-4"
            >
              <li>
                <a
                  :href="data.help.bullets[0].relative_url"
                  :target="data.help.bullets[0].target"
                  class="d-flex align-items-center"
                >
                  <span class="dotted-list__text">
                    {{ data.help.bullets[0].text }}
                  </span>
                  <i
                    class="icon-open_in_new font-size-15 pl-3 text-decoration-none"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  :href="data.help.bullets[1].relative_url"
                  :target="data.help.bullets[1].target"
                  class="d-flex align-items-center"
                >
                  <span class="dotted-list__text">
                    {{ data.help.bullets[1].text }}
                  </span>
                  <i
                    class="icon-attachment font-size-10 pl-3 text-decoration-none"
                  ></i>
                </a>
              </li>
            </ul>
            <div class="footer__section-title mb-2">
              {{ data.navigation.title }}
            </div>
            <nav class="d-flex flex-column flex-wrap color-text-gray">
              <a
                v-for="(item, index) in data.navigation.content"
                :key="index"
                :href="item.relative_url"
                class="font-size-12 bpg-phone-sans mb-2"
                >{{ item.text }}</a
              >
            </nav>
          </div>
          <div class="col-md-3">
            <div class="footer__section-title mb-4">
              {{ data.contact.title }}
            </div>
            <div class="color-text-gray font-size-12 bpg-phone-sans mb-4">
              <div class="d-flex align-items-center flex-wrap">
                <a
                  :href="`tel: ${data.contact.phone}`"
                  class="footer__contact-link d-flex align-items-center text-decoration-none"
                >
                  <i
                    class="icon-phone1 font-size-17 mr-2 text-decoration-none"
                  ></i>
                  <span class="footer__contact-link-text">
                    {{ data.contact.phone }}
                  </span>
                </a>
                <a
                  :href="`mailto: ${data.contact.email}`"
                  class="footer__contact-link d-flex align-items-center text-decoration-none w-100 mt-3"
                >
                  <i
                    class="icon-email font-size-17 mr-2 text-decoration-none"
                  ></i>
                  <span class="footer__contact-link-text">
                    {{ data.contact.email }}
                  </span>
                </a>
                <div
                  v-if="data.contact.address"
                  class="d-flex align-items-center w-100 mt-3"
                >
                  <i
                    class="icon-location font-size-17 mr-2 text-decoration-none"
                  ></i>
                  <a target="_blank" :href="mapUrl">
                    {{ data.contact.address.text }}</a
                  >
                </div>
              </div>
            </div>
            <div class="footer__section-title mb-2">
              {{ data.information.title }}
            </div>
            <nav class="d-flex flex-column flex-wrap color-text-gray">
              <a
                v-for="(item, index) in data.information.content"
                :key="index"
                :href="item.relative_url"
                class="font-size-12 bpg-phone-sans mb-2"
                >{{ item.text }}</a
              >
            </nav>
          </div>
          <div class="col-md-3">
            <div class="footer__section-title mb-2">
              {{ data.description.title }}
            </div>
            <div class="bpg-phone-sans font-size-12 color-text-gray mb-3">
              {{ data.description.text }}
            </div>
            <a
              :href="data.description.link.relative_url"
              class="d-flex align-items-center bpg-phone-sans font-size-12 color-text-gray"
            >
              <i
                class="icon-open_in_new font-size-15 pr-1 text-decoration-none"
              ></i>
              <span class="dotted-list__text">
                {{ data.description.link.text }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="footer__bottom-section d-flex align-items-center">
      <div
        class="container bpg-phone-sans font-size-12 color-text-gray bg-color-white "
      >
        Copyright © {{ new Date().getFullYear() }} b2b.alta.com.ge. All rights
        reserved
      </div>
    </section>
  </footer>
</template>

<script>
import CommonService from '@/services/CommonService';

export default {
  name: 'TheFooter',
  data() {
    return {
      data: []
    };
  },
  computed: {
    mapUrl() {
      if (!this.data.contact.address) return;
      return `https://www.google.com/maps/place/8+Akaki+Beliashvili+St,+T'bilisi+0159/@${this.data.contact.address.lat}.${this.data.contact.address.long}z/data=!3m1!4b1!4m5!3m4!1s0x40446d91f74dad19:0x6509539b48d2f416!8m2!3d41.771632!4d44.7792359`;
    }
  },
  created() {
    CommonService.getFooterContent().then(({ data }) => {
      this.data = data.data;
    });
  }
};
</script>

<style lang="scss">
.footer {
  margin-top: rem(24);

  &__top-section {
    padding-top: rem(54);
    padding-bottom: rem(64);
  }

  &__section-title {
    font-size: rem(14);
    font-family: 'BPG Nino Mtavruli', sans-serif;
    position: relative;
    color: $dark-gray;
    padding-bottom: rem(9.5);
    font-weight: bold;
    letter-spacing: 1px;

    &::after {
      width: rem(17);
      height: 3px;
      background: $dark-gray;
      @include abs-position(auto, auto, 0, 0);
      content: '';
    }
  }

  &__contact-link {
    &:hover {
      .footer__contact-link-text {
        text-decoration: underline;
      }
    }
  }

  &__bottom-section {
    height: rem(66);
  }
}
</style>
