<template>
  <div class="order-nested d-flex bg-color-bg-gray">
    <div class="container-fluid">
      <div class="container">
        <Breadcrumbs />
      </div>
      <div class="container">
        <div class="order-nested__content bg-color-white border-radius-s w-100">
          <RouterLink :to="{ name: 'Orders' }" class="text-decoration-none">
            <GoBack>
              უკან შეკვეთებში
            </GoBack>
          </RouterLink>
          <div
            v-if="order && order.order_item && order.order_item.items"
            class="overflow-auto"
          >
            <h2 class="dashboard-page-title mt-5">
              შეკვეთა: {{ order.order_id }}
            </h2>
            <table class="table order-nested__products-table w-100 mt-1">
              <tr class="">
                <th>კოდი</th>
                <th>დასახელება</th>
                <th class="qty">რ-ბა</th>
                <th>ფასი</th>
                <th>ჯამური ფასი</th>
                <th>ვალუტა</th>
                <th>სტატუსი</th>
              </tr>
              <tr v-for="item in order.order_item.items" :key="item.item">
                <td>{{ item.item }}</td>
                <td class="text-left">{{ item.item_name }}</td>
                <td>{{ item.qty }}</td>
                <td class="text-right">{{ item.price_unit }}</td>
                <td class="text-right">{{ item.price_total }}</td>
                <td>{{ item.currency }}</td>
                <td>{{ item.line_status }}</td>
              </tr>
            </table>

            <div class="font-size-15 border-top pt-2">
              <p class="mb-2">
                ჯამური თანხა:
                <span class="font-weight-bold"
                  >{{ order.total_price }} ({{ getCurrency }})</span
                >
              </p>
              <p class="mb-2">
                საქონლის რ-ბა:
                <span class="font-weight-bold">{{ order.total_qty }}</span>
              </p>
              <p>
                <span class="font-weight-bold">{{ order.item_count }}</span>
                დასახელების საქონელი
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/common/Breadcrumbs';
import GoBack from '../../components/common/GoBack';
import CustomerService from '../../services/CustomerService';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderNested',
  components: {
    GoBack,
    Breadcrumbs
  },
  computed: {
    ...mapGetters('auth', ['getCurrency'])
  },
  data() {
    return {
      order: null,
      OrderPickupPopupIsOpen: false
    };
  },
  created() {
    const data = {
      order_id: parseInt(this.$route.params.id),
      order_type: this.$route.params.type
    };
    CustomerService.getCustomerSalesOrder(data)
      .then(({ data }) => {
        this.order = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>

<style lang="scss">
.order-nested {
  padding-top: $goods-menu-height-mobile;
  padding-bottom: rem(80);

  @include media-breakpoint-up(md) {
    padding-top: $goods-menu-height;
  }

  &__content {
    padding: rem(28) rem(38);
  }

  &__products-table {
    border-spacing: 0px !important;
    border-collapse: collapse !important;

    .qty {
      min-width: rem(60);
    }
  }
}
</style>
