import NewsService from '../../services/NewsService';

export const namespaced = true;

export const state = {
  newsData: [],
  newsNestedData: null,
  totalPages: 0,
  page: 1,
  perPage: 12,
  isLoading: false
};

export const actions = {
  fetchNews({ commit, state }) {
    commit('SET_LOADING', true);
    NewsService.getNews(state.perPage, state.page)
      .then(({ data }) => {
        const totalPages = data.total_pages;
        if (totalPages !== state.totalPages) {
          commit('SET_TOTAL_PAGES', totalPages);
        }
        commit('SET_PAGE', state.page + 1);
        commit('SET_NEWS_DATA', data.news_items);
        commit('SET_LOADING', false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
  fetchNewsNested({ commit, getters }, id) {
    const nestedNews = getters.getNewsById(id);
    if (nestedNews) {
      commit('SET_NEWS_NESTED_DATA', nestedNews);
    } else {
      commit('SET_LOADING', true);
      NewsService.getNewsById(id)
        .then((response) => {
          commit('SET_NEWS_NESTED_DATA', response.data);
          commit('SET_LOADING', false);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }
};

export const mutations = {
  SET_NEWS_DATA(state, value) {
    state.newsData = state.newsData.concat(value);
  },
  SET_NEWS_NESTED_DATA(state, value) {
    state.newsNestedData = value;
  },
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = totalPages;
  },
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  }
};

export const getters = {
  allPagesLoaded({ totalPages, page }) {
    console.log(totalPages, page);
    return totalPages <= page - 1;
  },
  getNewsById: (state) => (id) => {
    return state.newsData.find((news) => news.id === id);
  },
  newsDataLength({ newsData }) {
    return newsData.length;
  }
};
