<template>

<div class="bg-color-bg-gray d-flex pt-3">
<Loading v-if="recentProductsIsLoading" />
<div class="container mt-5">
<Breadcrumbs />
<SectionTitle>ახალი პროდუქტები</SectionTitle>
<div class="my-4">
         
<div class="container-fluid">    
<section class="row"> 
<div v-for="(item, index) in recentProducts" :key="index" class="col-6 col-md-4"> 
<ProductItem :data="item"/>
</section>      
</div>           
          
</div>
</div>
</div>

</template>

<script>
import ProductItem from '../components/common/ProductItem';
import CatalogService from '../services/CatalogService';
import SectionTitle from '@/components/common/SectionTitle';
import Breadcrumbs from '@/components/common/Breadcrumbs';
import Loading from '../components/common/Loading';
    
export default {
  name: 'NewProducts',
  components: {
    ProductItem, Breadcrumbs, SectionTitle, Loading
  },
  data() {
    return {
      recentProducts: [],
      recentProductsIsLoading: false
    };
  },
  methods: {
    init() {
      this.getRecentProducts();
    },
    getRecentProducts() {
      this.recentProductsIsLoading = true;
      CatalogService.getRecentProducts()
        .then(({ data }) => {
          this.recentProductsIsLoading = false;
          this.recentProducts = data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.init();
  }
};
</script>