<template>
<div class="bg-color-bg-gray d-flex pt-3">
<div class="container mt-5">

<div class="container-fluid">    
<section class="align-items-center">
    
  <div class="d-flex  justify-content-center py-5 text-center">
    <div class="d-flex flex-column">
      <h1 class="font-size-24 mb-4">მიმდინარეობს კატალოგის გენერაცია</h1>
        <i class="loading__icon icon-refresh font-size-30"></i>
    </div>
  </div>
    
</section>      
</div>
    
</div>
</div>
</template> 
<script>
import CatalogService from '../services/CatalogService';
import {VueJsExcel} from 'vue-js-excel'
    
export default {
  name: 'GetCatalog',
  mixins: [VueJsExcel],
  components: {
    
  },
  data() {
    return {  
    columns: ['item', 'item_full_name', 'qty_text', 'price_unit', 'brand', 'category'],
    priceList: []
    }    
  },
methods: {
    init() {
      this.getcatalog();
    },
    
    getcatalog() {
    const data = {};
      CatalogService.GetCatalog(data)
        .then(({ data }) => {
           this.priceList = data;
           this.VueJsExcelExport(this.priceList, "alta_catalog", this.columns);
           setTimeout( () => this.$router.back(), 1000);
        })
        .catch((error) => {
          console.log(error);
        });         
    }
  },
  created() {
    this.init();
  }
};
</script>

