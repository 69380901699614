<template>
  <div class="overflow-auto">
    <div
      class="order-reservation-section bg-color-bg-gray border-radius-s d-flex justify-content-between align-items-center"
    >
      <div
        class="color-text-gray font-size-12 bpg-phone-sans d-flex align-items-center"
      >
        <i class="icon-location font-size-30 mr-2 "></i>
        <div class="d-flex flex-wrap">
          <p class="mb-0 w-100">{{ store.store_name }}</p>
          <small class="">{{ store.store_address }}</small>
        </div>
        <div
          class="d-flex flex-wrap"
          v-for="shipment in store.shipments"
          :key="shipment.shpn"
        >
          <div v-if="shipment.shpn">
            <p class="mb-0 w-100">ტვირთი {{ shipment.id }}</p>
            <p class="mb-0 w-100">თარიღი: {{ shipment.date }}</p>
            <p class="mb-0 w-100">
              სტატუსი: {{ shipment.packing_status }} -
              {{ shipment.packing_status_desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <table
      class="order-nested-table w-100"
      v-for="shipment in store.shipments"
      :key="shipment.id"
    >
      <thead>
        <tr
          class="order-nested-table__row bpg-phone-sans font-size-12 color-text-gray"
        >
          <th><div class="order-nested-table__content">კოდი</div></th>
          <th>
            <div class="order-nested-table__content ">
              დასახელება
            </div>
          </th>
          <th>
            <div class="order-nested-table__content text-nowrap text-center">
              რ-ბა
            </div>
          </th>
          <th><div class="order-nested-table__content">ფასი</div></th>
          <th><div class="order-nested-table__content">ჯამი</div></th>
          <th><div class="order-nested-table__content">ვალუტა</div></th>
          <th><div class="order-nested-table__content">სტატუსი</div></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="order-nested-table__row"
          v-for="order in shipment.items"
          :key="order.item"
        >
          <td>
            <div
              class="order-nested-table__content font-size-14 color-dark-gray bpg-phone-sans"
            >
              {{ order.item }}
            </div>
          </td>
          <td>
            <div
              class="order-nested-table__content font-size-14 color-dark-gray bpg-phone-sans"
            >
              {{ order.item_name }}
            </div>
          </td>
          <td>
            <div
              class="order-nested-table__content font-size-12 color-text-gray bpg-phone-sans text-center"
            >
              {{ order.qty }}
            </div>
          </td>
          <td>
            <div
              class="order-nested-table__content font-size-14 color-text-gray bpg-phone-sans"
            >
              {{ order.price_unit }}
            </div>
          </td>
          <td>
            <div
              class="order-nested-table__content font-size-14 color-text-gray bpg-phone-sans"
            >
              {{ order.price_total }}
            </div>
          </td>
          <td>
            <div
              class="order-nested-table__content font-size-14 color-text-gray bpg-phone-sans"
            >
              {{ order.currency }}
            </div>
          </td>
          <td>
            <div
              class="order-nested-table__content font-size-14 color-dark-gray bpg-phone-sans bold"
            >
              {{ order.line_status }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OrderNestedTable',
  props: {
    store: {
      type: Object,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
.order-nested-table {
  thead {
    display: none;

    @include media-breakpoint-up(md) {
      display: table-header-group;
    }
  }

  &__row {
    border-bottom: 1px solid $border-gray;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    td:nth-child(2) {
      grid-column: 2 / span 5;
    }

    @include media-breakpoint-up(md) {
      display: table-row;
    }
  }

  &__content {
    padding: rem(17) 0;
    &:first-child {
      // max-width: rem(600);
    }
  }

  th {
    min-width: rem(80);
    font-weight: normal;
  }
}
.order-reservation-section {
  height: rem(80);
  padding-left: rem(20);
  padding-right: rem(20);
}
</style>
