<template>
  <RouterLink
    :to="{ name: 'NewsNested', params: { id: data.id } }"
    class="news-grid-item bg-color-white position-relative text-decoration-none mr-3"
  >
    <div class="d-flex flex-column w-100 h-100">
      <div class="bpg-phone-sans font-size-12 color-dark-blue mb-3">
        {{ data.date }}
      </div>
      <h4
        class="news_title font-size-12 color-dark-gray mb-3 font-weight-normal"
      >
        {{ data.title }}
      </h4>
      <div>
        <img class="news-grid-item__img" :src="data.image_link" alt="" />
      </div>
      <div
        class="news-grid-item__read-more cursor-pointer bpg-nino-mtarvruli font-size-16 dark-gray ml-auto mt-auto  align-self-end text-decoration-none"
      >
        სრულად
      </div>
    </div>
  </RouterLink>
</template>

<script>
export default {
  name: 'NewsGridItem',
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style lang="scss">
.news_title {
  font-family: BPG Phone Sans, sans-serif;
  min-height: 50px;
}
.news-grid-item {
  padding: rem(37) rem(20) rem(32);
  min-height: rem(262);
  border-right: 2px solid $bg-gray;
  border-bottom: 2px solid $bg-gray;

  &:nth-child(4n) {
    border-right: none;
  }

  &__read-more {
    transition: color 0.2s ease;

    &:hover {
      color: $light-purple !important;
    }
  }

  &__text {
    /*display: -webkit-box;*/
    /*-webkit-line-clamp: 5;*/
    /*-webkit-box-orient: vertical;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/

    margin-bottom: rem(30);

    p {
      margin-bottom: rem(4);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__img {
    width: rem(100);
    float: left;
    margin-right: rem(10);
  }
}
</style>
