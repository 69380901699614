<template>
  <div class="open-orders">
    <Loading v-if="isLoading" />
    <div v-else class=""> 
    <a @click="exportToExcel" class="export_excel"><img alt="ექსპორტი ექსელში" src="@/assets/images/excel.png" /></a>
    <h2 class="dashboard-page-title mb-4">ღია შეკვეთები</h2>
        
      <div v-if="sales_orders.order" class="overflow-auto">
        <div class="d-table table w-100">
          <div class="tr mb-3">
            <div class="th order-number">შეკვეთა</div>
            <div class="th">ტიპი</div>
            <div class="th date">თარიღი</div>
            <div class="th amount text-nowrap">თანხა</div>
            <div class="th amount text-nowrap">ვალუტა</div>
            <div class="th status">სტატუსი</div>
            <div class="th">შენიშვნა</div>
          </div>
          <router-link
            v-for="item in sales_orders.order"
            :key="item.id"
            class="position-relative vertical-align-middle tr w-100 font-size-14 color-dark-gray bpg-phone-sans text-decoration-none"
            :to="{
              name: 'OpenOrderNested',
              params: {
                id: item.order_id,
                type: item.order_type
              }
            }"
          >
            <div class="td order-number">{{ item.order_id }}</div>
            <div class="td">{{ item.order_type }}</div>
            <div class="td date">{{ item.order_date }}</div>
            <div class="td amount text-right text-nowrap">
              {{ item.amount }}
            </div>
            <div class="td amount">{{ item.currency }}</div>
            <div class="td status">
              <div v-if="item.order_on_hold == 'Y'" class="tooltip-parent">
                <div class="mt-1">
                  <i class="icon-lock color-red font-size-14"></i>
                </div>
                <Tooltip
                  ><span class="color-red">შეკვეთა შეჩერებულია</span></Tooltip
                >
              </div>
              <div v-else>-</div>
            </div>
            <div class="td">{{ item.note }}</div>
          </router-link>
        </div>
      </div>
      <h6
        v-if="!sales_orders.order"
        class="font-size-16 bpg-nino-mtarvruli color-orange mt-5 text-center w-100"
      >
        ღია შეკვეთები ვერ მოიძებნა
      </h6>
      <Pagination
        v-model="page_num"
        :records="total_lines"
        :per-page="lines_per_page"
        class="mt-5 ml-auto"
        :options="paginationOptions"
        @paginate="onPaginate"
      />
    </div>
  </div>
</template>

<style lang="scss">
.open-orders {
  .table {
    tr:hover,
    .tr:hover {
      background-color: #5353530d;
    }

    .td {
      vertical-align: middle;
    }

    .date {
      min-width: rem(110);
    }

    .amount {
      min-width: rem(60);
      max-width: rem(100);
    }

    .status {
      min-width: rem(40);
      max-width: rem(80);
    }
  }
}
.export_excel {float: right; cursor: pointer;}
</style>

<script>
import CustomerService from '../../services/CustomerService';
import tableMixin from '@/mixins/table-mixin';
import Loading from '../../components/common/Loading';
import Tooltip from '../../components/common/Tooltip';
import * as XLSX from 'xlsx';

export default {
  name: 'OpenOrders',
  components: { Loading, Tooltip },
  mixins: [tableMixin],
  data() {
    return {
      data: [],
      page_num: 1,
      total_lines: 0,
      total_pages: 0,
      lines_per_page: 25,
      isLoading: false,
      sales_orders: [],
      sales_orders_excel: []
    };
  },
  created() {
    if (this.$route.query.page) this.page_num = Number(this.$route.query.page);
    this.getCustomerOpenOrders();
    this.getCustomerOpenOrders_excel();
  },
  methods: {
    getCustomerOpenOrders(page = this.page_num) {
      this.isLoading = true;
      const data = {
        page_num: this.page_num,
        lines_per_page: this.lines_per_page
      }; 
      CustomerService.getCustomerOpenOrders(data)
        .then(({ data }) => {
          this.setUrlQuery(page);
          this.page_num = page;
          this.sales_orders = data.sales_orders;
          this.total_lines = data.total_lines;
          this.total_pages = data.total_pages;
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
      
getCustomerOpenOrders_excel(page = 1)  {
  const data_excel = {
        page_num: 1,
        lines_per_page: 10000
      };
        
    CustomerService.getCustomerOpenOrders_excel(data_excel)
    .then(({ data }) => {
      this.sales_orders_excel = data.sales_orders;
    })
    .catch((error) => {
      console.error(error.response);
    })
    .finally(() => {
      this.isLoading = false;
    });    
  },
    
      
exportToExcel() {
  this.getCustomerOpenOrders_excel();
  const tableData = this.getTableData();
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Open Orders');
  const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const fileName = 'open_orders.xlsx';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
},

getTableData() {
  return this.sales_orders_excel.order.map(item => {
    return {
      'შეკვეთა': item.order_id,
      'ტიპი': item.order_type,
      'თარიღი': item.order_date,
      'თანხა': item.amount,
      'ვალუტა': item.currency,
      'სტატუსი': item.order_on_hold === 'Y' ? 'შეკვეთა შეჩერებულია' : '-',
      'შენიშვნა': item.note
    };
  });
},

      
    onPaginate(page) {
      this.getCustomerOpenOrders(page);
    }
  }
};
</script>
