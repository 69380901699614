<template>
  <label
    class="checkbox d-flex align-items-center position-relative cursor-pointer"
  >
    <input
      type="checkbox"
      :checked="value == customValue"
      :value="customValue"
	  :name="name"
      @change="onChange"
    />
    <span
      class="checkbox__checkmark d-flex align-items-center justify-content-center border-radius-xxs position-absolute"
    >
      <i class="font-size-10 icon-check"></i>
    </span>
    <span class="checkbox__name font-size-12 bpg-phone-sans color-text-gray">
      <slot />
    </span>

    <span v-if="error" class="mt-2 color-red font-size-10 bpg-phone-sans">
      {{ error }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    customValue: {
      type: [String, Number, Boolean],
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
      default: false
    },
    error: {
      type: String
    }
  },
  methods: {
    onChange(event) {
      const { checked, value } = event.target;
      this.$emit('input', checked ? value : '');
    }
  }
};
</script>

<style lang="scss">
.checkbox {
  display: block;
  user-select: none;
  &__checkmark {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid $text-gray;
    i {
      color: transparent;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:focus ~ .checkbox__checkmark {
      box-shadow: 0 0 0.15rem 0.1rem rgba($text-gray, 0.5);
    }
    &:checked ~ .checkbox__checkmark {
      //border: 1px solid $green;
      background: $light-purple;
      border: 2px solid $light-purple;
      i {
        color: #fff;
      }

      svg {
        fill: $light-purple;
      }
    }
  }
  &:hover {
    input ~ .checkbox__checkmark {
      box-shadow: 0 0 0.05rem 0.05rem rgba($text-gray, 0.4);
    }
    input:checked ~ .checkbox__checkmark {
      //border: 2px solid $dark-gray, 0.5);
    }
  }
  .checkbox__name {
    margin-left: 29px;
  }
}
</style>
