<template>
  <div class="goods-menu container-fluid px-0">
    <nav
      class="goods-menu__nav-container h-100 container position-absolute z-index-1 px-0 px-md-3"
    >
      <ul
        class="goods-menu__nav-list p-0 bg-color-dark-purple d-flex align-self-center h-100 position-relative"
        @mouseleave="handleMouseLeave"
      >
        <li
          v-for="(good, index) in goods"
          :key="good.ID"
          class="goods-menu__nav-list-item text-center color-white"
          :class="{ active: activeItem === index }"
          @mouseenter="handleMouseEnter(index)"
        >
          <router-link
            :to="{ name: 'Catalog', query: { id: good.ID } }"
            event
            @click.native.prevent="handleTopCategoryClick(good, index)"
            class="goods-menu__nav-link bpg-phone-sans h-100 d-flex align-items-center  justify-content-between justify-content-md-center text-decoration-none"
          >
            <div class="goods-menu__nav-link-text position-relative">
              {{ good['Name'] }}
              <i
                class="goods-menu__nav-link-icon d-none d-md-block position-absolute icon-open_in_new font-size-15 opacity-0 color-text-gray"
              ></i>
            </div>
            <i class="d-md-none mr-3 font-size-9 icon-arrow-down"></i>
          </router-link>
          <SecondLevelItem
            v-if="good.items"
            :second-level-title="good.Name"
            :second-level-id="good.ID"
            :second-level-data="good.items"
            @close="activeItem = -1"
            :isOpened="isOpened"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import SecondLevelItem from './SecondLevelItem';
import { isDesktop } from '../../helpers/helpers';

export default {
  name: 'GoodsMenu',
  components: { SecondLevelItem },
  props: {
    goods: {
      required: true,
      type: Array
    },
    isOpened: {
      type: Boolean
    }
  },
  data() {
    return {
      activeItem: -1
    };
  },
  watch: {
    isOpened(isOpened) {
      if (!isOpened) this.activeItem = -1;
    }
  },
  methods: {
    handleTopCategoryClick(good, index) {
      if (isDesktop()) {
        this.$router.push({
          name: 'Catalog',
          query: { id: good.ID }
        });
      } else {
        this.changeActiveIndex(index);
        // console.log(good, index);
        // this.openTopCategory(this);
      }
    },
    handleMouseLeave() {
      if (isDesktop()) {
        this.activeItem = -1;
      }
    },
    handleMouseEnter(index) {
      if (isDesktop()) {
        this.changeActiveIndex(index);
      }
    },
    changeActiveIndex(index) {
      this.activeItem = this.activeItem === index ? -1 : index;
    }
  }
};
</script>

<style lang="scss">
.goods-menu {
transition: all 0.2s ease;
position: fixed;
height: 100%;
transform: translateX(-100%);
background-color: #482080;

  &.open {
    transform: translate(0);
  }

  @include media-breakpoint-up(md) {
    height: $goods-menu-height;
    position: relative;
    transform: none;

    &.active {
      height: rem(44);
    }
  }

  &__nav-container {
    left: 50%;
    transform: translateX(-50%);
  }

  &__nav-list {
    flex-wrap: wrap;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  &__nav-list-item {
    width: 100%;
    height: rem(50);

    @include media-breakpoint-up(md) {
      width: initial;
      height: 100%;
    }

    &:hover,
    &.active {
      .goods-menu__nav-link {
        background: $bg-gray;

        & > .icon-arrow-down {
          color: $text-gray !important;
        }

        .goods-menu__nav-link-icon {
          transform: translate(3px, -3px);
          opacity: 1;
        }
      }

      .goods-menu__nav-link-text {
        // transform: translateX(-5px);
        color: $text-gray !important;
      }
    }

    &.active {
      .second-level-item {
        transform: translate(0);
        opacity: 1;

        @include media-breakpoint-up(md) {
          display: flex;
        }
      }
    }
  }

  &__nav-link {
    transition: background-color 0.2s ease;

    .icon-arrow-down {
      transform: rotate(-90deg);
    }

    &-text {
      transition: color 0.3s ease;
      padding: 0 1em;
      font-size: rem(12);

      @include media-breakpoint-up(md) {
        padding: 0 1em;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 2em;
        font-size: rem(14);
        max-width: 200px;
      }
    }

    &-icon {
      right: -0.4em;
      top: -0.2em;

      @include media-breakpoint-up(lg) {
        right: 0.8em;
        top: 0;
      }
    }

    &-text,
    &-icon {
      transition: transform 0.2s ease;
    }
  }
}
</style>
