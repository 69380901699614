<template>
  <section
    class="grid-slider"
    :class="isWhite ? 'bg-color-white' : 'bg-color-bg-gray'"
  >
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <SectionTitle>{{ title }}</SectionTitle>
        <div
          v-if="!isLoading"
          :id="`grid-slider-id-${this._uid}`"
          class="grid-slider__arrows d-flex align-items-center"
          :class="{ 'd-none': showArrows }"
        >
          <ProductSliderArrowButton :is-gray="isWhite" />
          <ProductSliderArrowButton :is-gray="isWhite" is-right-direction />
        </div>
      </div>
      <div
        class="grid-slider__container border-radius-s overflow-hidden"
        :style="`margin: 0 -${edgePadding}px`"
        v-if="!isLoading"
      >
        <VueTinySlider
          :items="itemCount"
          :slide-by="itemCount"
          :gutter="gutter"
          :mouse-drag="true"
          :nav="false"
          :autoplay="false"
          :autoplayTimeout="10000"
          :loop="false"
          :autoplayButtonOutput="false"
          speed="600"
          :edgePadding="edgePadding"
          :controls-container="`#grid-slider-id-${this._uid}`"
        >
          <slot />
        </VueTinySlider>
      </div>
      <div v-else class="placeholder-section">
        <div class="placeholder-item"></div>
        <div class="placeholder-item"></div>
        <div class="placeholder-item"></div>
        <div class="placeholder-item"></div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductSliderArrowButton from './GridSliderArrowButton';
import VueTinySlider from 'vue-tiny-slider';
import SectionTitle from './SectionTitle';

export default {
  name: 'GridSlider',
  components: { SectionTitle, ProductSliderArrowButton, VueTinySlider },
  props: {
    title: {
      type: String,
      default: ''
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    gutter: {
      type: [String, Number],
      default: 0
    },
    itemsLength: {
      type: Number,
      default: 0
    },
    edgePadding: {
      type: [Number, String],
      default: 0
    },
    isLoading: {
      required: true,
      type: [Boolean]
    }
  },
  computed: {
    itemCount() {
      return window.innerWidth < 991 ? 2 : 4;
    },
    showArrows() {
      return this.$slots?.default?.length > this.itemCount;
    }
  }
};
</script>

<style lang="scss">
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
.grid-slider {
  padding: rem(25) 0 rem(10);

  @include media-breakpoint-up(md) {
    padding: rem(44) 0 rem(61);
  }

  &__arrows {
    outline-width: 0;
    appearance: none;
  }

  .placeholder-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .placeholder-item {
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    height: 200px;
    position: relative;
    overflow: hidden;

    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }

    @include media-breakpoint-up(md) {
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(
        to right,
        transparent 0%,
        #e8e8e8 50%,
        transparent 100%
      );
      animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}
</style>
