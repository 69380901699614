<template>
<div class="bg-color-bg-gray d-flex pt-3">
<div class="container mt-5">

<div class="container-fluid">    
<section class="align-items-center">
    
<div class="d-flex  justify-content-center py-5 text-center">
<div class="d-flex flex-column">
<h1 class="font-size-24 mb-4">მიმდინარეობს პოსის პროგრამის ფაილის გენერაცია</h1>
<i class="loading__icon icon-refresh font-size-30"></i>
</div>
</div>
    
</section>      
</div>
    
</div>
</div>
</template>


<script>
import axios from 'axios';
    
export default {
  name: 'GetPos',
  components: {},
  methods: {
    init() {
      this.getPosInit();
    },
    getPosInit() {  
    axios({
        url: process.env.VUE_APP_API_URL + "/get-pos",
        method: "GET",
        responseType: "blob", 
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", 'alta_b2b_pos.zip');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() => {
            this.$router.back();
          }, 1000);
      });
        
      
    },
  },
  created() {
    this.init();
  },
};
</script>

