<template>
  <div class="mb-3">
    <article
      class="product-slider-item bg-color-white border-radius-s position-relative cursor-pointer h-100 w-100"
    >
      <div
        :id="`product-item-slider-id-${this._uid}`"
        class="product-slider-item__image-slider-buttons"
      >
        <ProductItemSliderArrowButton />
        <ProductItemSliderArrowButton is-right-direction />
      </div>
      <router-link
        :to="{ name: 'Product', params: { id: data.item } }"
        class="product-slider-item__content d-inline-block w-100 text-decoration-none"
      >
          
<svg v-if = "data.is_promo == 'Y'" class="aqcia_icon_list" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <line x1="30" y1="50" x2="70" y2="50" stroke="#90298E" stroke-width="4" />
  <line x1="50" y1="30" x2="50" y2="70" stroke="#90298E" stroke-width="4" />
  <line x1="35" y1="35" x2="65" y2="65" stroke="#90298E" stroke-width="4" />
  <line x1="35" y1="65" x2="65" y2="35" stroke="#90298E" stroke-width="4" />
</svg>
          
        <ProductImageSlider
          :productId="data.item"
          :imageCount="data.img_count"
          :imagesMaxNumber="3"
          :uid="this._uid"
          imageClassName="product-slider-item__image"
        />
        <div class="product-slider-item__title-container mt-3">
          <h4
            class="product-slider-item__title bpg-phone-sans font-size-12 color-dark-gray mb-0 text-center font-weight-normal"
            :title="data.item_price_name"
          >
            {{ data.item_price_name }}
          </h4>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div
            class="product-slider-item__price bpg-nino-mtarvruli font-size-18 font-weight-bold color-purple"
          >
            {{ data.price_unit }} {{ data.currency }}
          </div>
          <div
            v-if="showOldTest"
            class="product-slider-item__old-price color-dark-gray ml-3"
          >
            <span class="bpg-nino-mtarvruli font-size-12">4500 </span>
            <i class="icon-lari font-size-9"></i>
          </div>
        </div>
      </router-link>
      <div
        class="product-slider-item__add-to-cart position-absolute bg-color-bg-gray d-flex align-items-center justify-content-center"
        @click="handleAddClick"
      >
        <i class="icon-add_to_cart font-size-14 color-brand-secondary"></i>
      </div>
    </article>
  </div>
</template>

<script>
import ProductItemSliderArrowButton from './ProductItemSliderArrowButton';
import ProductImageSlider from './ProductImageSlider.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ProductItem',
  components: { ProductItemSliderArrowButton, ProductImageSlider },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      qty: 1
    };
  },
  computed: {
    showOldTest() {
      return false;
    }
  },
  methods: {
    ...mapActions('basket', ['updateBasket']),
    handleAddClick() {
      const item = {
        item_full_name: this.data.item_price_name,
        item: this.data.item,
        is_promo:this.data.is_promo,
        qty: this.qty
      };
      this.updateBasket([item]);
      this.qty = 1;
    }
  }
};
</script>

<style lang="scss">
.aqcia_icon_list {float: right; position: absolute; width: 40px; height: 40px; margin: -15px 0px 0px 75%;}
.product-slider-item {
  box-shadow: 0px 4px 10px $box-shadow-gray;
  border: 2px solid $bg-gray;

  &__content {
    padding: rem(15) rem(20) rem(60);
    height: rem(262);
    &:hover {
      .product-slider-item__title {
        text-decoration: underline;
      }
    }
  }

  &__image {
    width: 100%;
    height: rem(100);
  }

  &__title-container {
    height: rem(48);
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &__price {
    letter-spacing: 1px;
  }

  &__old-price {
    text-decoration: line-through;
  }

  &__add-to-cart {
    width: rem(58);
    height: rem(44);
    border-radius: 5px 0 5px 5px;
    right: 0;
    bottom: rem(-12);
    transition: background-color 0.2s ease;
    i {
      transition: color 0.2s ease;
    }
    &:hover {
      background: $light-purple;
      i {
        color: $white;
      }
    }
  }
}
</style>
