<template>
  <div class="report-amount">
    <div
      v-if="title"
      class="bpg-phone-sans color-text-gray font-size-12 mb-2 d-inline-block"
    >
      {{ title }}
    </div>
    <div
      :class="
        `report-amount__value font-size-14 w-100 bpg-phone-sans border-radius-s color-${fontColor}`
      "
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    },
    fontColor: {
      type: String,
      default: 'orange'
    }
  }
};
</script>

<style lang="scss">
.report-amount {
  min-width: $input-width;

  &__value {
    border: 1px solid $border-gray;
    border-radius: 5px;
    padding: rem(15) 19px;
    min-height: rem(53);
  }
}
</style>
