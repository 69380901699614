export default {
  props: {
    title: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    }
  }
};
