export default {
  methods: {
    getImages(productId, imageCount, width = null) {
      const images = [];

      if (!imageCount) {
        images.push(require(`@/assets/images/no-image.png`));

        return images;
      }

      for (let i = 1; i <= imageCount; i++) {
        images.push(this.getImageLink(productId, i, width));
      }

      return images;
    },
    getImageLink(productId, imageNumber = 1, width = null) {
		
	const itemFolder = Math.floor(productId / 1000);
		
	if (width == "700") {
        //url += `?width=${width}`;
		var salt = (productId * 1987) % 10000;
		var folder_suffix = "w";
     }
	else {
		var salt = (productId * 1997) % 10000;
		var folder_suffix = "";
		var width = "164";
	}
		
      let url = `${process.env.VUE_APP_IMAGE_API_URL}/images/${folder_suffix}${width}/${itemFolder}/${productId}_${salt}_${imageNumber}.jpg`;
      
      return url;
    }
  }
};
