<template>
  <div
    class="basket-count-header-item d-flex color-dark-gray m-3 ml-md-4 position-relative"
  >
    <i class="icon-add_to_cart font-size-15 mr-1"></i>
    <div
      v-if="getBasketItemsCount"
      class="basket-count-header-item__counter font-size-12 bpg-nino-mtarvruli  border-radius-s d-flex align-items-center justify-content-center"
    >
      {{ getBasketItemsCount }}
    </div>
    <span
      class="d-none d-md-block bpg-nino-mtarvruli font-size-14 ml-2"
      v-if="basket"
    >
      {{ basket.total_price }} ({{ getCurrency }})</span
    >
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HeaderCartCountItem',
  computed: {
    ...mapState('basket', ['basket']),
    ...mapGetters('basket', ['getBasketItemsCount']),
    ...mapGetters('auth', ['getCurrency'])
  }
};
</script>

<style lang="scss">
.basket-count-header-item {
  white-space: nowrap;

  &__currency-icon {
    margin-left: rem(2);
  }

  &__counter {
    @include abs-position(-14px, auto, auto, 12px);
    padding-top: 2px;
    width: 17px;
    background-color: #482080;
    height: 15px;
    color: $white;
  }
}
</style>
