<template>
  <div class="ledger">
    <Loading v-if="isLoading" />
    <div
      v-else
      class="d-flex flex-wrap align-items-center justify-content-between mb-2 mb-md-5"
    >
      <h2 class="dashboard-page-title">ამონაწერი</h2>
      <div class="d-flex">
        <span
          class="font-size-12 color-text-gray bpg-phone-sans mr-4 d-flex align-items-center"
          >ფილტრი:</span
        >
        <v-select
          :value="'მაჩვენე ' + lines_per_page + ' ნივთი'"
          :getOptionLabel="(option) => option"
          class="d-none d-md-block style-chooser mr-3"
          :options="selectData"
          @input="
            lines_per_page = $event;
            filterCustomerSalesOrders(1);
          "
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3"
              ><i class="icon-arrow-down font-size-6 color-dark-gray "></i
            ></span>
          </template>
        </v-select>
        <DatePicker
          v-model="date"
          :format="'YYYY-MM-DD'"
          range
          placeholder="აირჩიეთ თარიღი"
          prefix-class="xmx"
          @change="onDateChange"
          range-separator=" - "
        >
          <template v-slot:icon-calendar>
            <i class="icon-calendar font-size-15 color-text-gray d-block"></i>
          </template>
        </DatePicker>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <ReportAmount
        title="საწყისი ბალანსი (GEL)"
        :value="start_balance"
        class="mt-3 mr-4"
      />
      <ReportAmount
        title="საბოლოო ბალანსი (GEL)"
        :value="end_balance"
        class="mt-3"
      />
    </div>

<a @click="exportToExcel" class="export_excel"><img alt="ექსპორტი ექსელში" src="@/assets/images/excel.png" /></a>

    <div v-if="ledger_items.items" class="mt-5 overflow-auto">
      <div class="d-table table w-100">
        <div class="tr mb-3">
          <div class="th">დოკ. ტიპი.</div>
          <div class="th">თარიღი</div>
          <div class="th">დოკუმენტი</div>
          <div class="th">#1</div>
          <div class="th">#2</div>
          <div class="th">თანხა</div>
          <div class="th text-nowrap">ნაზარდი ჯამი</div>
          <div class="th text-nowrap">ვალუტა</div>
        </div>
        <div
          v-for="(item, index) in ledger_items.items"
          :key="index"
          class="position-relative tr w-100 mb-3 font-size-14 color-dark-gray bpg-phone-sans"
          :class="{ active: index === activeItem }"
        >
          <div class="td">{{ item.src_type }}</div>
          <div class="td">{{ item.date }}</div>
          <div class="td">{{ item.doc }}</div>
          <div class="td">{{ item.dn1 }}</div>
          <div class="td">{{ item.dn2 }}</div>
          <div
            class="td text-right"
            :class="{ 'color-red': parseInt(getAmount(item)) < 0 }"
          >
            {{ getAmount(item) }}
          </div>
          <div class="td text-right">{{ item.rest_amount }}</div>
          <div class="td">{{ item.currency }}</div>
          <!-- <div
            class="table__see-more td text-nowrap"
            @click="changeActiveItem(index)"
          >
            <span v-if="index === activeItem">
              დახურვა
            </span>
            <span v-else>
              სრულად
            </span>
            <div
              class="table__more-content"
              :class="{ active: index === activeItem }"
            >
              <div
                v-if="item.dn1"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray">
                  #1
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.dn1 }}
                </div>
              </div>
              <div
                v-if="item.dn2"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray">
                  #2
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.dn2 }}
                </div>
              </div>
              <div
                v-if="item.doc_amount"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray ">
                  დოკუმენტის თანხა
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.doc_amount.toFixed(2) }}
                </div>
              </div>
              <div
                v-if="item.inv_amount"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray ">
                  ინვოისის თანხა
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.inv_amount.toFixed(2) }}
                </div>
              </div>
              <div
                v-if="item.inv_amount_open"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray ">
                  ინვოისის თანხა
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.inv_amount_open.toFixed(2) }}
                </div>
              </div>
              <div
                v-if="item.db"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray ">
                  დებიტი
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.db.toFixed(2) }}
                </div>
              </div>
              <div
                v-if="item.ct"
                class="d-flex font-size-14 bpg-phone-sans mb-4"
              >
                <div class="mr-4 color-text-gray ">
                  კრედიტი
                </div>
                <div class="flex-1 color-dark-gray text-left">
                  {{ item.ct.toFixed(2) }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <h6
      v-else
      class="font-size-16 bpg-nino-mtarvruli color-orange mt-5 text-center w-100"
    >
      ამონაწერები ვერ მოიძებნა
    </h6>
    <Pagination
      v-model="page_num"
      :records="total_lines"
      :per-page="lines_per_page"
      class="mt-3 mt-md-5 ml-auto"
      :options="paginationOptions"
      @paginate="onPaginate"
    />
  </div>
</template>

<style lang="scss">
.export_excel {float: right; cursor: pointer;}
</style>

<script>
import vSelect from 'vue-select';
import tableMixin from '@/mixins/table-mixin';
import ReportAmount from '../../components/common/ReportItem';
import CustomerService from '../../services/CustomerService';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ka';
import Loading from '../../components/common/Loading';
import * as XLSX from 'xlsx';

export default {
  name: 'Ledger',
  components: { Loading, ReportAmount, DatePicker, vSelect },
  mixins: [tableMixin],
  data() {
    return {
      selectData: [10, 25, 50, 100],
      date: '',
      page_num: 1,
      lines_per_page: 25,
      total_lines: 0,
      total_pages: 0,
      activeItem: -1,
      isLoading: false,
      ledger_items: [],
      ledger_items_excel: [],
      end_balance: 0,
      start_balance: 0
    };
  },
  created() {
    if (this.$route.query.page) this.page_num = Number(this.$route.query.page);
    this.refreshPage();
    this.filterCustomerSalesOrders_excel();
  },
  methods: {
    refreshPage() {
      this.date = [
        this.$moment()
          .subtract(1, 'month')
          .toDate(),
        this.$moment().toDate()
      ];
      this.filterCustomerSalesOrders(1);
      this.filterCustomerSalesOrders_excel(1);
    },
    onDateChange(date) {
      this.date = date;
      this.filterCustomerSalesOrders(1);
      this.filterCustomerSalesOrders_excel(1);
    },
    filterCustomerSalesOrders(page = this.page_num) {
      this.isLoading = true;
      const date1 = this.date[0]
        ? this.$moment(this.date[0]).format('YYYY-MM-DD')
        : '2010-01-01';
      const date2 = this.date[1]
        ? this.$moment(this.date[1]).format('YYYY-MM-DD')
        : this.$moment(new Date()).format('YYYY-MM-DD');
      const data = {
        date1,
        date2,
        page_num: this.page_num,
        lines_per_page: this.lines_per_page
      };

      CustomerService.getCustomerLedger(data)
        .then(({ data }) => {
          this.setUrlQuery(page);
          this.page_num = page;
          this.ledger_items = data.ledger_items;
          this.total_lines = data.total_lines;
          this.total_pages = data.total_pages;
          this.start_balance = data.start_balance;
          this.end_balance = data.end_balance;
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
      
   
    
filterCustomerSalesOrders_excel(page = 1)  {

const date1 = this.date[0]
        ? this.$moment(this.date[0]).format('YYYY-MM-DD')
        : '2010-01-01';
      const date2 = this.date[1]
        ? this.$moment(this.date[1]).format('YYYY-MM-DD')
        : this.$moment(new Date()).format('YYYY-MM-DD');
    
  const data_excel = {
        date1,
        date2,
        page_num: 1,
        lines_per_page: 10000
      };
        
    CustomerService.getCustomerLedger_excel(data_excel)
    .then(({ data }) => {
      this.ledger_items_excel = data.ledger_items;
    })
    .catch((error) => {
      console.error(error.response);
    })
    .finally(() => {
      this.isLoading = false;
    });    
  },
    
      
exportToExcel() {
  this.filterCustomerSalesOrders_excel();
  const tableData = this.getTableData();
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'ledger');
  const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const fileName = 'ledger.xlsx';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
},

getTableData() {
  return this.ledger_items_excel.items.map(item => {
    return {
      'დოკ. ტიპი': item.src_type,
      'თარიღი': item.date,
      'დოკუმენტი': item.date,
      '#1': item.dn1,
      '#2': item.dn2,
      'თანხა': item.inv_amount,
      'ნაზარდი ჯამი': item.rest_amount,
      'ვალუტა': item.currency
    };
  });
}, 
      

          
      
      
    onPaginate(page) {
      this.filterCustomerSalesOrders(page);
    },
    getAmount(item) {
      return item.inv_amount;
    }
  }
};
</script>
