<template>
  <div class="" v-if="images">
    <VueTinySlider
      v-if="images && images.length > 0"
      items="1"
      :nav="false"
      :autoplay="false"
      :loop="true"
      :autoplayButtonOutput="false"
      :controls-container="`#product-item-slider-id-${this.uid}`"
    >
      <div
        v-for="item in images"
        :key="item"
        class="bg-contain"
        :class="imageClassName"
        :style="`background-image: url('${item}')`"
      ></div>
    </VueTinySlider>
  </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';
import imagesMixin from '@/mixins/images-mixin';

export default {
  name: 'ProductImageSlider',
  components: { VueTinySlider },
  mixins: [imagesMixin],
  props: {
    productId: {
      type: [String, Number],
      required: true
    },
    imageCount: {
      type: Number,
      required: true
    },
    imagesMaxNumber: {
      type: Number,
      default: 1
    },
    imageClassName: {
      type: String
    },
    uid: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      images: null
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    productId() {
      this.images = null;
      setTimeout(() => {
        this.init();
      }, 0);
    }
  },
  methods: {
    init() {
      const imageCount = this.imageCount < 3 ? this.imageCount : 3;
      this.images = this.getImages(this.productId, imageCount);
    }
  }
};
</script>
