<template>
  <div class="home">
    <ImageSlider />
    <GridSlider
      title="ახალი პროდუქტები"
      gutter="30"
      class="home__grid-slider"
      :isLoading="recentProductsIsLoading"
      id="recent-products"
    >
      <ProductItem
        v-for="(item, itemIndex) in recentProducts"
        :data="item"
        :key="itemIndex"
      />
    </GridSlider>
    <GridSlider
      title="სიახლეები"
      gutter="30"
      :items-length="12"
      :isLoading="newsIsLoading"
      id="news"
    >
      <NewsGridItem v-for="(item, index) in news" :key="index" :data="item" />
    </GridSlider>
  </div>
</template>

<script>
import GridSlider from '../components/common/GridSlider';
import ProductItem from '../components/common/ProductItem';
import NewsGridItem from '../components/news/NewsGridItem';
import ImageSlider from '../components/common/ImageSlider';

import CatalogService from '../services/CatalogService';
import NewsService from '../services/NewsService';

export default {
  name: 'Home',
  components: {
    ImageSlider,
    NewsGridItem,
    ProductItem,
    GridSlider
  },
  data() {
    return {
      recentProducts: [],
      recentProductsIsLoading: false,
      news: [],
      newsIsLoading: false
    };
  },
  methods: {
    init() {
      this.getRecentProducts();
      this.getNewsData();
    },
    getNewsData() {
      this.newsIsLoading = true;
      NewsService.getNews()
        .then(({ data }) => {
          this.newsIsLoading = false;
          this.news = data.news_items.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRecentProducts() {
      this.recentProductsIsLoading = true;
      CatalogService.getRecentProducts()
        .then(({ data }) => {
          this.recentProductsIsLoading = false;
          this.recentProducts = data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.init();
  }
};
</script>
