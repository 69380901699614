<template>
  <div
    class="dropdown-box position-relative cursor-pointer"
    @mouseenter="mouseEnterHandler"
    @mouseleave="isActive = false"
    :class="{ active: isActive }"
  >
    <div @click="mouseClickHandler" class="d-flex">
      <slot name="topContent" />
    </div>
    <div
      class="dropdown-box__content position-absolute bg-white border-radius-s"
    >
      <slot name="dropdownContent" :close-dropdown="closeDropdown" />
    </div>
  </div>
</template>

<script>
import { isDesktop } from '../../helpers/helpers';

export default {
  name: 'DropdownBox',
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    mouseEnterHandler() {
      if (isDesktop()) this.isActive = true;
    },
    mouseClickHandler() {
      if (!isDesktop()) this.isActive = !this.isActive;
    },
    closeDropdown() {
      this.isActive = false;
    }
  }
};
</script>

<style lang="scss">
.dropdown-box {
  &__content {
    opacity: 0;
    visibility: hidden;
    top: 100%;
    right: 0;
    border: 1px solid $bg-gray;
    box-shadow: 0 4px 10px $box-shadow-gray;
    z-index: 2;
  }

  &.active {
    .dropdown-box__content {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
