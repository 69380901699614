import apiClient from './api-client';

export default {
  getPriceList(data) {
    return apiClient.post(`/get-price-list`, data);
  },
  getProductItem(data) {
    return apiClient.post(`/item-info`, data);
  },
  GetItemRest(data) {
    return apiClient.post(`/get-item-rest`, data);
  },
  GetCatalog(data) {
    return apiClient.post(`/get-catalog`, data);
  },
  getProductFeatures(data) {
    return apiClient.post(`/item-features`, data);
  },
/*  es methodi agar gamoiyeneba suratebis camosagebad
getProductImages(productId, data) {
    return apiClient.get(`/product-image/${productId}/${count}`, { params: data });
  },*/
  getRecentProducts() {
    return apiClient.get('/new-products');
  },
  getPromos() {
    return apiClient.get('/get-promos');
  }, 
  GetAqciebi(data) {
      return apiClient.get('get-aqciebi/', {
      params: {
        "item": data
      }
    });
  }
    
  
};
