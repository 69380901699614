<template>
  <div class="order-pickup-popup position-absolute position-full">
    <Loading v-if="fetching" />
    <div
      @click="closePopup"
      class="order-pickup-popup__background-overlay position-fixed position-full"
    ></div>
    <div
      class="order-pickup-popup__content position-absolute border-radius-s bg-color-white"
      :class="{ mounted: mounted }"
    >
      <div
        class="status-popup__header d-flex align-items-center justify-content-between pb-3"
      >
        <div
          class="bpg-phone-sans font-size-14 colo-dark-gray d-flex align-items-center"
        >
          შეავსეთ ვიზიტის დეტალები
        </div>
        <div
          class="status-popup__close-container position-relative cursor-pointer d-flex text-decoration-none tooltip-parent"
        >
          <i
            @click="closePopup"
            class="icon-close1 font-size-13 color-orange"
          ></i>
        </div>
      </div>
      <div class="font-size-14 mt-3 color-text-gray bpg-phone-sans">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            class="order-pickup-popup__pickup-form"
          >
            <div class="row mt-2">
              <div class="col-12">
                <span
                  class="font-size-12 color-text-gray bpg-phone-sans mb-1 d-flex align-items-center"
                  >აირჩიეთ ან დაამატეთ ახალი მისამართი:</span
                >
                <v-select
                  v-model="selectedAddress"
                  label="label"
                  class="style-chooser"
                  :options="customerBranchesSelect"
                  @input="addressSelectInputHandler"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class=""
                      ><i
                        class="icon-arrow-down font-size-6 color-dark-gray "
                      ></i
                    ></span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <BaseInput
                  v-model="pickupForm.delivery_city"
                  class=""
                  title="ქალაქი"
                  placeholder=""
                  :disabled="newAddressFieldIsDisabled"
                />
              </div>
              <div class="col-8">
                <BaseInput
                  v-model="pickupForm.delivery_address"
                  class=""
                  title="მისამართი"
                  placeholder=""
                  :disabled="newAddressFieldIsDisabled"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <BaseInput
                  v-model="pickupForm.contact_phone"
                  class=""
                  title="საკონტაქტო ტელეფონი"
                  placeholder=""
                />
              </div>
            </div>
            <hr class="mt-4" />
            <h4 class="color-black mt-3">აირჩიეთ გადამზიდველი</h4>
            <div class="row mt-2">
              <div class="col-12">
                <span
                  class="font-size-12 color-text-gray bpg-phone-sans mb-2 d-flex align-items-center"
                  >გადამზიდველი:</span
                >
                <v-select
                  v-model="selectedTransportationCarrier"
                  class="style-chooser"
                  :options="transportationCarriersSelect"
                  :disabled="carrierFieldIsDisabled"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class=""
                      ><i
                        class="icon-arrow-down font-size-6 color-dark-gray "
                      ></i
                    ></span>
                  </template>
                </v-select>
              </div>
            </div>
            <h4 class="color-black mt-3">ან შეიყვანეთ მძღოლის მონაცემები</h4>
            <div class="row mt-2">
              <div class="col-12 col-md-6">
                <span
                  class="font-size-12 color-text-gray bpg-phone-sans mb-2 d-flex align-items-center"
                  >აირჩეთ ან დაამატეთ მძღოლი:</span
                >
                <v-select
                  v-model="selectedVechile"
                  class="style-chooser"
                  :options="customerVehiclesSelect"
                  @input="vehileSelectInputHandler"
                  :disabled="vechileFieldIsDisabled"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class=""
                      ><i
                        class="icon-arrow-down font-size-6 color-dark-gray "
                      ></i
                    ></span>
                  </template>
                </v-select>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <BaseInput
                  v-model="pickupForm.driver_tax_id"
                  class=""
                  title="მძღოლის პირადი ნომერი"
                  placeholder="Xxxxxxxxxx"
                  :disabled="newVechileFieldIsDisabled"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <BaseInput
                  v-model="pickupForm.vehicle_serial"
                  class=""
                  title="ავტომობილის ნომერი"
                  placeholder="xx123xx"
                  :disabled="newVechileFieldIsDisabled"
                />
              </div>
              <div class="col-6">
                <BaseInput
                  v-model="pickupForm.contact_name"
                  class=""
                  title="მძღოლის სახელი"
                  :disabled="newVechileFieldIsDisabled"
                />
              </div>
            </div>
            <div class="row mt-2">
              <h4 class="col-12 color-black mt-3">დამატებითი კომენტარი</h4>
              <div class="col-12">
                <BaseTextarea
                  v-model="pickupForm.note"
                  placeholder="დაიწყეთ წერა.."
                ></BaseTextarea>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 d-flex justify-content-end">
                <BaseButton
                  class="white mr-3"
                  type="submit"
                  :isLoading="fetching"
                  >შენახვა</BaseButton
                >
                <BaseButton
                  class="bg-color-dark-green"
                  :isLoading="fetching"
                  @click.native="createShipment"
                  >შეკვეთის დადასტურება</BaseButton
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <transition name="fade">
      <StatusPopup
        v-if="status.isOpen"
        :status-is-success="status.isSuccess"
        :visible="status.isOpen"
        @close="status.isOpen = false"
      >
        <template v-slot:header>
          {{ status.title }}
        </template>
        <template v-slot:body>
          {{ status.body }}
        </template>
        <template v-slot:buttons="slotProps">
          <BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
        </template>
      </StatusPopup>
    </transition>
  </div>
</template>

<script>
import BaseTextarea from '../../components/form-components/BaseTextarea.vue';
import vSelect from 'vue-select';

import CustomerService from '../../services/CustomerService';
import BaseButton from '../common/BaseButton.vue';
import Loading from '@/components/common/Loading';
import StatusPopup from '@/components/common/StatusPopup';

export default {
  name: 'OrderPickupPopup',
  components: {
    StatusPopup,
    Loading,
    BaseTextarea,
    vSelect,
    BaseButton
  },
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      mounted: false,
      fetching: false,
      customerBranches: [],
      transportationCarriers: [],
      customerVehicles: [],
      selectedAddress: '',
      selectedVechile: '',
      selectedTransportationCarrier: '',
      pickupForm: {
        delivery_city: '',
        delivery_address: '',
        contact_phone: '',
        carrier_id: '',
        vehicle_serial: '',
        driver_tax_id: '',
        contact_name: '',
        note: ''
      },
      status: {
        isSuccess: true,
        isOpen: false,
        title: 'ვიზიტის დაჯავშნა',
        body: ''
      }
    };
  },
  computed: {
    customerBranchesSelect() {
      let branchOptions = this.customerBranches.map((branch) => {
        return {
          id: branch.id,
          label: `${branch.city}, ${branch.address}`,
          branch: branch
        };
      });

      branchOptions.push({
        id: 'new',
        label: 'ახალი მისამართის დამატება'
      });

      return branchOptions;
    },
    transportationCarriersSelect() {
      let carrierOptions = this.transportationCarriers.map((carrier) => {
        return {
          id: carrier.carrier_id,
          label: carrier.carrier_name,
          carrier: carrier
        };
      });

      carrierOptions.unshift({
        id: '',
        label: 'აირჩიეთ გადამზიდავი'
      });

      return carrierOptions;
    },
    customerVehiclesSelect() {
      let vehiclesOptions = this.customerVehicles.map((vehicle) => {
        return {
          id: vehicle.vehicle_serial,
          label: `${vehicle.driver_name} - ${vehicle.vehicle_serial}`,
          vehicle: vehicle
        };
      });

      vehiclesOptions.unshift({
        id: '',
        label: 'აირჩიეთ ავტომობილი'
      });

      vehiclesOptions.push({
        id: 'new',
        label: 'ახალი ავტომობილის დამატება'
      });

      return vehiclesOptions;
    },
    newAddressFieldIsDisabled() {
      return this.selectedAddress.id !== 'new';
    },
    newVechileFieldIsDisabled() {
      return this.selectedVechile.id !== 'new';
    },
    vechileFieldIsDisabled() {
      return !(
        this.selectedTransportationCarrier == '' ||
        this.selectedTransportationCarrier.id == ''
      );
    },
    carrierFieldIsDisabled() {
      return !(this.selectedVechile == '' || this.selectedVechile.id == '');
    }
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    });
    document.addEventListener('keydown', this.keyDownListener);
    this.loadFormData();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDownListener);
  },
  methods: {
    createShipment() {
      this.status.isSuccess = false;
      this.fetching = true;

      const [order_id, order_type] = this.order.order_id.split('/');

      const data = {
        order_id: order_id,
        order_type: order_type
      };

      CustomerService.createShipment(data)
        .then(({ data }) => {
          if (data.error_id == '0') {
            this.status.isSuccess = true;
          }

          this.status.body = data.error_desc;
        })
        .catch(() => {
          this.status.body = data.error_desc;
        })
        .finally(() => {
          this.status.isOpen = true;
          this.fetching = false;
        });
    },
    loadFormData() {
      CustomerService.getCustomerBranches().then(({ data }) => {
        this.customerBranches = data.branches;
      });
      CustomerService.getTransportationCarriers().then(({ data }) => {
        this.transportationCarriers = data.carriers;
      });
      CustomerService.getCustomerVehicles().then(({ data }) => {
        this.customerVehicles = data.vehicles;
      });

      this.pickupForm = {
        delivery_city: this.order.delivery_city,
        delivery_address: this.order.delivery_address,
        contact_phone: this.order.contact_phone,
        carrier_id: this.order.carrier_id,
        vehicle_serial: this.order.vehicle_serial,
        driver_tax_id: this.order.driver_tax_id,
        note: this.order.note
      };
    },
    addressSelectInputHandler(input) {
      if (input.id === 'new' || input.id === '') {
        this.pickupForm.delivery_address = '';
        this.pickupForm.delivery_city = '';
        return;
      }
      this.pickupForm.delivery_address = input.branch.address;
      this.pickupForm.delivery_city = input.branch.city;
    },
    vehileSelectInputHandler(input) {
      if (input.id === 'new' || input.id === '') {
        this.pickupForm.driver_tax_id = '';
        this.pickupForm.vehicle_serial = '';
        this.pickupForm.contact_name = '';
        return;
      }

      this.pickupForm.driver_tax_id = input.vehicle.driver_tax_id;
      this.pickupForm.vehicle_serial = input.vehicle.vehicle_serial;
      this.pickupForm.contact_name = input.vehicle.driver_name;
    },
    onSubmit() {
      let data = { ...this.pickupForm };
      (data.order_id = this.$route.params.id.toString()),
        (data.order_type = this.$route.params.type);
      data.carrier_id = this.selectedTransportationCarrier.id;

      this.status.isSuccess = false;
      this.fetching = true;

      console.log(data);
      CustomerService.setOrderDeliveryInfo(data)
        .then(({ data }) => {
          if (data.error_id == '0') {
            this.status.isSuccess = true;
          }

          this.status.body = data.error_desc;
        })
        .catch(() => {
          this.status.body = data.error_desc;
        })
        .finally(() => {
          this.status.isOpen = true;
          this.fetching = false;
        });
    },
    closePopup() {
      this.$emit('close');
    },
    keyDownListener(e) {
      if (e.key === 'Escape') {
        this.closePopup();
      }
    }
  },
  watch: {
    selectedTransportationCarrier() {
      if (this.vechileFieldIsDisabled) {
        this.pickupForm.vehicle_serial = '';
        this.pickupForm.contact_name = '';
        this.pickupForm.driver_tax_id = '';
        this.selectedVechile = '';
      }
    }
  }
};
</script>

<style lang="scss">
.order-pickup-popup {
  z-index: 9;

  &__background-overlay {
    background: rgba($dark-gray, 0.5);
  }

  &__content {
    padding: rem(22) rem(25) rem(40);
    top: 3%;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: all 0.3s ease;
    opacity: 0;
    width: calc(100% - #{rem(30)});

    @include media-breakpoint-up(md) {
      max-width: rem(780);
      padding: rem(22) rem(40) rem(40);
    }

    &.mounted {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }

  &__header {
    border-bottom: 3px solid $border-gray;
  }

  &__tab-switcher {
    background-color: rgba(#f7f8fa, 0.66);

    .base-button {
      background-color: rgba(#f7f8fa, 0.66);
      color: #3a3e4c;
      box-shadow: none !important;
      flex: 1;
      justify-content: center;

      &.active {
        color: #f77e0b;
        background-color: #eff0f2;
      }
    }

    .divider {
      padding: 0 1em;
    }
  }

  .vs--disabled {
    .vs__dropdown-toggle {
      background: #f7f8fa;
    }
  }
}
</style>
