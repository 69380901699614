<template>
  <div class="orders">
    <Loading v-if="isLoading" />
    <div
      class="d-flex flex-wrap align-items-center justify-content-between mb-5"
    >
      <h2 class="dashboard-page-title">შეკვეთები</h2>
      <div class="d-flex">
        <span
          class="font-size-12 color-text-gray bpg-phone-sans mr-4 d-flex align-items-center"
          >ფილტრი:</span
        >
        <v-select
          :value="'მაჩვენე ' + lines_per_page + ' შეკვეთა'"
          :getOptionLabel="(option) => option"
          class="d-none d-md-block style-chooser mr-3"
          :options="selectData"
          @input="
            lines_per_page = $event;
            filterCustomerSalesOrders(1);
          "
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3"
              ><i class="icon-arrow-down font-size-6 color-dark-gray "></i
            ></span>
          </template>
        </v-select>
        <DatePicker
          v-model="date"
          :format="'YYYY-MM-DD'"
          range
          placeholder="აირჩიეთ თარიღი"
          prefix-class="xmx"
          @change="onDateChange"
          range-separator=" - "
          :disabled-date="(date) => date >= new Date()"
          :default-value="date"
        >
          <template v-slot:icon-calendar>
            <i class="icon-calendar font-size-15 color-text-gray d-block"></i>
          </template>
        </DatePicker>
      </div>
    </div>
    <div class="d-flex align-items-center mb-5">
      <button
        class="orders__refresh d-flex align-items-center justify-content-center bg-color-light-purple mr-3"
        :disabled="isLoading"
        @click="refreshPage"
      >
        <i
          class="icon-refresh font-size-24 color-white"
          :class="{ active: isLoading }"
        ></i>
      </button>
      <div
        class="orders__refresh-text bpg-phone-sans color-text-gray font-size-12 py-2 px-3 border-radius-s"
      >
        განაახლე შეკვეთის გვერდი
      </div>
    </div>

<a @click="exportToExcel" class="export_excel"><img alt="ექსპორტი ექსელში" src="@/assets/images/excel.png" /></a>

    <div v-if="sales_orders.order" class="d-table table w-100">
      <div class="tr mb-3">
        <div class="th">შეკვეთა</div>
        <div class="th">ტიპი</div>
        <div class="th">თარიღი</div>
        <div class="th text-nowrap">თანხა</div>
        <div class="th text-nowrap">ვალუტა</div>
      </div>
      <router-link
        v-for="item in sales_orders.order"
        :key="item.id"
        class="position-relative box-shadow-none tr w-100 mb-3 font-size-14 color-dark-gray bpg-phone-sans text-decoration-none"
        :to="{
          name: 'OrderNested',
          params: {
            id: item.order_id,
            type: item.order_type
          }
        }"
      >
        <div class="td">{{ item.order_id }}</div>
        <div class="td">{{ item.order_type }}</div>
        <div class="td">{{ item.order_date }}</div>
        <div class="td text-right">{{ item.amount }}</div>
        <div class="td">{{ item.currency }}</div>
      </router-link>
    </div>
    <h6
      v-if="!sales_orders.order"
      class="font-size-16 bpg-nino-mtarvruli color-orange mt-5 text-center"
    >
      შეკვეთები ვერ მოიძებნა
    </h6>
    <Pagination
      v-model="page_num"
      :records="total_lines"
      :per-page="lines_per_page"
      class="mt-5 ml-auto"
      :options="paginationOptions"
      @paginate="onPaginate"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import tableMixin from '@/mixins/table-mixin';
import CustomerService from '../../services/CustomerService';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ka';
import Loading from '../../components/common/Loading';
import * as XLSX from 'xlsx';    
    
export default {
  name: 'Orders',
  mixins: [tableMixin],
  components: {
    Loading,
    DatePicker,
    vSelect
  },
  data() {
    return {
      selectData: [10, 25, 50, 100],
      date: '',
      page_num: 1,
      lines_per_page: 25,
      total_lines: 0,
      total_pages: 0,
      activeItem: -1,
      isLoading: false,
      sales_orders: [],
      sales_orders_excel: []
    };
  },
  created() {
    if (this.$route.query.page) this.page_num = Number(this.$route.query.page);
    this.refreshPage();
    this.getCustomerOpenOrders_excel();  
  },
  methods: {
    refreshPage() {
      this.date = [
        this.$moment()
          .subtract(1, 'month')
          .toDate(),
        this.$moment().toDate()
      ];
      this.filterCustomerSalesOrders(1);
      this.filterCustomerSalesOrders_excel(1);
    },
    onDateChange(date) {
      this.date = date;
      this.filterCustomerSalesOrders(1);
      this.filterCustomerSalesOrders_excel(1);
    },
    filterCustomerSalesOrders(page = this.page_num) {
      this.isLoading = true;

      const date1 = this.date[0]
        ? this.$moment(this.date[0]).format('YYYY-MM-DD')
        : '2010-01-01';
      const date2 = this.date[1]
        ? this.$moment(this.date[1]).format('YYYY-MM-DD')
        : this.$moment(new Date()).format('YYYY-MM-DD');
      const data = {
        date1,
        date2,
        page_num: this.page_num,
        lines_per_page: this.lines_per_page
      };

        
CustomerService.getCustomerSalesOrders(data)
        .then(({ data }) => {
          this.setUrlQuery(page);
          this.page_num = page;
          this.sales_orders = data.sales_orders;
          this.total_lines = data.total_lines;
          this.total_pages = data.total_pages;
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
      

filterCustomerSalesOrders_excel(page = 1)  {

const date1 = this.date[0]
        ? this.$moment(this.date[0]).format('YYYY-MM-DD')
        : '2010-01-01';
      const date2 = this.date[1]
        ? this.$moment(this.date[1]).format('YYYY-MM-DD')
        : this.$moment(new Date()).format('YYYY-MM-DD');
    
  const data_excel = {
        date1,
        date2,
        page_num: 1,
        lines_per_page: 10000
      };
        
    CustomerService.getCustomerSalesOrders_excel(data_excel)
    .then(({ data }) => {
      this.sales_orders_excel = data.sales_orders;
    })
    .catch((error) => {
      console.error(error.response);
    })
    .finally(() => {
      this.isLoading = false;
    });    
  },
    
      
exportToExcel() {
  this.filterCustomerSalesOrders_excel();
  const tableData = this.getTableData();
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
  const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const fileName = 'orders.xlsx';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
},

getTableData() {
  return this.sales_orders_excel.order.map(item => {
    return {
      'შეკვეთა': item.order_id,
      'ტიპი': item.order_type,
      'თარიღი': item.order_date,
      'თანხა': item.amount,
      'ვალუტა': item.currency
    };
  });
}, 
      
    onPaginate(page) {
      this.filterCustomerSalesOrders(page);
    }
  }
};
</script>

<style lang="scss">
.orders {
  &__refresh {
    width: 40px;
    height: 30px;
    border-radius: 5px;
    transition: opacity 0.3s ease;

    i {
      transition: none;

      &.active {
        transition: transform 0.2s ease;
        transform: rotate(360deg);
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__refresh-text {
    border: 1px solid $border-gray;
  }

  .tooltip {
    bottom: auto;
    top: calc(100% - 6px);
    z-index: 1;
  }
}
.export_excel {float: right; cursor: pointer;}
</style>
