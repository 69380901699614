<template>
  <div class="status-popup position-fixed position-full">
    <div
      @click="closePopup"
      class="status-popup__background-overlay position-fixed position-full"
    ></div>
    <div
      class="status-popup__content position-absolute border-radius-s bg-color-white"
      :class="{ mounted: mounted }"
    >
      <div
        class="status-popup__header d-flex align-items-center justify-content-between pb-3"
      >
        <div
          class="bpg-phone-sans font-size-14 colo-dark-gray d-flex align-items-center"
        >
          <svg class="status-popup__status-icon  mr-2">
            <use
              v-if="statusIsSuccess"
              xlink:href="~@/assets/svg/icons.svg#success"
            />
            <use v-else xlink:href="~@/assets/svg/icons.svg#error" />
          </svg>

          <slot name="header" />
        </div>
        <div
          class="status-popup__close-container position-relative cursor-pointer d-flex text-decoration-none tooltip-parent"
        >
          <Tooltip>დახურვა</Tooltip>
          <i
            @click="closePopup"
            class="icon-close font-size-13 color-orange"
          ></i>
        </div>
      </div>
      <div class="font-size-14 mt-3 color-text-gray bpg-phone-sans">
        <slot name="body" />
      </div>
      <div class="mt-5 w-100 d-flex justify-content-end">
        <slot name="buttons" class="ml-auto" :closeProp="closePopup" />
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from './Tooltip';
export default {
  name: 'StatusPopup',
  components: { Tooltip },
  props: {
    headerTitle: {
      title: {
        type: String,
        default: ''
      }
    },
    statusIsSuccess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mounted: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    });
    document.addEventListener('keydown', this.keyDownListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDownListener);
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
    keyDownListener(e) {
      if (e.key === 'Escape') {
        this.closePopup();
      }
    }
  }
};
</script>

<style lang="scss">
.status-popup {
  z-index: 9;
  &__background-overlay {
    background: rgba($dark-gray, 0.5);
  }

  &__content {
    padding: rem(22) rem(40) rem(40);
    top: 20%;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: all 0.3s ease;
    opacity: 0;
    width: calc(100% - #{rem(30)});

    &.mounted {
      transform: translate(-50%, 0);
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      max-width: rem(660);
    }
  }

  &__header {
    border-bottom: 3px solid $border-gray;
  }

  &__status-icon {
    width: rem(28);
    height: rem(28);
  }
}
</style>
