<template>
  <div class="penalty-orders">
    <Loading v-if="isLoading" />     
    <div v-else>   
      <div class="d-flex justify-content-between">  
        <h2 class="dashboard-page-title mb-4">ვადაგადაცილებული ინვოისები</h2>
        <p class="bpg-nino-mtarvruli font-weight-bold">
          საჯარიმო თანხა:
          <span class="color-red font-size-18">{{ total_amount }}</span>
        </p>
        <a @click="exportToExcel" class="export_excel"><img alt="ექსპორტი ექსელში" src="@/assets/images/excel.png" /></a>
      </div>
      <div v-if="penalty_orders.order" class="overflow-auto">
        <div class="d-table table w-100">
          <div class="tr mb-3">
            <div class="th">შეკვეთა</div>
            <div class="th">ტიპი</div>
            <div class="th date">გადახდის თარიღი</div>
            <div class="th days">გადაცილებული დღეები</div>
            <div class="th date">გამოწერის თარიღი</div>
            <div class="th">თანხა</div>
            <div class="th">ღია თანხა</div>
            <div class="th">ვალუტა</div>
          </div>
          <component
            :is="item.order_id ? 'router-link' : 'div'"
            v-for="(item, index) in penalty_orders.order"
            :key="`${item.order_id}_${index}`"
            class="position-relative tr w-100 mb-3 font-size-14 color-dark-gray bpg-phone-sans text-decoration-none"
            :to="{
              name: 'OrderNested',
              params: {
                id: item.order_id,
                type: item.order_type
              }
            }"
          >
            <div class="td">{{ item.order_id }}</div>
            <div class="td">{{ item.order_type }}</div>
            <div class="td text-nowrap">{{ item.due_date }}</div>
            <div class="td">{{ item.days }}</div>
            <div class="td text-nowrap">{{ item.invoice_date }}</div>
            <div class="td text-right">{{ item.amount }}</div>
            <div class="td color-red text-right font-weight-bold">
              {{ item.amount_open }}
            </div>
            <div class="td">{{ item.currency }}</div>
          </component>
        </div>
      </div>
      <h6
        v-else
        class="font-size-16 bpg-nino-mtarvruli color-orange mt-5 text-center"
      >
        ვადაგადაცილებული ინვოისი ვერ მოიძებნა
      </h6>
      <Pagination
        v-model="page_num"
        :records="total_lines"
        :per-page="lines_per_page"
        class="mt-5 ml-auto"
        :options="paginationOptions"
        @paginate="onPaginate"
      />
    </div>
  </div>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import CustomerService from '../../services/CustomerService';
import Loading from '../../components/common/Loading';
import * as XLSX from 'xlsx';

export default {
  name: 'PenaltyOrders',
  components: { Loading },
  mixins: [tableMixin],
  data() {
    return {
      date: '',
      page_num: 1,
      lines_per_page: 25,
      total_lines: 0,
      total_pages: 0,
      activeItem: -1,
      penalty_orders: [],
      penalty_orders_excel: [],
      isLoading: false,
      total_amount: 0
    };
  },
  created() {
    if (this.$route.query.page) this.page_num = Number(this.$route.query.page);
    this.getCustomerOpenOrders();
    this.getCustomerPenalty_excel();
  },
  methods: {
    getCustomerOpenOrders(page = this.page_num) {
      this.isLoading = true;
      const data = {
        page_num: this.page_num,
        lines_per_page: this.lines_per_page
      };

      CustomerService.getCustomerPenalty(data)
        .then(({ data }) => {
          this.setUrlQuery(page);
          this.page_num = page;
          this.penalty_orders = data.penalty_orders;
          this.total_amount = data.total_amount;
          this.total_lines = data.total_lines;
          this.total_pages = data.total_pages;
        })
        .catch((error) => {
          console.error(error.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
      
      
getCustomerPenalty_excel(page = 1)  {
  const data_excel = {
        page_num: 1,
        lines_per_page: 10000
      };
        
    CustomerService.getCustomerPenalty_excel(data_excel)
    .then(({ data }) => {
      this.penalty_orders_excel = data.penalty_orders;
    })
    .catch((error) => {
      console.error(error.response);
    })
    .finally(() => {
      this.isLoading = false;
    });    
  },
    

exportToExcel() {
  this.getCustomerPenalty_excel();
  const tableData = this.getTableData();
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'penalty orders');
  const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const fileName = 'penalty_orders.xlsx';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
},

getTableData() {
  return this.penalty_orders_excel.order.map(item => {
    return {
      'შეკვეთა': item.order_id,
      'ტიპი': item.order_type,
      'გადახდის თარიღი': item.due_date,
      'გადაცილებული დღეები': item.days,
      'გამოწერის თარიღი': item.invoice_date,
      'თანხა': item.inv_amount,
      'თანხა': item.amount,
      'ღია თანხა': item.amount_open,
      'ვალუტა': item.currency
    };
  });
},       

      
    onPaginate(page) {
      this.getCustomerOpenOrders(page);
    }
  }
};
</script>

<style lang="scss">
.penalty-orders {
  .table {
    .date {
      max-width: rem(80);
    }

    .days {
      min-width: rem(120);

      @include media-breakpoint-up(md) {
        min-width: auto;
        max-width: rem(110);
      }
    }
  }
}
.export_excel {float: right; cursor: pointer;}
</style>
