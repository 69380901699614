<template>
  <div class="order-nested d-flex bg-color-bg-gray">
    <div class="container-fluid px-0">
      <div class="container">
        <Breadcrumbs />
      </div>
      <div class="container">
        <div class="order-nested__content bg-color-white border-radius-s w-100">
          <RouterLink :to="{ name: 'OpenOrders' }" class="text-decoration-none">
            <GoBack>
              უკან შეკვეთებში
            </GoBack>
          </RouterLink>
          <OrderNestedHeader :order="order" v-if="order" />
          <div v-if="order && order.stores">
            <div
              class="d-flex mt-4 mb-4 align-items-center justify-content-between"
            >
              <BaseButton
                class="ml-auto"
                @click.native="OrderPickupPopupIsOpen = true"
              >
                მიწოდების პარამეტრები
              </BaseButton>
            </div>
            <transition name="fade">
              <OrderPickupPopup
                v-if="OrderPickupPopupIsOpen"
                @close="OrderPickupPopupIsOpen = false"
                :order="order"
                :data="{}"
              />
            </transition>
            <div v-for="store in order.stores" :key="store.store_name">
              <OrderNestedTable class="mb-4" :store="store" />
            </div>
            <div class="font-size-15 border-top pt-2">
              <p class="mb-2">
                ჯამური თანხა:
                <span class="font-weight-bold"
                  >{{ order.total_price }} ({{ getCurrency }})</span
                >
              </p>
              <p class="mb-2">
                საქონლის რ-ბა:
                <span class="font-weight-bold">{{ order.total_qty }}</span>
              </p>
              <p>
                <span class="font-weight-bold">{{ order.item_count }}</span>
                დასახელების საქონელი
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/common/Breadcrumbs';
import GoBack from '../../components/common/GoBack';
import OrderNestedHeader from '../../components/order-nested/OrderNestedHeader';
import OrderNestedTable from '../../components/order-nested/OrderNestedTable';
import CustomerService from '../../services/CustomerService';
import OrderPickupPopup from '../../components/order-nested/OrderPickupPopup.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderNested',
  components: {
    OrderNestedTable,
    OrderNestedHeader,
    GoBack,
    Breadcrumbs,
    OrderPickupPopup
  },
  computed: {
    ...mapGetters('auth', ['getCurrency'])
  },
  data() {
    return {
      order: null,
      OrderPickupPopupIsOpen: false
    };
  },
  created() {
    const data = {
      order_id: parseInt(this.$route.params.id),
      order_type: this.$route.params.type
    };
    CustomerService.getCustomerOpenOrder(data)
      .then(({ data }) => {
        this.order = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>

<style lang="scss">
.order-nested {
  padding-top: $goods-menu-height-mobile;
  padding-bottom: rem(80);

  @include media-breakpoint-up(md) {
    padding-top: $goods-menu-height;
  }

  &__content {
    padding: rem(20) rem(18);
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding: rem(28) rem(38);
    }
  }
}
</style>
