<template>
  <header ref="header" class="header position-absolute w-100 bg-color-white">
    <div
      class="container d-flex align-items-center justify-content-between h-100"
    >
      <div
        class="header__mobile-menu-button d-flex align-items-center flex-1 d-md-none"
      >
        <div @click="isOpened = !isOpened" class="py-2 d-inline-block">
          <div class="icon" :class="{ open: isOpened }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div @click="isOpenedSearch = true" class="py-2 ml-4 d-inline-block">
          <div class="icon-search font-size-25"></div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <router-link :to="{ name: 'Home' }" class="header__logo-container">
          <img alt="Alta logo" src="@/assets/images/alta-logo.png" />
        </router-link>
      </div>
      <div class="search-container" :class="{ open: isOpenedSearch }">
        <SearchInput class=" d-md-flex" v-if="loggedIn" :goods="goodsMenu" />
        <button
          class="base-button d-md-none bg-color-text-gray ml-2 pt-2"
          @click="isOpenedSearch = false"
        >
          <i class="icon-close1 font-size-14 d-md-none" />
        </button>
      </div>
      <div
        v-if="loggedIn"
        class="d-flex flex-1 justify-content-end align-items-center"
      >
        <div class="header__profile-header-item-container ml-md-3 ml-lg-0">
          <DropdownBox>
            <template v-slot:topContent>
              <HeaderProfileItem>
                <p class="mb-1">{{ getUser.manager_name }}</p>
                <p class="font-size-12 m-0 company-name">
                  {{ getUser.company }}
                </p>
              </HeaderProfileItem>
            </template>
            <template v-slot:dropdownContent="{ closeDropdown }">
              <div
                class="d-lg-none border-bottom bpg-phone-sans color-dark-blue font-size-18 pb-3 pt-4 px-4"
              >
                <p class="mb-1">{{ getUser.manager_name }}</p>
                <p class="font-size-12 m-0 company-name">
                  {{ getUser.company }}
                </p>
              </div>
              <HeaderProfileNavigation :close-dropdown="closeDropdown" />
            </template>
          </DropdownBox>
        </div>
        <DropdownBox>
          <template v-slot:topContent>
            <HeaderCartCountItem />
          </template>
          <template v-slot:dropdownContent="{ closeDropdown }">
            <HeaderCartBox :close-dropdown="closeDropdown" />
          </template>
        </DropdownBox>
      </div>
    </div>
    <GoodsMenu
      :goods="goodsMenu"
      :isOpened="isOpened"
      :class="{ open: isOpened }"
      ref="goods-menu"
      v-if="loggedIn"
    />
  </header>
</template>

<script>
import HeaderProfileItem from '../header/HeaderProfileItem';
import HeaderCartCountItem from '../header/HeaderCartCountItem';
//import SearchInput from '../form-components/SearchInput';
import DropdownBox from '../common/DropdownBox';
import HeaderProfileNavigation from '../header/HeaderProfileNavigation';
import HeaderCartBox from '../header/HeaderBasket';
import GoodsMenu from '../goods-menu/GoodsMenu';
import SearchInput from '../form-components/SearchInput';
// import CommonService from '../../services/CommonService';
import { mapState, mapGetters, mapActions } from 'vuex';
import { isDesktop } from '../../helpers/helpers';

export default {
  name: 'TheHeader',
  components: {
    SearchInput,
    GoodsMenu,
    HeaderCartBox,
    HeaderProfileNavigation,
    DropdownBox,
    //SearchInput,
    HeaderCartCountItem,
    HeaderProfileItem
  },
  data() {
    return {
      isOpened: false,
      isOpenedSearch: false
    };
  },
  computed: {
    ...mapGetters('auth', ['loggedIn', 'getUser']),
    ...mapState('navigation', ['goodsMenu'])
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.fetchGoodsMenu();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route() {
      if (this.isOpened && !isDesktop()) this.isOpened = false;
    }
  },
  methods: {
    ...mapActions('navigation', ['fetchGoodsMenu']),
    openNavigationMenu() {
      this.isOpened = !this.isOpened;
    },
    handleScroll() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.$refs.header.classList.add('active');
        this.$refs['goods-menu'].$el.classList.add('active');
      } else {
        this.$refs.header.classList.remove('active');
        this.$refs['goods-menu'].$el.classList.remove('active');
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  height: $header-height-mobile;
  box-shadow: 0px 4px 10px #56308b47;
  left: 0;
  top: 0;
  z-index: 9;
  transition: height 0.2s ease;

  @include media-breakpoint-up(md) {
    height: $header-height;
    box-shadow: none;

    &.active {
      height: rem(70);

      .header__logo-container {
        img {
          height: rem(40);
        }
      }

      .style-chooser .vs__selected-options {
        padding: 0.1em !important;
        transition: 0.3s;
      }
    }
  }

  &__logo-container {
    @include media-breakpoint-up(md) {
      margin-right: rem(70);
    }

    img {
      height: rem(40);
      transition: height 0.2s ease;

      @include media-breakpoint-up(md) {
        height: rem(60);
      }
    }
  }

  &__profile-header-item-container {
    margin-right: 0;

    @include media-breakpoint-up(lg) {
      margin-right: rem(40);
    }

    .company-name {
      text-overflow: ellipsis;
      max-width: 140px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__mobile-menu-button {
    .icon {
      width: 22px;
      height: 18px;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $purple;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
          transform-origin: left center;
        }

        &:nth-child(2) {
          top: 8px;
          transform-origin: left center;
        }

        &:nth-child(3) {
          top: 16px;
          transform-origin: left center;
        }
      }

      &.open {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 0px;
            left: 4px;
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 16px;
            left: 4px;
          }
        }
      }
    }
  }

  .search-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 4px 10px;
    background: #fff;
    height: 54px;
    box-shadow: 0 6px 5px #00000045;
    display: flex;
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease;

    &.open {
      opacity: 1;
      bottom: -54px;
    }

    .search-input {
      flex: 1;
    }

    &:after {
      content: '';
    }

    @include media-breakpoint-up(md) {
      position: relative;
      width: rem(560);
      // height: rem(45);
      height: 100%;
      padding: rem(16) 0px;
      opacity: 1;
      box-shadow: none;
      z-index: initial;
    }
  }
}
</style>
