<template>
  <div class="base-input position-relative">
    <div
      v-if="title"
      class="bpg-phone-sans color-text-gray font-size-12 mb-2 d-inline-block"
    >
      {{ title }}
    </div>
    <div>
      <input
        :disabled="disabled"
        :type="inputType"
        :placeholder="placeholder"
        :value="value"
        @input="updateValue($event.target.value)"
        :class="[
          `base-input__input font-size-14 w-100`,
          { 'base-input__input--success': isSuccess },
          { 'base-input__input--error': error }
        ]"
      />
    </div>
    <div v-if="error" class="mt-1 color-red font-size-10 bpg-phone-sans">
      {{ error }}
    </div>
  </div>
</template>

<script>
import inputMixin from '../../mixins/input-mixin';

export default {
  name: 'BaseInput',
  mixins: [inputMixin]
};
</script>

<style lang="scss">
.base-input {
  input:disabled {
    background: $bg-gray;
  }

  &__input {
    &--error {
      border-color: $red;
    }
    &--success {
      border-color: $green;
    }
  }
}
</style>
