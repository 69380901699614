<template functional>
  <div class="person d-flex align-items-center ">
    <div
      class="person__icon-container mr-lg-3 border-radius-50 d-flex align-items-center justify-content-center"
    >
      <i class="font-size-14 color-white icon-person"></i>
    </div>
    <span
      class="d-none d-lg-block bpg-nino-mtarvruli font-size-14 color-dark-blue"
      ><slot
    /></span>
  </div>
</template>

<script>
export default {
  name: 'HeaderProfileItem'
};
</script>

<style lang="scss">
.person {
padding: rem(12); 
  &__icon-container {
    width: rem(40);
    background-color: #482080;
    height: rem(40);
    box-shadow: 0px 4px 10px $box-shadow-gray;
  }
}
</style>
