import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apiClient from './services/api-client';
import './assets/scss/main.scss';
import Spinner from 'vue-simple-spinner';
import BaseButton from './components/common/BaseButton';
import BaseInput from './components/form-components/BaseInput';
import { ValidationObserver, ValidationProvider } from 'vee-validate'; 
import VueMaskedInput from 'vue-masked-input';
import Pagination from 'vue-pagination-2';
import IdleVue from 'idle-vue';
import Notifications from 'vue-notification';
import VueGtag from "vue-gtag";
// import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(Notifications);
// Vue.use(VueReCaptcha, {
//   siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
//   loaderOptions: {
//     useRecaptchaNet: false,
//     autoHideBadge: true
//   }
// });

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 600000,
  store
});

Vue.use(require('vue-moment'));

Vue.use(VueGtag, {
config: {id: process.env.VUE_APP_GTAG_ID}    
});

Vue.config.productionTip = false;

Vue.component('Spinner', Spinner);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseButton', BaseButton);
Vue.component('Pagination', Pagination);
Vue.component('MaskedInput', VueMaskedInput);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
import './static/validator';

new Vue({
  router,
  store,
  created() {
    const userString = localStorage.getItem('user');
    const saveUserLoggedIn = Boolean(localStorage.getItem('saveUserLoggedIn'));
    if (saveUserLoggedIn) {
      store.commit('auth/SET_USER_LOGGED_IN', saveUserLoggedIn);
    }
    if (userString) {
      const userData = JSON.parse(userString);
      store.commit('auth/SET_USER_DATA', userData);
    }
    apiClient.interceptors.response.use(
      (response) => {
        if (response.data.error_id === 1005) {
          store.dispatch('auth/logout');
        }
        return response;
      },
      (error) => {
        // if (error.response.status === 401) {
        //   store.dispatch('logout');
        // }
        return Promise.reject(error);
      }
    );
  },
  onIdle() {
    if (store.state.auth.user && !store.state.auth.saveUserLoggedIn) {
      store.dispatch('auth/logout');
    }
  },
  render: (h) => h(App)
}).$mount('#app');
