<template>
  <div class="image-slider position-relative">
    <div
      v-if="data.length > 1"
      id="image-slider__controls-js"
      class="image-slider__controls d-flex align-items-center z-index-1"
    >
      <button
        class="image-slider__control-button d-flex align-items-center justify-content-center  position-absolute z-index-1 border-radius-50"
      >
        <i class="icon-triangle_arrow font-size-5 d-inline-block"></i>
      </button>
      <button
        class="image-slider__control-button image-slider__control-button--right d-flex align-items-center justify-content-center position-absolute z-index-1 border-radius-50"
      >
        <i class="icon-triangle_arrow font-size-5 d-inline-block"></i>
      </button>
    </div>
    <div
      v-if="data.length > 1"
      id="image-slider__nav-js"
      class="image-slider__nav position-absolute d-flex z-index-1"
    >
      <div
        v-for="(image, index) in data.length"
        :key="index"
        class="image-slider__nav-item bg-color-white border-radius-s cursor-pointer"
      ></div>
    </div>
    <VueTinySlider
      v-if="data.length > 0"
      :mouse-drag="true"
      :autoplay="true"
      :autoplayTimeout="6000"
      :loop="true"
      :autoplayButtonOutput="false"
      speed="600"
      :controls-container="
        data.length > 1 ? '#image-slider__controls-js' : false
      "
      :nav-container="data.length > 1 ? '#image-slider__nav-js' : false"
      autoplay-reset-on-visibility
    >
        
    <div v-for="(image, index) in data" :key="index" >
    <a v-if ="image.link.length > 1" :href ="image.link">
    <div class="image-slider__img bg-contain"  :style="`background-image: url(${image.image}); max-width: 1170px;  margin: 110px auto 50px auto; border-radius: 5px;`"> </div>
    </a>
        
    <div v-if ="image.link.length < 1" class="image-slider__img bg-contain"  :style="`background-image: url(${image.image}); max-width: 1170px;  margin: 110px auto 50px auto; border-radius: 5px;`"> </div>    
    
    </div>
        
    </VueTinySlider>
  </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';
import CommonService from '../../services/CommonService';
export default {
  name: 'ImageSlider',
  components: {
    VueTinySlider
  },
  data() {
    return {
      data: []
    };
  },
  created() {
    CommonService.getSliderImages().then(({ data }) => {
      this.data = data.data;
    });
  }
};
</script>

<style lang="scss">    
@media only screen and (max-width: 767px) {
.image-slider__img {margin: 10px auto !important; max-height: 150px !important; }
}
.image-slider {
  &__img {
    height: 430px !important;
    
    @include media-breakpoint-up(md) {
      height: rem(435);  
    }
  }

  &__control-button {
    width: rem(40);
    height: rem(40);
    background: rgba($white, 0.3);
    transition: background-color 0.2s ease;
    top: rem(320);
    left: $container-offset;
      

    i {
      transform: rotate(90deg);
    }

    &--right {
      right: $container-offset;
      left: auto;
      i {
        transform: rotate(270deg);
      }
    }

    &:hover {
      background: rgba($white, 1);
    }
  }

  &__nav {
    bottom: rem(26);
    right: $container-offset;
  }

  &__nav-item {
    width: 6px;
    height: 13px;
    margin-left: rem(18);
    transition: background-color 0.2s ease;

    &.tns-nav-active,
    &:hover {
      background: $dark-gray;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
