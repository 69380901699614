<template>
  <div @mouseleave="handleMouseLeave" class="second-level-item w-100">
    <ul
      class="second-level-item__list bg-color-bg-gray h-100 position-relative"
    >
      <li
        class="second-level-item__list-title d-flex d-md-none color-dark-blue bpg-phone-sans"
        @click="$emit('close')"
      >
        <i
          class="color-dark-blue second-level-item__list-title-icon mr-3 font-size-9 icon-arrow-down"
        ></i>
        {{ secondLevelTitle }}
      </li>
      <li
        class="second-level-item__list-item d-md-none bpg-phone-sans color-dark-blue font-size-14 d-flex align-items-center cursor-pointer"
        @click="$emit('close')"
      >
        <router-link
          :to="{ name: 'Catalog', query: { id: secondLevelId } }"
          class="d-flex justify-content-between w-100 align-items-center text-decoration-none"
        >
          <span>{{ secondLevelTitle }}</span>
          <i
            class="second-level-item__list-item-icon color-dark-blue icon-arrow-down font-size-6 ml-auto"
          ></i>
        </router-link>
      </li>
      <li
        v-for="(item, index) in this.secondLevelData"
        :key="item.ID"
        class="second-level-item__list-item bpg-phone-sans color-dark-blue font-size-14 d-flex align-items-center cursor-pointer"
        :class="{ active: isActive === index }"
        @click="isActive = index"
      >
        <span class="mr-3">
          {{ item.Name }}
        </span>
        <i
          class="second-level-item__list-item-icon color-dark-blue icon-arrow-down font-size-6 ml-auto"
        ></i>
        <ul
          class="
              second-level-item__nested-list h-100 flex-wrap
            "
        >
          <li
            @click="handleNestidListTitleClick"
            class="second-level-item__nested-list-title color-dark-blue d-md-none font-size-15 bpg-phone-sans d-flex align-items-center text-nowrap"
          >
            <i class="mr-3 font-size-8 color-dark-blue icon-arrow-down"></i>
            <span>{{ item.Name }}</span>
          </li>
          <li
            class="second-level-item__nested-list-item font-size-14 bpg-phone-sans color-text-gray d-flex align-items-center text-nowrap"
          >
            <router-link
              :to="{ name: 'Catalog', query: { id: item.ID } }"
              class="second-level-item__nested-nav-link  d-flex align-items-center text-decoration-none"
            >
              <strong>{{ item.Name }}</strong>
            </router-link>
          </li>
          <li
            v-for="item in item.items"
            :key="item.ID"
            class="second-level-item__nested-list-item font-size-12 bpg-phone-sans color-text-gray text-nowrap ml-4"
          >
            <router-link
              :to="{ name: 'Catalog', query: { id: item.ID } }"
              class="second-level-item__nested-nav-link  d-flex align-items-center text-decoration-none"
            >
              {{ item.Name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <div class="bg-color-white h-100 flex-1"></div>
  </div>
</template>

<script>
import { isDesktop } from '../../helpers/helpers';

export default {
  name: 'SecondLevelItem',
  props: {
    secondLevelData: {
      type: Array,
      required: true
    },
    secondLevelTitle: {
      type: String
    },
    secondLevelId: {
      type: [Number, String]
    },
    isOpened: {
      type: Boolean
    }
  },
  data() {
    return {
      isActive: -1
    };
  },
  watch: {
    isOpened(isOpened) {
      if (!isOpened) this.isActive = -1;
    }
  },
  mounted() {
    if (isDesktop()) {
      this.isActive = 0;
    }
  },
  computed: {
    filteredArray() {
      return this.secondLevelData.filter((item) => {
        return item.items && item.items.length > 0;
      });
    }
  },
  methods: {
    handleMouseLeave() {
      if (isDesktop()) {
        this.isActive = 0;
      }
    },
    handleNestidListTitleClick(event) {
      event.stopPropagation();
      this.isActive = -1;
    }
  }
};
</script>

<style lang="scss">
$second-level-menu-height: rem(550);
.second-level-item {
  box-shadow: 0 6px 6px 0 rgba($black, 0.05);
  width: 100%;
  @include abs-position(0, 0);
  height: 100%;
  z-index: 1;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.2s ease;

  @include media-breakpoint-up(md) {
    display: none;
    opacity: 1;
    @include abs-position(100%, 0);
    height: $second-level-menu-height;
  }

  &__list {
    padding: 0;
    list-style: none;
    position: absolute;

    @include media-breakpoint-up(md) {
      display: inline-grid;
      grid-template-rows: repeat(14, calc(#{$second-level-menu-height} / 14));
      grid-auto-flow: column;
    }
  }

  &__list-item {
    padding: rem(10) rem(24) rem(10) rem(40);
    transition: background-color 0.2s ease;

    &.active {
      .second-level-item__nested-list {
        transform: translate(0);
        opacity: 1;
        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      .second-level-item__list-item-icon {
        opacity: 1;
      }
    }

    &.active,
    &:hover {
      background: $white;
    }
  }

  &__list-item-icon {
    padding-top: rem(6);
    transform: rotate(270deg);

    @include media-breakpoint-up(md) {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &__list-title {
    padding: rem(15) rem(24) rem(15) rem(15);
    transition: background-color 0.2s ease;
  }

  &__list-title-icon {
    padding-top: rem(6);
    transform: rotate(90deg);
  }

  &__nested-list {
    left: 0;
    top: 0;
    z-index: 1;
    background: $bg-gray;
    transform: translateX(-100%);
    flex-direction: column;
    width: 100%;
    position: absolute;
    padding: 0;
    transition: all 0.2s ease;
    opacity: 0;

    &-title {
      padding: rem(15) rem(20);

      .icon-arrow-down {
        transform: rotate(90deg);
      }
    }

    @include media-breakpoint-up(md) {
      display: none;
      opacity: 1;
      padding: 0 rem(34);
      background: $white;
      transform: 0;
      @include abs-position(0, auto, 0, 100%);
    }
  }

  &__nested-list-item {
    padding: 0 rem(40);
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 0 rem(40) 0 0;
    }

    &:hover {
      color: $light-purple;
    }
  }

  &__nested-nav-link {
    height: calc((#{$second-level-menu-height} - #{rem(50)}) / 16);
  }
}
</style>
