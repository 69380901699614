<template functional>
  <h3
    class="section-title bpg-nino-mtarvruli font-size-16 color-dark-gray position-relative"
  >
    <slot />
  </h3>
</template>

<script>
export default {
  name: 'SectionTitle'
};
</script>

<style lang="scss">
.section-title {
  padding-bottom: rem(9.5);

  &::after {
    width: rem(17);
    height: 3px;
    background: $light-purple;
    @include abs-position(auto, auto, 0, 0);
    content: '';
  }
}
</style>
