<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ errors }"
    :name="name"
    :ref="refString"
  >
    <div class="auth-input-container d-flex">
      <div class="auth-input-container__icon text-center">
        <i
          :class="`font-size-${fontSize} color-dark-gray icon-${iconName}`"
        ></i>
      </div>
      <BaseInput
        :title="title"
        :placeholder="placeholder"
        v-model="innerValue"
        class="flex-1 d-flex flex-column"
        :error="errors[0]"
        :input-type="inputType"
      />
    </div>
  </ValidationProvider>
</template>

<script>
import inputMixin from '@/mixins/input-mixin';
export default {
  name: 'AuthInputContainer',
  mixins: [inputMixin],
  data() {
    return {
      innerValue: this.value
    };
  },
  props: {
    iconName: {
      type: String,
      required: true
    },
    fontSize: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    refString: {
      type: String,
      required: true
    }
  },
  watch: {
    innerValue(val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style lang="scss">
.auth-input-container {
  &__icon {
    margin-top: rem(39);
    width: rem(35);

    @include media-breakpoint-up(md) {
      width: rem(56);
    }
  }
}
</style>
