<template>
  <div
    class="tooltip position-absolute py-2 px-3 border-radius-s bg-color-white color-text-gray bpg-phone-sans font-size-12"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tooltip'
};
</script>

<style lang="scss">
.tooltip-parent {
  position: relative;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
.tooltip {
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + #{rem(6)});
  border: 1px solid $border-gray;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  background: #fff;
  z-index: 1;
}
</style>
