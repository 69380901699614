<template>
  <button
    class="product-item-slider-arrow-button position-absolute border-radius-50 bg-color-white d-flex align-items-center justify-content-center cursor-pointer"
    :class="{
      'product-item-slider-arrow-button--right': $props.isRightDirection
    }"
  >
    <i
      class="product-item-slider-arrow-button__icon icon-triangle_arrow font-size-6 color-white"
    ></i>
  </button>
</template>

<script>
export default {
  name: 'ProductItemSliderArrowButton',
  props: {
    isRightDirection: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.product-item-slider-arrow-button {
  width: rem(32);
  height: rem(32);
  top: rem(80);
  left: rem(-12);
  border: 2px solid $bg-gray !important;

  &__icon {
    transform: rotate(90deg);
    text-shadow: -1px 0 $icon-gray, 0 1px $icon-gray, 1px 0 $icon-gray,
      0 -1px $icon-gray;
  }
  &:focus {
    outline: none !important;
    border: 2px solid $bg-gray !important;
  }

  &--right {
    left: auto;
    right: rem(-12);
    .product-item-slider-arrow-button__icon {
      transform: rotate(270deg);
    }
  }

  &:disabled {
    display: none !important;
  }
}
</style>
