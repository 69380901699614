<template>
  <div class="my-profile ">
    <ValidationObserver v-slot="{ handleSubmit }">
      <div
        class="dashboard__content-child border-radius-s bg-color-white h-100 d-flex flex-wrap"
      >
        <h2 class="dashboard-page-title mb-4 w-100">ჩემი მონაცემები</h2>
        <div>
          <h3 class="dashboard-page-sub-title">კომპანიის მონაცემები</h3>
          <div class="my-profile__input-wrapper mr-5">
            <BaseInput
              title="საიდენტიფიკაციო კოდი"
              v-model="user.company_id"
              disabled
            />
          </div>
          <BaseInput
            class="my-profile__input-wrapper"
            title="დასახელება"
            v-model="user.company"
            disabled
          />
          <BaseInput
            class="my-profile__input-wrapper mb-5"
            title="საგადასახადო კოდი"
            v-model="user.company_tax_id"
            disabled
          />
        </div>
        <div class="">
          <h3 class="dashboard-page-sub-title">ჩემი მონაცემები</h3>
          <BaseInput
            class="my-profile__input-wrapper"
            title="მენეჯერის კოდი"
            v-model="user.manager_id"
            disabled
          />
          <BaseInput
            class="my-profile__input-wrapper"
            title="მენეჯერი"
            v-model="user.manager_name"
            disabled
          />
          <BaseInput
            class="my-profile__input-wrapper"
            title="როლი"
            v-model="user.manager_type"
            disabled
          />
          <BaseInput
            class="my-profile__input-wrapper"
            title="ელ.ფოსტა"
            v-model="user.user_email"
            disabled
          />
          <BaseInput
            class="my-profile__input-wrapper"
            title="user"
            v-model="user.user_name"
            disabled
          />
        </div>
      </div>
      <div
        class="mt-4 dashboard__content-child border-radius-s bg-color-white h-100"
      >
        <h2 class="dashboard-page-title mb-4">სხვა მომხმარებლები</h2>

        <div class="d-flex justify-content-between">
          <div
            class="font-size-12 bpg-phone-sans color-text-gray mb-4 d-flex align-items-center cursor-pointer"
            @click="showNewUserForm = true"
            v-if="!showNewUserForm"
          >
            <div
              class="mr-3 px-3 py-2  bg-color-bg-gray border-radius-s d-flex align-items-center"
            >
              <i class="icon-add font-size-16 color-dark-gray"></i>
            </div>
            მომხმარებლის დამატება
          </div>
          <div
            class="font-size-12 bpg-phone-sans color-text-gray mb-4 d-flex align-items-center cursor-pointer"
            @click="showNewUserForm = false"
            v-else
          >
            <div
              class="mr-3 px-3 py-2  bg-color-bg-gray border-radius-s d-flex align-items-center"
            >
              <i class="icon-close1 font-size-16 color-dark-gray"></i>
            </div>
            დახურვა
          </div>
        </div>
        <div v-if="showNewUserForm">
          <form
            ref="addNewUserForm"
            @submit.prevent="handleSubmit(onCustomNewUserSubmit)"
            class="w-100"
          >
            <div class="row">
              <InputWithInfoTooltip
                v-model="newCustomerUser.manager_name"
                title="მენეჯერი"
                name="manager_name"
                placeholder="სახელი, გვარი"
                tooltip-text="შეიყვანეთ მენეჯერი სახელი"
                :default-value="''"
                rules="required"
                class="col-6"
              />
              <InputWithInfoTooltip
                v-model="newCustomerUser.manager_type"
                title="მენეჯერის ტიპი"
                name="manager_type"
                placeholder="მენეჯერის ტიპი"
                tooltip-text="აირჩიეთ მენჯერის ტიპი"
                :default-value="''"
                rules="required"
                class="col-6"
              />
            </div>
            <div class="row">
              <InputWithInfoTooltip
                v-model="newCustomerUser.manager_email"
                title="ელ-ფოსტა"
                name="manager_email"
                placeholder="მიუთითეთ მეილი"
                tooltip-text="შეიყვანეთ მენჯერის ელ-ფოსტა"
                :default-value="''"
                rules="required|email"
                class="col-6"
              />
              <InputWithInfoTooltip
                v-model="newCustomerUser.manager_tax_id"
                title="პირადი ნომერი"
                name="manager_tax_id"
                placeholder="მიუთითეთ პირადი ნომერი"
                tooltip-text="შეიყვანეთ მენჯერის პირადი ნომერი"
                :default-value="''"
                rules="required"
                class="col-6"
              />
            </div>
            <div class="d-flex">
              <BaseButton class="ml-auto" type="submit"
                >განაცხადის გაგზავნა</BaseButton
              >
            </div>
          </form>
        </div>
        <div class="user-table">
          <table class="table mt-3 mt-md-5 w-100 font-size-15">
            <thead>
              <tr>
                <th>user</th>
                <th>მენეჯერი</th>
                <th>მენეჯერის ტიპი</th>
                <th>ელ.ფოსტა</th>
                <th>სტატუსი</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="">
              <tr v-for="(user, index) in customerUsers" :key="index" class="">
                <td>{{ user.manager_user }}</td>
                <td>{{ user.manager_name }}</td>
                <td>{{ user.manager_type }}</td>
                <td>{{ user.manager_email }}</td>
                <td>
                  <BaseCheckbox
                    :key="user.manager_id"
                    name="brand"
                    class="ml-3"
                    v-model="user.access_code"
                    custom-value="A"
                  >
                  </BaseCheckbox>
                </td>
                <td>
                  <BaseButton
                    class="ml-auto"
                    type="submit"
                    @click.native="changeUserStatus(user)"
                    >შენახვა</BaseButton
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ValidationObserver>
    <transition name="fade">
      <StatusPopup
        v-if="statusPopupIsOpen"
        :status-is-success="statusType == 'success'"
        :visible="statusPopupIsOpen"
        @close="statusPopupIsOpen = false"
      >
        <template v-slot:header>
          {{ statusMessages.title }}
        </template>
        <template v-slot:body>
          {{ statusMessages.body }}
        </template>
        <template v-slot:buttons="slotProps">
          <BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
        </template>
      </StatusPopup>
    </transition>
  </div>
</template>

<script>
import InputWithInfoTooltip from '../../components/form-components/InputWithInfoTooltip';
import { mapGetters } from 'vuex';
import CustomerService from '@/services/CustomerService';
import StatusPopup from '../../components/common/StatusPopup';
import BaseCheckbox from '../../components/form-components/BaseCheckbox';

export default {
  name: 'MyProfile',
  components: { InputWithInfoTooltip, StatusPopup, BaseCheckbox },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  data() {
    return {
      user_id: null,
      user: null,
      customerUsers: null,
      customerUsersForms: [],
      newCustomerUser: {
        manager_name: '',
        manager_tax_id: '',
        manager_type: '',
        manager_email: ''
      },
      showNewUserForm: false,
      statusPopupIsOpen: false,
      statusType: 'error',
      statusMessages: {
        title: 'ახალი მომხმარებლის დამატება',
        body: ''
      }
    };
  },
  created() {
    this.user = { ...this.getUser };
    CustomerService.getCustomerUsers().then(({ data }) => {
      this.customerUsers = data.user;
    });
  },
  methods: {
    changeUserStatus(user) {
      const { manager_user, access_code } = user;
      const data = {
        manager_user: manager_user,
        enabled: access_code == 'A' ? 'Y' : 'N'
      };

      CustomerService.changeUserStatus(data)
        .then(({ data, status }) => {
          console.log(data, status);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    onManagerFormSubmit(index) {
      console.log(this.customerUsersForms[index]);
    },
    onCustomNewUserSubmit() {
      console.log(this.newCustomerUser);
      this.statusType = 'error';
      this.statusMessages.body = 'მომხარებლის დამატებისას დაფიქსირდა შეცდომა';

      CustomerService.createCustomerUser(this.newCustomerUser)
        .then(({ data, status }) => {
          console.log(data, status);
          if (data.error_id == 0) {
            this.statusType = 'success';
            this.statusMessages.body =
              'განაცხადი წარმატბით გაიგზავნა. მომხმარებელი დაემატება ადმინისტრაციის დასრუდის შემდეგ';

            this.$nextTick(() => {
              this.$refs.addNewUserForm.reset();
            });
          }
          this.statusPopupIsOpen = true;
        })
        .catch((error) => {
          this.statusPopupIsOpen = true;
          console.log(error);
        })
        .finally(() => {});
    }
  }
};
</script>

<style lang="scss">
.my-profile {
  &__input-wrapper {
    width: $input-width;
    margin-bottom: rem(15);
  }

  .user-table {
    overflow-x: auto;
    td,
    .td {
      padding: rem(10);
    }
  }
}
</style>
