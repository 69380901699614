import apiClient from './api-client';

export default {
  getGoods() {
    return apiClient.get(`get-goods-tree`);
  },
  getSliderImages() {
    return apiClient.get('get-slider-images');
  },
  getFooterContent() {
    return apiClient.get('get-footer-content');
  }
};
