<template>
  <article>
   <Loading v-if="isLoading" />
    <article
      v-if="newsNestedData"
      class="nested-news bg-color-white z-index-1 border-radius-s d-flex flex-column justify-content-between position-relative"
    >
      <router-link
        :to="{ name: 'news' }"
        class="nested-news__close-container position-absolute cursor-pointer d-flex text-decoration-none tooltip-parent"
      >
        <Tooltip>დახურვა</Tooltip>
        <i class="icon-close font-size-13 color-orange"></i>
      </router-link>
      <div class="bpg-phone-sans font-size-14 color-dark-blue mb-3">
        {{ newsNestedData.date }}
      </div>
      <h4 class="bpg-nino-mtarvruli font-size-16 color-dark-gray mb-3">
        {{ newsNestedData.title }}
      </h4>
      <div>
        <img class="nested-news__img" :src="newsNestedData.image_link" alt="" />
        <div
          class="news-grid-item__text bpg-phone-sans font-size-14 color-gray mb-4"
          v-html="newsNestedData.desc"
        ></div>
          

<div style="clear:both"></div>
          
<div class="my-4">
    
<div>     
<div v-for="(item, index) in newsNestedData.items" :key="index" class="col-6 col-md-4 float-left">
<section> 
<ProductItem :data="item"/>
</section>
</div>
    
</div>
    
</div>
    
    
</div>

<router-link :to="{ name: 'News' }" class="news-grid-item__read-more cursor-pointer bpg-nino-mtarvruli font-size-16 dark-gray ml-auto align-self-end text-decoration-none">ნაკლების ნახვა</router-link>
          
    </article>
  </article>
</template>

<script>
import ProductItem from '../../components/common/ProductItem';
import Tooltip from '../../components/common/Tooltip';
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/common/Loading';
export default {
  name: 'NewsNested',
  components: { Tooltip, ProductItem, Loading },
  computed: mapState('news', ['newsNestedData', 'isLoading']),
  methods: mapActions('news', ['fetchNewsNested']),
  created() {
    this.fetchNewsNested(this.$route.params.id);
  }
};
</script>

<style lang="scss">
.nested-news {
  padding: rem(50) rem(35) rem(34);

  &__close-container {
    right: rem(36);
    top: rem(20);
  }

  &__img {
    width: rem(200);
    float: left;
    margin-right: rem(50);
    margin-bottom: rem(15);
  }
}
</style>
