import Vue from 'vue';
import Vuex from 'vuex';
import * as news from '@/store/modules/news';
import * as auth from '@/store/modules/auth';
import * as basket from '@/store/modules/basket';
import * as navigation from '@/store/modules/navigation';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    news,
    basket,
    auth,
    navigation
  }
});
