<template>
  <div class="order-nested-header mt-3 w-100 border-radius-s pr-md-4">
    <div
      class="w-100 p-2 d-md-flex flex-wrap align-items-center justify-content-between"
    >
      <div class="d-flex flex-1 align-items-center">
        <div class="d-flex">
          <button
            class="order-nested-header__refresh d-flex align-items-center justify-content-center border-radius-s bg-color-light-purple mr-3"
            @click="reloadPage"
          >
            <i class="icon-refresh font-size-20 color-white"></i>
          </button>
        </div>
        <div>
          <div class="d-flex align-items-center">
            <div
              class="bpg-nino-mtarvruli font-size-16 font-weight-bold color-dark-gray"
            >
              შეკვეთა N {{ order.order_id }}
            </div>
          </div>
          <div class="d-flex">
            <div class="bpg-nino-mtarvruli font-size-15 color-text-gray mr-2">
              თარიღი: {{ order.order_date }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="order.order_on_hold == 'Y'"
        class="bpg-phone-sans mt-4 mt-md-0 d-flex justify-content-center flex-1 font-size-14 color-dark-gray d-flex "
      >
        <div class="mr-2">
          <i class="icon-lock color-red font-size-16"></i>
        </div>
        <div class="color-red">
          შეკვეთა შეჩერებულია
        </div>
        <div class="d-none position-relative tooltip-parent text-nowrap">
          <Tooltip>პროდუქცია ჩაბარებულია</Tooltip>
          <i class="icon-error_outline font-size-17 ml-2 color-orange"></i>
        </div>
      </div>
      <div
        class="d-flex mt-2 w-100 flex-1 flex-md-wrap flex-wrap justify-content-between justify-content-md-end"
      >
        <BaseButton
          class="mt-2 send-invoice-btn"
          @click.native="sendInvoiceBtnHandler"
          >ინვოისის გაგზავნა</BaseButton
        >
        <BaseButton
          class="mt-2 ml-md-4 cancel-order-btn"
          @click.native="cancelOrderBtnHandler"
        >
          <i class="icon-close1 font-size-12 color-dark-white mr-2"></i>
          <span>შეკვეთის გაუქმება</span>
        </BaseButton>
      </div>
    </div>

    <transition name="fade">
      <StatusPopup
        v-if="statusPopupIsOpen"
        :status-is-success="statusType == 'success'"
        :visible="statusPopupIsOpen"
        @close="closePopup"
      >
        <template v-slot:header>
          {{ statusMessages.title }}
        </template>
        <template v-slot:body>
          {{ statusMessages.body }}
        </template>
        <template v-slot:buttons="slotProps">
          <BaseButton @click.native="slotProps.closeProp">გასაგებია</BaseButton>
        </template>
      </StatusPopup>
    </transition>
  </div>
</template>

<script>
import BaseButton from '../common/BaseButton.vue';
import Tooltip from '../common/Tooltip';
import CustomerService from '../../services/CustomerService';
import StatusPopup from '../../components/common/StatusPopup';

export default {
  name: 'OrderNestedHeader',
  components: { Tooltip, BaseButton, StatusPopup },
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      statusPopupIsOpen: false,
      statusType: 'error',
      statusMessages: {
        title: '',
        body: ''
      }
    };
  },
  methods: {
    sendInvoiceBtnHandler() {
      this.statusType = 'error';
      this.statusMessages.title = 'ინვოისის გაგზავნა';

      const data = {
        order_id: String(this.$route.params.id),
        order_type: this.$route.params.type
      };

      CustomerService.sendInvoiceByMail(data)
        .then(({ data, status }) => {
          this.statusMessages.body = data.error_desc;
          if (status == 200 && data.error_id == 0) {
            this.statusType = 'success';
            this.statusMessages.body = 'ინვოისი წარმატებით გაიგზავნა';
          }
          this.statusPopupIsOpen = true;
        })
        .catch((error) => {
          this.statusPopupIsOpen = true;
          console.log(error);
        })
        .finally(() => {});
    },
    cancelOrderBtnHandler() {
      this.statusType = 'error';
      this.statusMessages.title = 'შეკვეთის გაუქმება';
      this.statusAction = 'cancel';
      const data = {
        order_id: String(this.$route.params.id),
        order_type: this.$route.params.type
      };

      CustomerService.cancelOrder(data)
        .then(({ data, status }) => {
          console.log(data, status);
          this.statusMessages.body = data.error_desc;

          if (data.error_id == 0) {
            this.statusType = 'success';
            this.statusMessages.body = 'შეკვეთა წარმატებით გაუქმდა';
          }
          this.statusPopupIsOpen = true;
        })
        .catch((error) => {
          this.statusPopupIsOpen = true;
          console.log(error);
        })
        .finally(() => {});
    },
    closePopup() {
      this.statusPopupIsOpen = false;

      if (this.statusAction == 'cancel') {
        this.$router.push({ name: 'OpenOrders' });
      }
    },
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>

<style lang="scss">
.order-nested-header {
  border: 1px solid $border-gray;

  &__refresh {
    width: rem(50);
    height: rem(50);
    transition: opacity 0.3s ease;

    @include media-breakpoint-up(md) {
      width: 35px;
      height: rem(100);
    }

    i {
      transition: none;

      &.active {
        transition: transform 0.2s ease;
        transform: rotate(360deg);
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .cancel-order-btn {
    background-color: $red;
  }

  .send-invoice-btn,
  .cancel-order-btn {
    padding: rem(12) rem(15) rem(7);

    @include media-breakpoint-up(md) {
      padding: rem(14) rem(15) rem(10);
    }
  }
}
</style>
