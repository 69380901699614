export default {
  computed: {
    getDashboardRoutes() {
      const { routes } = this.$router.options;
      const dashboardIndex = routes.findIndex((route) => {
        return route.name === 'Dashboard';
      });
      return routes[dashboardIndex].children;
    }
  }
};
