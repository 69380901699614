import BasketService from '@/services/BasketService';
import Vue from 'vue';

export const namespaced = true;

export const state = {
  basket: null,
  isLoading: false,
  order: null
};

export const actions = {
  getBasket({ commit }) {
    commit('SET_LOADING', true);
    BasketService.getBasket()
      .then((res) => {
        commit('SET_BASKET', res.data);
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  clearBasket({ dispatch, commit }) {
    BasketService.clearBasket()
      .then(() => {
        Vue.prototype.$notify({
          group: 'all',
          type: 'warn',
          title: 'კალათი წარმატებით გასუფთავდა'
        });
        dispatch('getBasket');
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: 'all',
          type: 'error',
          title: 'კალათის გასუფთავებისას დაფიქსირდა შეცდომა',
          text: `გთხოვთ სცადოთ ხელახლა`
        });
        console.log(error.response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  removeBasketItems({ state, dispatch, commit }, basketItems) {
    const mappedBasket = state.basket.items.map((item) => {
      return {
        item: item.item,
        qty: basketItems.includes(item.item) ? -1 : item.qty
      };
    });

    BasketService.updateBasket(mappedBasket)
      .then(() => {
        Vue.prototype.$notify({
          group: 'all',
          type: 'warn',
          title: 'ნივთი წარმატებით წაიშალა კალათიდან'
        });
        dispatch('getBasket');
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: 'all',
          type: 'error',
          title: 'ნივთის წაშლისას მოხდა შეცდომა',
          text: `გთხოვთ სცადოთ ხელახლა`
        });
        console.log(error.response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  updateBasket({ commit, dispatch }, payload) {
    commit('SET_LOADING', true);
    BasketService.updateBasket(payload)
      .then(() => {
        Vue.prototype.$notify({
          group: 'all',
          type: 'success',
          title: 'კალათა წარმატებით განახლდა',
          text: `ნივთის დასახელება: ${payload[0].item_full_name}, <br> რაოდენობა - ${payload[0].qty}`
        });
        dispatch('getBasket');
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: 'all',
          type: 'error',
          title: 'კალათის განახლებსიას მოხდა შეცდომა',
          text: `გთხოვთ ხელახლა სცადოთ კალათის განახლება`
        });
        console.log(error.response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  checkoutBasket({ commit }) {
    commit('SET_LOADING', true);
    return BasketService.checkoutBasket()
      .then((res) => {
        commit('SET_ORDER', res.data);
        return res.data;
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  }
};

export const mutations = {
  SET_BASKET(state, payload) {
    payload.items = payload?.items?.map((item) => {
      return {
        ...item,
        ...{ checked: false }
      };
    });

    state.basket = payload;
  },
  SET_ORDER(state, payload) {
    state.order = payload;
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  }
};

export const getters = {
  getBasketItemsCount({ basket }) {
    return basket?.items?.reduce((a, b) => ({ qty: a.qty + b.qty })).qty;
  }
};
