<template>
  <div class="container-fluid">
    <Loading v-if="isLoading" />
    <div v-if="newsData[0]" class="row border-radius-s overflow-hidden">
      <NewsGridItem
        v-for="(item, index) in newsData[0].items"
        :key="index"
        class="col-md-3 news_item"
        :data="item"
      />
    </div>
    <div class="block mt-4 text-center">
      <BaseButton
        v-if="!allPagesLoaded && !isLoading"
        @click.native="getNewsData"
      >
        მეტის ნახვა
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NewsGridItem from '@/components/news/NewsGridItem';
import BaseButton from '@/components/common/BaseButton';
import Loading from '@/components/common/Loading';
    
export default {
  name: 'News',
  components: { BaseButton, NewsGridItem, Loading },
  computed: {
    ...mapState('news', ['newsData', 'newsTotal', 'isLoading']),
    ...mapGetters('news', ['allPagesLoaded', 'newsDataLength'])
  },
  methods: {
    ...mapActions('news', ['fetchNews']),
    getNewsData(page = 1) {
      this.fetchNews(page);
    }
  },
  created() {
    if (!this.newsDataLength) this.getNewsData(1);
  }
};
</script>
<style lang="scss">
.news_item {
  max-width: 23% !important;
}
</style>
