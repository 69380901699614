<template>
  <button :type="type" :disabled="isLoading" class="base-button">
    <Spinner
      v-if="isLoading"
      class="mr-2"
      size="small"
      line-fg-color="#f77e0b"
    />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: () => {
        return 'button';
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.base-button {
  background: $light-purple;
  padding: rem(14) rem(20) rem(8);
  transition: background-color 0.2s ease;
  white-space: nowrap;
  border-radius: 5px;
  font-family: 'BPG Nino Mtavruli', sans-serif;
  font-size: rem(14);
  text-align: center;
  color: $white;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    padding: rem(14) rem(40) rem(12);
  }

  &.white {
    color: $light-purple;
    background: white;
    border: $light-purple 1px solid !important;

    &:hover {
      background: rgba($gray, 0.1);
    }
  }

  &.secondary {
    background: $dark-gray;
  }

  &:disabled {
    background: $text-gray !important;
  }

  &:focus {
    //box-shadow: 0 0 0 0.2rem rgba($light-purple, 0.5);
    //outline: none;
  }
  &:hover {
    background: rgba($dark-gray, 0.8);
  }
  &:active {
    background: $dark-gray;
  }
}
</style>
