<template>
  <div class="base-input position-relative">
    <div
      v-if="title"
      class="bpg-phone-sans color-text-gray font-size-12 mb-2 d-inline-block"
    >
      {{ title }}
    </div>
    <div>
      <textarea
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        @input="updateValue($event.target.value)"
        :class="[
          `base-input__textarea font-size-14 w-100`,
          { 'base-input__textarea--success': isSuccess },
          { 'base-input__textarea--error': error }
        ]"
      ></textarea>
    </div>
    <div v-if="error" class="mt-1 color-red font-size-10 bpg-phone-sans">
      {{ error }}
    </div>
  </div>
</template>

<script>
import inputMixin from '../../mixins/input-mixin';

export default {
  name: 'BaseTextarea',
  mixins: [inputMixin]
};
</script>

<style lang="scss">
.base-input {
  &__textarea {
    min-height: rem(120);
    resize: none;

    &--error {
      border-color: $red;
    }
    &--success {
      border-color: $green;
    }
  }
}
</style>
